import { INQUIRY_STATUS } from "actions/util/types.action"

const initialState = {
  inquiryStatus: ''
}

const inquiryReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
      case INQUIRY_STATUS:
        return {
          ...state,
          inquiryStatus: payload
        }
      default:
        return state
    }
  }
  
  export default inquiryReducer