import React from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Container, Form } from 'react-bootstrap'
import "../../res/styles/campaign-form.styles.scss";


const CampaignForm = () => {
  return <Container className='campaign-form'>
    <div className="d-flex flex-column gap-3">
      <div className="d-flex">
        <h1 className='fw-bold form-title'>Create Campaign</h1>
      </div>
      <div className="d-flex">
        <h2 className='fw-bold form-subtitle text-primary'>Basic Information</h2>
      </div>

      <Form>
        <div className='row'>
          <div className="col-12 col-md-4 mb-4">
            <Form.Group>
              <Form.Label className='fs-sm fw-600'>Campaign Name</Form.Label>
              <Form.Control className='fs-sm fw-600 py-2 rounded-pill'/>
            </Form.Group>
          </div>
          <div className="col-12 col-md-4 mb-4">
            <Form.Group>
              <Form.Label className='fs-sm fw-600'>Campaign ID</Form.Label>
              <Form.Control className='fs-sm fw-600 py-2 rounded-pill'/>
            </Form.Group>
          </div>
          <div className="col-12 col-md-4 mb-4">
            <Form.Group>
              <Form.Label className='fs-sm fw-600'>Country</Form.Label>
              <Form.Select className='fs-sm fw-600 py-2 rounded-pill'>
                <option disabled selected>---Select Country---</option>
              </Form.Select>
            </Form.Group>
          </div>
        </div>
        <div className='row'>
          <div className="col-12 col-md-4 mb-4">
            <Form.Group>
              <Form.Label className='fs-sm fw-600'>Organisation</Form.Label>
              <Form.Control className='fs-sm fw-600 py-2 rounded-pill'/>
            </Form.Group>
          </div>
          <div className="col-12 col-md-4 mb-4">
            <Form.Group>
              <Form.Label className='fs-sm fw-600'>Start Date</Form.Label>
              <DatePicker disabledKeyboardNavigation={true} dateFormat="dd MM YYYY" className='fs-sm fw-600 py-2 rounded-pill form-control'/>
            </Form.Group>
          </div>
        </div>
        <div className='row'>
          <div className="col-6 col-md-4 mb-4">
            <Form.Group>
              <Form.Label className='fs-sm fw-600'>Target Trainings</Form.Label>
              <Form.Control className='fs-sm fw-600 py-2 rounded-pill'/>
            </Form.Group>
          </div>
          <div className="col-6 col-md-4 mb-4">
            <Form.Group>
              <Form.Label className='fs-sm fw-600'>Target Engagements</Form.Label>
              <Form.Control className='fs-sm fw-600 py-2 rounded-pill'/>
            </Form.Group>
          </div>
        </div>

        <h4 className='fw-bold form-subtitle text-primary '>Campaign Details</h4>

        <div className='row'>
          <div className="col-12 col-md-6 mb-4">
            <Form.Group className="detail-field">
              <Form.Label className='fs-sm fw-600'>Description</Form.Label>
              <Form.Control className='fs-sm fw-600 py-2 rounded-pill'/>
            </Form.Group>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h6 className='fs-sm fw-600'>Locations</h6>
          </div>
          <div className="col-12 col-lg-3 mb-4">
            <Form.Group>
              <Form.Select className='fs-sm fw-600 py-2 rounded-pill'>
                <option disabled selected>---Select Location---</option>
              </Form.Select>
            </Form.Group>
          </div>
          <div className="col-12 col-lg-3 mb-4">
            <Form.Group>
              <Form.Select className='fs-sm fw-600 py-2 rounded-pill'>
                <option disabled selected>---Select Location---</option>
              </Form.Select>
            </Form.Group>
          </div>
          <div className="col-12 col-lg-3 mb-4">
            <Form.Group>
              <Form.Select className='fs-sm fw-600 py-2 rounded-pill'>
                <option disabled selected>---Select Location---</option>
              </Form.Select>
            </Form.Group>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-lg-3 mb-4">
            <Form.Group>
              <Form.Label className='fs-sm fw-600'>Disease Filters</Form.Label>
              <Form.Select className='fs-sm fw-600 py-2 rounded-pill'>
                <option disabled >Select Disease</option>
              </Form.Select>
            </Form.Group>
          </div>
        </div>
        <div >
          <div className="col-12 col-sm-8 col-md-4 col-lg-3 mb-4 p-2">
            <Form.Group>
              <Form.Label className='fs-sm fw-600'>Gender</Form.Label>
              <div className='d-flex align-items-center justify-content-between'>
                <Form.Check className='fw-600 py-2' id='gender-male' label='Male'/>
                <Form.Check className='fw-600 py-2' id='gender-female' label='Female'/>
              </div>
            </Form.Group>
          </div>
        </div>
        <div className='d-flex'>
          <Button variant="primary" className="submit-button" type="submit">Submit</Button>
        </div>
      </Form>
    </div>
  </Container>
}

export default CampaignForm