import { CAMPAIGN_LISTING_IS_FETCHING, IS_FROM_CAMPAIGN_LISTING_SCREEN } from "actions/util/types.action"

const initialState = {
  isFromCampaignLisitingScreen: false,
  isFetching: false
}

const campaignListing = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case IS_FROM_CAMPAIGN_LISTING_SCREEN:
      return {
        ...state,
        isFromCampaignLisitingScreen: payload.isFromCampaignLisitingScreen
      }
    case CAMPAIGN_LISTING_IS_FETCHING:
      return {
        ...state,
        isFetching: payload
      }
    default:
      return state
  }
}

export default campaignListing