import api from "lib/api.lib";
import { INQUIRY_STATUS } from "./util/types.action";

export const sendInquiry = (formData) => async (dispatch) => {
  try {
    await dispatch({ type: INQUIRY_STATUS, payload: 'sending' });
    const res = await api.post(`${process.env.REACT_APP_API}/inquiry`, formData);
    if (res.status === 200) {
      await dispatch({ type: INQUIRY_STATUS, payload: 'sent' });
    } else {
      await dispatch({ type: INQUIRY_STATUS, payload: 'failed' });
    }
  } catch (error) {
    await dispatch({ type: INQUIRY_STATUS, payload: 'failed' });
  }
};

export const resetInquiryStatus = () => async (dispatch) => {
  await dispatch({ type: INQUIRY_STATUS, payload: '' });
}