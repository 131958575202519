import useScreenSize from "hooks/screenSize";
import { connect } from 'react-redux'
import React, { useEffect, useState } from "react";
import { imageFileTypes } from "actions/util/static-data.util";
import { photoModal } from "actions/util/photoModal.action";
import PropTypes from 'prop-types';

const GalleryItem = ({ photoModal, item, itemId }) => {
  const { isDesktop } = useScreenSize()
  const [layoutLoaded, setLayoutLoaded] = useState(false)
  
  useEffect(() => {
    setLayoutLoaded(true)
  }, [])

  const getAosDelay = () => {
    const itemsPerRow = isDesktop ? 3 : 2;
    const delay = (itemId%itemsPerRow)*150;
    return delay;
  }
  const openPhotoModal = () => {
    photoModal({isShowPhotoModal: true, object: item, isPreviewOption: false, selectedIndex: itemId})
  }
  return (
    layoutLoaded && <>
      <div data-aos="fade-up" data-aos-delay={getAosDelay()} className="card" onClick={openPhotoModal}>
        <div key={itemId} className='h-100'>
          { imageFileTypes.some(type => item.imageUrl.includes(`.${type}`)) ? 
            <img src={item.imageUrl} className="image-view" alt='Attachment'/>
            :
            <div className = "video-card h-100 w-100">
              <video className = "video-frame w-100 h-100" controls key={itemId} src={item.imageUrl} type="video/mp4, video/quicktime, video/x-msvideo, video/*, .mkv"/>
            </div>
          }
        </div>
      </div>
    </>
  );
};

GalleryItem.propTypes = {
  photoModal: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps , { photoModal })(GalleryItem)
