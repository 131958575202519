import { GALLERY_SET_DATA, GALLERY_IS_FETCHING, GALLERY_APPEND_SIGNED_URLS } from "actions/util/types.action"

const initialState = {
  posts: {
    data: [],
    dataProcessed: [],
    pagination: {
      currentPage: 0,
      totalPages: 1,
      totalRecords: 0
    }
  },
  isFetching: false
}

const galleryPostsReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
      case GALLERY_SET_DATA:
        return {
          ...state,
          posts: {
            ...state.posts,
            dataProcessed: payload.pagination.currentPage === 1 ? [] : state.posts.dataProcessed,
            data: [...(payload.pagination.currentPage === 1 ? [] : state.posts.data), ...payload.data ? payload.data : initialState.posts.data],
            pagination: payload.pagination ? payload.pagination : initialState.posts.pagination
          }
        }
      case GALLERY_IS_FETCHING:
        return {
          ...state,
          isFetching: payload
        }
      case GALLERY_APPEND_SIGNED_URLS:
        state.posts.data.map((post) => {
          post.files = []
          payload.imageUrls.map((url) => {
            if(url.id === post._id){
              post.files.push(url)
            }
            return url
          })
          return post
        })
        return {
          ...state,
          posts: {
            ...state.posts,
            dataProcessed: [
              ...state.posts.dataProcessed,
              ...payload.imageUrls.map(url => {
                const post = state.posts.data.find(post => post._id === url.id)
                return {
                  imageUrl: url.imageUrl,
                  post: post,
                  imageIndex: post.files.findIndex(file=> file.imageUrl === url.imageUrl)
                }
              })
            ]
          }
        }
      default:
        return state
    }
  }
  
  export default galleryPostsReducer
