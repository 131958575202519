import { EXP_CAMPAIGN_STATUS } from "actions/util/types.action"

const initialState = {
  expandCampaignStatus: ''
}

const expandCampaignReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
      case EXP_CAMPAIGN_STATUS:
        return {
          ...state,
          expandCampaignStatus: payload
        }
      default:
        return state
    }
  }
  
  export default expandCampaignReducer