import React, { useEffect, useState } from 'react'
import { Container, Table, ProgressBar } from 'react-bootstrap'
import "../../res/styles/campaign-listing.styles.scss";
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { GetCampaigns, setCampaign, setCampaigns, UpdateCampaignStatusToComplete, setSelectedCampaignsFilter } from '../../actions/campaign.action'
import { setIsFromCampaignLisitingScreen, setIsCampaignLisitingFetching } from '../../actions/util/campaignListing.action'
import { Dropdown } from 'react-bootstrap'
import { BsThreeDots } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import { getFormattedDate, parsePageTitle } from '../../lib/common.lib'
import HelpTooltipIcon from 'components/common/HelpTooltipIcon';
import Pagination from 'components/util/Pagination';
import Loading from 'components/util/Loading';
import { setCountryFilter } from 'actions/blogPost.action'
import useTitle from 'hooks/useTitle';

const CampaignListing = ({
  GetCampaigns,
  CampaignsDataPaginated,
  DataCount,
  setCampaign,
  user,
  UpdateCampaignStatusToComplete,
  setIsFromCampaignLisitingScreen,
  setCampaigns,
  setSelectedCampaignsFilter,
  isFetching,
  setCountryFilter
}) => {
  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 5;
  
  useTitle('Campaigns')

  useEffect(() => {
    GetCampaigns({ pageIndex: currentPage - 1, pageSize: ITEMS_PER_PAGE, countryCode: user.country.iso3.toUpperCase(), userRole: '' })
  }, [GetCampaigns, currentPage, ITEMS_PER_PAGE])

  const isCampaignAdminWrite = () => {
    if (user.userRole.includes('insights-super-admin')) {
      return true
    }
    const associate = user.associate.find(
      (val) => val?.type === "campaign" && val?.role === "read/write"
    );
    return (
      associate?.role === "read/write" &&
      user.userRole.includes("insights-campaign")
    );
  }

  const navigateViewDetailsPage = (data) => {
    setCampaign(data)
    navigate(`/campaign-details/${data._id}`)
  }
  
  const navigateProgressUpdatePage = (data) => {
    setCampaign(data)
    navigate(`/campaign-progress/${data._id}`)
  }


  const getStatusClass = (status) => {
    if (status) {
      switch (status.toUpperCase()) {
        case 'ONGOING':
          return 'ongoing'

        case 'COMPLETED':
          return 'completed'

        case 'UPCOMING':
          return 'upcoming'

        default:
          return ''
      }
    }
  }

  const gotoDashboard = (campaign) => {
    setCampaigns([campaign])
    setCountryFilter({ selectedCountryFilter: campaign.countryISO, isCountryChanged: true })
    setSelectedCampaignsFilter([{label: campaign.name, value: campaign._id}])
    setIsFromCampaignLisitingScreen({isFromCampaignLisitingScreen: true})
    navigate('/dashboard')
  }

  return <Container className='campaign-listing'>
    <div className='d-flex flex-column h-100 gap-4'>
      <div className='d-flex justify-content-between flex-row'>
        <div className="d-inline-flex align-items-center">
          <h2 className='fw-bold title'>Campaign Database</h2>
          <HelpTooltipIcon tooltip="This page shows all current and past engagements that are run on the reach52 Connect platform."/>
        </div>
        {/* TODO: Keep Add Campaign button/feature commented until requirements are not cleared from Wan
        <div className='d-flex'>
          <Link className='custom-link' to='/create-campaign'>
            <Button variant="primary" className="submit-button" type="submit" >Add Campaign</Button>
          </Link>
        </div> */}
      </div>
      <div className='bg-white border rounded-3 shadow-sm mt-4 overflow-hidden mb-5 position-relative'>
      { isFetching && <Loading height='300px'/> }
        { !isFetching && <Table responsive='xl' borderless className='campaign-table mb-0'>
          <thead>
            <tr className='border-bottom'>
              <th className='p-3 text-nowrap'>Campaign Name</th>
              <th className='p-3 text-nowrap'>Country</th>
              <th className='p-3 text-nowrap'>Status</th>
              <th className='p-3 text-nowrap'>Target Residents</th>
              <th className='p-3 text-nowrap'>Target Trainings</th>
              <th className='p-3 text-nowrap'>Last Update</th>
              <th className='p-3 text-nowrap'>Start Date</th>
              <th className='p-3 text-nowrap'>End Date</th>
              <th className='p-3 text-nowrap'></th>
            </tr>
          </thead>
          <tbody>
            {
              CampaignsDataPaginated.map((Obj, id) => {
                const targetResident = Obj.targetResidents !== 0 ? Obj.targetResidents : Obj.targetEngagements.target
                return (
                  <tr key={"row-" + id.toString()}>
                    <td className='px-3 pt-3 pb-2 pointer-selection' onClick={() => { gotoDashboard(Obj) }}>
                      <span className="fw-600">{Obj.name}</span>
                      <div className='campaign-code'>{Obj.campaignId}</div>
                    </td>
                    <td className='px-3 pt-3 pb-2'>{Obj.country}</td>
                    <td className='px-3 pt-3 pb-2'>
                      <div className={'status-' + getStatusClass(Obj.campaignStatus)}>
                        {Obj.campaignStatus ? Obj.campaignStatus : ''}
                      </div>
                    </td>
                    <td className='px-3 pt-3 pb-2'>
                      {/* {Obj.targetEngagements.achieved.toString() + '/' + Obj.targetEngagements.target.toString()} */}
                      {Obj.targetEngagements.achieved.toString() + '/' + targetResident.toString()}
                      <ProgressBar className='target-bar'>
                        {/* <ProgressBar now={Obj.targetEngagements.achieved} max={Obj.targetEngagements.target} data-aos="slide-right" data-aos-duration="500"
                          className='completed-bar' /> */}
                        <ProgressBar now={Obj.targetEngagements.achieved} max={targetResident} data-aos="slide-right" data-aos-duration="500"
                          className='completed-bar' />
                      </ProgressBar>
                    </td>
                    <td className='px-3 pt-3 pb-2'>
                      {Obj.targetTrainings.achieved.toString() + '/' + Obj.targetTrainings.target.toString()}                   <ProgressBar className='target-bar'>
                        <ProgressBar now={Obj.targetTrainings.achieved} max={Obj.targetTrainings.target} data-aos="slide-right" data-aos-duration="500"
                          className='completed-bar' />
                      </ProgressBar>
                    </td>
                    <td className='px-3 pt-3 pb-2'>{Obj._metadata.updated.date ? getFormattedDate(Obj._metadata.updated.date) : ''}</td>
                    <td className='text-nowrap px-3 pt-3 pb-2'>{Obj.duration.dateStart ? getFormattedDate(Obj.duration.dateStart) : ''}</td>
                    <td className='text-nowrap px-3 pt-3 pb-2'>{Obj.duration.dateEnd ? getFormattedDate(Obj.duration.dateEnd) : ''}</td>
                    <td className='pe-3 pt-3 pb-2'>
                      <Dropdown>
                        <Dropdown.Toggle as={'span'} className='no-caret'>
                          <BsThreeDots className='cursor-pointer' size={22} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu align="end">
                          <Dropdown.Item className='py-1 fs-sm' onClick={() => { navigateViewDetailsPage(Obj) }}>View</Dropdown.Item>
                          <Dropdown.Item className='py-1 fs-sm' onClick={() => { navigateProgressUpdatePage(Obj) }}>Update Progress</Dropdown.Item>
                          { (isCampaignAdminWrite() && Obj.status !== 'COMPLETED') &&
                            <Dropdown.Item className='py-1 fs-sm' onClick={() => { 
                              UpdateCampaignStatusToComplete({
                                pageIndex: currentPage - 1,
                                pageSize: ITEMS_PER_PAGE,
                                countryCode: Obj.countryISO,
                                campaignId: Obj._id,
                                userCountry: user.country.iso3.toUpperCase()
                              }) 
                            }}>Mark As Complete</Dropdown.Item>
                          }
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                )

              })
            }
          </tbody>
        </Table> }
        <Pagination disabled={false} totalPages={Math.ceil(DataCount/ITEMS_PER_PAGE)} currentPage={currentPage} setPage={setCurrentPage} />
      </div>
    </div>
  </Container>
}

CampaignListing.propTypes = {
  GetCampaigns: PropTypes.func.isRequired,
  setCampaign: PropTypes.func.isRequired,
  setIsFromCampaignLisitingScreen: PropTypes.func.isRequired,
  CampaignsDataPaginated: PropTypes.array.isRequired,
  DataCount: PropTypes.number.isRequired,
  setCampaigns: PropTypes.func.isRequired,
  UpdateCampaignStatusToComplete: PropTypes.func.isRequired,
  setSelectedCampaignsFilter: PropTypes.func.isRequired,
  setIsCampaignLisitingFetching: PropTypes.func.isRequired,
  setCountryFilter: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  CampaignsDataPaginated: state.campaigns.CampaignsDataPaginated,
  isFetching: state.campaignListing.isFetching,
  user: state.auth.user,
  DataCount: state.campaigns.DataCount,
})

export default connect(mapStateToProps, {
  GetCampaigns, setCampaign, UpdateCampaignStatusToComplete,
  setIsFromCampaignLisitingScreen,
  setCampaigns,
  setSelectedCampaignsFilter,
  setIsCampaignLisitingFetching,
  setCountryFilter
})(CampaignListing)