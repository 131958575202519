import React, { useEffect, useRef, useState } from 'react'
import { Button, Container, Form, Spinner } from 'react-bootstrap'
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
import { BsCheck2Circle, BsExclamationOctagon } from 'react-icons/bs';
import toast from 'react-hot-toast';
import { sendExpandCampaignRequest, resetExpandCampaignStatus } from '../../actions/expandCampaign.action'
import "../../res/styles/expand-campaign.styles.scss";
import { countriesForExpandCampaign, focusAreas } from 'actions/util/static-data.util';
import { GetCampaigns } from '../../actions/campaign.action'
import ReCAPTCHA from "react-google-recaptcha";
import useTitle from 'hooks/useTitle';

const ExpandCampaign = ({ user, isAuthenticated, currentCampaign, expandCampaignStatus, campaignsData, GetCampaigns, sendExpandCampaignRequest, resetExpandCampaignStatus }) => {

  useTitle('Expand Campaign')

  const [formData, setFormData] = useState({
    campaignId: '',
    country: '',
    focusArea: '',
    message: '',
    name: '',
    email: ''
  })
  
  const recaptchaRef = useRef();

  useEffect(() => {
    if(isAuthenticated){
      const campaignIds = user.campaign.map((obj) => obj.campaignId)
      GetCampaigns({ countryCode: user.country.iso3.toUpperCase(), campaignIds, userRole: 'app-external-user' })
    }
  }, [isAuthenticated, GetCampaigns, user])

  useEffect(() => {
    if(isAuthenticated){
      setFormData(f => ({ ...f, campaignId: currentCampaign.campaignId }))
    }
  }, [isAuthenticated, currentCampaign.campaignId])
  

  useEffect(() => {
    resetExpandCampaignStatus()
  }, [resetExpandCampaignStatus])

  useEffect(() => {
    if(expandCampaignStatus === 'sent'){
      setFormData({
        campaignId: '',
        country: '',
        focusArea: '',
        message: '',
        name: '',
        email: ''
      })
    }
  }, [expandCampaignStatus])
  
  const handleFormChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }
  
  const onSubmitForm = async (e) => {
    e.preventDefault()
    if(isAuthenticated && !formData.campaignId){
      toast.error('Please select a campaign to continue!')
      return
    }
    if(isAuthenticated){
      sendExpandCampaignRequest(formData)
    } else {
      const token = await recaptchaRef.current.executeAsync()
      await sendExpandCampaignRequest({
        ...formData,
        'g-recaptcha-response': token
      })
      recaptchaRef.current?.reset();
    }
  }

  return <Container className='expand-campaign'>
    <div className='mt-5 container-small'>
      <h5 className='fw-bold mb-4'>Expand Your Campaign</h5>

      {(() => {
        switch(expandCampaignStatus){
          case 'sending':
            return <div className='alert alert-primary position-relative pe-5'>
              <Spinner animation='border' variant='primary' size='sm' className='position-absolute end-0 me-3 mt-1'/>
              Sending inquiry...
            </div>
          case 'sent':
            return <div className='alert alert-success position-relative pe-5'>
              <BsCheck2Circle size={24} className='position-absolute end-0 me-3'/>
              Inquiry received. We'll get back to you soon!
            </div>
          case 'failed':
            return <div className='alert alert-danger position-relative pe-5'>
              <BsExclamationOctagon size={24} className='position-absolute end-0 me-3'/>
              Something went wrong! Please try again.
            </div>
          default:
            return <></>
        }
      })()}

      <Form onSubmit={onSubmitForm} className='mb-5'>

        { isAuthenticated && <Form.Group className='mb-3'>
          <Form.Label>Campaign</Form.Label>
          <Form.Select value={formData.campaignId} onChange={handleFormChange} name='campaignId' style={{ color: !formData.campaignId ? '#989898' : 'black' }} required>
            <option disabled value=''>---Select Campaign---</option>
            { campaignsData.map(c => <option value={c.campaignId} key={c.campaignId} style={{ color: 'black' }}>{ c.name }</option>) }
          </Form.Select>
        </Form.Group> }
        
        <Form.Group className="mb-3" hidden={isAuthenticated}>
          <Form.Label className='fs-sm fw-500'>Your Name</Form.Label>
          <Form.Control onChange={handleFormChange} name='name' className='fs-sm fw-500 py-2 rounded-3' required={!isAuthenticated} />
        </Form.Group>

        <Form.Group className="mb-3" hidden={isAuthenticated}>
          <Form.Label className='fs-sm fw-500'>Your Email Address</Form.Label>
          <Form.Control onChange={handleFormChange} name='email' className='fs-sm fw-500 py-2 rounded-3' required={!isAuthenticated} />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label className='fs-sm fw-500'>Country</Form.Label>
          <Form.Select defaultValue={''} onChange={handleFormChange} name='country' style={{ color: formData.country ? 'black' : '#989898' }} className='fs-sm fw-500 py-2 rounded-3' required>
            <option disabled value=''>---Select Country---</option>
            {countriesForExpandCampaign.map(country => (<option value={country.value} key={country.value} style={{ color: 'black' }}>{country.label}</option>))}
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label className='fs-sm fw-500'>Focus Area</Form.Label>
          <Form.Select defaultValue={''} required onChange={handleFormChange} name='focusArea' style={{ color: formData.focusArea ? 'black' : '#989898' }} className='fs-sm fw-500 py-2 rounded-3'>
            <option disabled value=''>---Select Focus Area---</option>
            {focusAreas.map(area => (<option value={area} key={area} style={{ color: 'black' }}>{area}</option>))}
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label className='fs-sm fw-500'>Tell us more!</Form.Label>
          <textarea className='form-control rounded-3 fw-500 fs-sm' minLength={10} name='message' maxLength={5000} value={formData.message} onChange={handleFormChange} rows={6} placeholder="I would like to..."></textarea>
        </Form.Group>

        { isAuthenticated || <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
        /> }

        <div className='mt-4 border-top pt-4 d-grid'>
          <Button variant="primary" type="submit" disabled={(isAuthenticated && !currentCampaign?.name) || !['', 'failed'].includes(expandCampaignStatus)}>Submit</Button>
        </div>
      </Form>

    </div>
  </Container>
}

ExpandCampaign.propTypes = {
  GetCampaigns: PropTypes.func.isRequired,
  expandCampaignStatus: PropTypes.string.isRequired,
  isAuthenticated: PropTypes.bool.isRequired
}

const mapStateToProps = (state) => ({
  expandCampaignStatus: state.expandCampaign.expandCampaignStatus,
  campaignsData: state.campaigns.CampaignsData,
  user: state.auth.user,
  currentCampaign: state.campaigns.campaign,
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps , { sendExpandCampaignRequest, resetExpandCampaignStatus, GetCampaigns })(ExpandCampaign)