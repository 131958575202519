import api from "../lib/api.lib";
import { GET_NEWS, GET_NEWS_ARTICLE, NEWS_LIST_IS_LOADING, NEWS_ITEM_IS_LOADING, CLEAR_NEWS_ARTICLES } from "./util/types.action";

export const getNews = (category, page) => async (dispatch) => {
  try {
    if(parseInt(page) === 1){
      await dispatch({ type: CLEAR_NEWS_ARTICLES, payload: { category } });
    }
    await dispatch({ type: NEWS_LIST_IS_LOADING, payload: { category, status: true } });
    const res = await api.get(`${process.env.REACT_APP_API}/news/${category}?page=${page}`);
    if (res.status === 200) {
      await dispatch({
        type: GET_NEWS,
        payload: {
          category,
          data: res.data
        },
      });
    } else {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
    }
    await dispatch({ type: NEWS_LIST_IS_LOADING, payload: { category, status: false } });
  } catch (error) {
    console.log(error);
  }
};

export const getNewsArticle = (articleId) => async (dispatch) => {
  try {
    await dispatch({ type: NEWS_ITEM_IS_LOADING, payload: true });
    const res = await api.get(`${process.env.REACT_APP_API}/news/${articleId}`);
    if (res.status === 200) {
      await dispatch({
        type: GET_NEWS_ARTICLE,
        payload: res.data,
      });
    } else {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
    }
    await dispatch({ type: NEWS_ITEM_IS_LOADING, payload: false });
  } catch (error) {
    console.log(error);
  }
};
