import React from 'react'
import { Button } from 'react-bootstrap'

import SubscriptionImage from '../../res/img/subscription.png';

const SubscriptionPanel = () => {
  return <div className='position-relative mb-3 rounded-4 px-3 py-4 subscription-panel overflow-hidden'>
  <img src={SubscriptionImage} className='h-100 w-50 end-0 top-0 position-absolute' alt='subscription'/>
  <div className='row mb-2'>
    <div className='col-12 col-md-6'>
      <h3 className='mb-3 text-primary fw-600'>Keep up to date with us!</h3>
      <p className='mb-4'>We’ll send you latest news, views, case studies, and any insights from our campaigns directly to your inbox</p>
      <Button variant='primary fw-500 fs-sm' size='lg' as='a' href='/news-letter'>Subscribe Now</Button>
    </div>
  </div>
</div>
}

export default SubscriptionPanel