import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import TopNavbar from 'components/layout/TopNavbar'
import ContentWrapper from 'components/common/ContentWrapper'
import PropTypes from 'prop-types'

const PublicOrPrivateRoute = ({
  component: Component,
  isAuthenticated,
  user
}) => {
    return (
    <Fragment>
      { isAuthenticated && <TopNavbar/> }
      <ContentWrapper noMarginY={!isAuthenticated}>
        <Component />
      </ContentWrapper>
    </Fragment>
  )
}

PublicOrPrivateRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user
})

export default connect(mapStateToProps, {})(PublicOrPrivateRoute)
