import { LOGIN_SUCCESS, LOGOUT, LOGOUT_STATE, LOCAL_LOGIN_STATUS } from "actions/util/types.action"
import { getUserCountryList } from '../lib/common.lib'

const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated:
    localStorage.getItem('token') && localStorage.getItem('user')
      ? true
      : false,
  user: localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : [],
  userCountry: localStorage.getItem('user') ? getUserCountryList(JSON.parse(localStorage.getItem('user')).country.iso3) : [],
  localLoginStatus: 'idle'
}

const authReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        token: payload.token,
        isAuthenticated: true,
        user: payload.user,
        userCountry: getUserCountryList(payload.user?.country?.iso3)
      }
    case LOGOUT_STATE:
    case LOGOUT:
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        user: null,
      }
    case LOCAL_LOGIN_STATUS:
      return {
        ...state,
        localLoginStatus: payload
      }
    default:
      return state
  }
}

export default authReducer