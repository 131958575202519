import Modal from 'react-bootstrap/Modal'
import { BsThreeDots } from 'react-icons/bs'
import { photoModal } from 'actions/util/photoModal.action';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import { Button } from "react-bootstrap";
import { uploadFiles } from 'actions/fileUpload.action'
import ExpandableText from './ExpandableText';
import useScreenSize from 'hooks/screenSize';
import React, { useState } from 'react'
import GalleryPreviewModal from '../gallery/GalleryPreviewModal'
import NameDisplayPicture from 'components/common/NameDisplayPicture';
import UploadProgressBar from './UploadProgressBar';

const PhotoModal = ({ campaignsSelected, photoModal, modalObject, uploadFiles, user, isMonthFilterApplied, posts }) => {
  
  const [previewIndex, setPreviewIndex] = useState(modalObject.selectedIndex)
  const closePhotoModal = () => {
    photoModal({isShowPhotoModal: false, object: {}, isPreviewOption: false})
    setPreviewIndex(null)
  }
  const [isUploadActionsDisabled, setIsUploadActionsDisabled] = useState(false)
  const { isDesktop } = useScreenSize()

  const tempDate = new Date(modalObject.isPreviewOption ? modalObject.object.post.dateCreated : posts.dataProcessed[previewIndex].post.dateCreated).toString().split(' ');
  const formattedDate = `${tempDate[1]} ${+tempDate[2]}`;

  const onNext = () => {
    if(modalObject.isPreviewOption){
      if(previewIndex < modalObject.object.post.files.length-1){
        setPreviewIndex(previewIndex+1)
      }
    } else {
      if(previewIndex < posts.dataProcessed.length-1){
        setPreviewIndex(previewIndex+1)
      }
    }
  }

  const onClickPost = () => {
    uploadFiles(modalObject.object.post.files, modalObject.object.post.country, modalObject.object.post.description, user, campaignsSelected[0]._id, isMonthFilterApplied)
    setIsUploadActionsDisabled(true)
  }

  const onPrevious = () => {
    if(previewIndex>0){
      setPreviewIndex(previewIndex-1)
    }
  }

  return (
    <Modal show={modalObject.isShowPhotoModal} onHide={closePhotoModal} centered  keyboard={false} size="xl" aria-labelledby="contained-modal-title-vcenter">
      <Modal.Body className="px-0 py-0 details-div">
        <div className="d-flex w-100 main-div">
          <GalleryPreviewModal
            previewIndex={previewIndex}
            item={modalObject.isPreviewOption ? modalObject : {isPreviewOption: false}}
            file={modalObject.isPreviewOption ? modalObject.object.post.files[previewIndex] : posts.dataProcessed[previewIndex]}
            maxPosts={modalObject.isPreviewOption ? modalObject.object.post.files.length : posts.dataProcessed.length}
            onNext={onNext}
            onPrevious={onPrevious}
          />
          <div className="d-flex align-items-center flex-column info-div">
            <div className='bg-white px-4 d-flex justify-content-center flex-column w-100 author-description-div'>
              <div className='d-flex align-items-start justify-content-between'>
                  <div className='d-flex align-items-center'>
                      {
                        modalObject.isPreviewOption
                        ? (
                          modalObject.object.post.profileUrl
                          ? <img src={modalObject.object.post.profilePicUrl} className="blog-post-author-photo" alt="Profile"/>
                          : <NameDisplayPicture firstName={modalObject.object.post.createdBy.split('')[0]} lastName={modalObject.object.post.createdBy.split(' ', 2)[1] || ''}/>
                        )
                        : (
                          posts.dataProcessed[previewIndex].post.profileUrl
                          ? <img src={posts.dataProcessed[previewIndex].post.profilePicUrl} className="blog-post-author-photo" alt="Profile"/>
                          : <NameDisplayPicture firstName={posts.dataProcessed[previewIndex].post.createdBy.split('')[0]} lastName={posts.dataProcessed[previewIndex].post.createdBy.split(' ', 2)[1] || ''}/>
                        )
                      }
                      <div className='d-inline-flex flex-column ms-2'>
                        <div className='fw-bold text-capitalize'>{modalObject.isPreviewOption ? modalObject.object.post.createdBy : posts.dataProcessed[previewIndex].post.createdBy}</div>
                        <small className='text-muted mt-n1'>{formattedDate}</small>
                        <small className='text-muted mt-n1'>{modalObject.isPreviewOption ? '' : posts.dataProcessed[previewIndex].post.campaignName}</small>
                      </div>
                  </div>
                  <div className='pe-2'>
                    <BsThreeDots size={20}/>
                  </div>
              </div>
              <div className='d-flex align-items-start'>
                <ExpandableText maxExpandedHeight={isDesktop ? 300 : undefined}>
                  {modalObject.isPreviewOption ? modalObject.object.post.description : posts.dataProcessed[previewIndex].post.description}
                </ExpandableText>
              </div>
            </div>
            <UploadProgressBar/>
            { modalObject.isPreviewOption && !modalObject.isLiveUrl ? 
                <div className='bg-white px-4 pb-1 d-flex flex-row w-100 justify-content-end buttons-actions'>
                  <Button variant="primary" disabled= {isUploadActionsDisabled} className="modify-button-backgroud me-2" onClick={closePhotoModal} >
                    Modify
                  </Button>
                  <Button className="post-button-backgroud" disabled= {isUploadActionsDisabled}
                  onClick={() => { onClickPost() }} >
                    Post
                  </Button>
                </div>
                : <div className='bg-white px-4 d-flex flex-row w-100 justify-content-end buttons-actions'></div>
              }
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

PhotoModal.propTypes = {
  photoModal: PropTypes.func.isRequired,
  uploadFiles: PropTypes.func.isRequired,
  campaignsSelected: PropTypes.array.isRequired,
  isMonthFilterApplied: PropTypes.string.isRequired
};
const mapStateToProps = (state) => ({
  modalObject: state.photoModal,
  posts: state.galleryPosts.posts,
  campaignsSelected: state.campaigns.campaignsSelected,
  isMonthFilterApplied: state.blogPost.isMonthFilterApplied,
  user: state.auth.user
})

export default connect(mapStateToProps , { photoModal, uploadFiles })(PhotoModal)
