import axios from 'axios';
import { STORAGE_MANAGEMENT_API_URL } from 'lib/urlConstants';
import { createBlogPost, editBlogPost } from './blogPost.action'
import { EDIT_BLOG_POST, FILE_UPLOAD_PROGRESS, PROFILE_UPLOADED_SUCCESSFULLY } from './util/types.action';

export const uploadFiles = (files, country, description, user, campaignId, isMonthFilterApplied ) => async (dispatch) => {
  try {
    const FormData = require('form-data');
    const formData = new FormData();

    for( const file of files){
      formData.append('files', file);
    }
    const headersList = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'text/plain',
      'Authorization': localStorage.token && localStorage.user
      ? `Bearer ${localStorage.token}`
      : ''
      }
    const reqOptions = {
      url:`${STORAGE_MANAGEMENT_API_URL}/v2/connect/campaign/file-upload?isoCountry=${country.toLowerCase()}`,
      method: "POST",
      headers: headersList,
      data: formData,
      onUploadProgress: progressEvent => {
        dispatch({ type: FILE_UPLOAD_PROGRESS, payload: Math.ceil((progressEvent.loaded/progressEvent.total)*100) })
      }
    }
    const res = await axios.request(reqOptions)
    if (res.status === 200) {
      await dispatch({ type: FILE_UPLOAD_PROGRESS, payload: 0 })
      const requestBody = { 
        profileUrl: user.profilePic, 
        fileUrls: res.data.fileNames, 
        _partition: country,
        description: description,
        country: country,
        createdBy: user.firstName + ' ' + user.lastName,
        campaignId: campaignId,
        isDeleted: false,
        isMonthFilterApplied
      }
      await dispatch(createBlogPost(requestBody))
    } else { 
       console.log('error',res.data.message)
    }
  } catch (error) {
      console.log('error',error)
  }
}

export const uploadEditedFiles = (feedPost) => async (dispatch) => {
  await dispatch({ type: EDIT_BLOG_POST, payload: { status: false } })
  try {
    const FormData = require('form-data');
    const formData = new FormData();

    if (feedPost.additionalUploads.length > 0) {
      for( const file of feedPost.additionalUploads) {
        formData.append('files', file);
      }
      const headersList = {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'text/plain',
        'Authorization': localStorage.token && localStorage.user
        ? `Bearer ${localStorage.token}`
        : ''
      }
      const reqOptions = {
        url:`${STORAGE_MANAGEMENT_API_URL}/v2/connect/campaign/file-upload?isoCountry=${feedPost.country.toLowerCase()}`,
        method: "POST",
        headers: headersList,
        data: formData,
        onUploadProgress: progressEvent => {
          dispatch({ type: FILE_UPLOAD_PROGRESS, payload: Math.ceil((progressEvent.loaded/progressEvent.total)*100) })
        }
      }
      const res = await axios.request(reqOptions)
      if (res.status === 200) {
        await dispatch({ type: FILE_UPLOAD_PROGRESS, payload: 0 })
        const requestBody = {
          _id: feedPost._id,
          fileNames: res.data.fileNames,
          selfLinks: res.data.selfLinks,
          _partition: feedPost.country,
          description: feedPost.description,
          files: feedPost.files,
          fileUrls: feedPost.fileUrls
        }
        await dispatch(editBlogPost(requestBody))
      } else { 
        console.log('error', res.data.message)
      }
    } else {
      const requestBody = {
        _id: feedPost._id,
        _partition: feedPost.country,
        description: feedPost.description,
        files: feedPost.files,
        fileUrls: feedPost.fileUrls
      }
      await dispatch(editBlogPost(requestBody))
    }
  } catch (error) {
      console.log('error',error)
  }
} 

export const uploadProfilePicture = (file, country) => async (dispatch) => {
  try {
    const FormData = require('form-data')
    const formData = new FormData()
    formData.append('file', file)
    const headersList = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'text/plain',
      'Authorization': localStorage.token && localStorage.user
      ? `Bearer ${localStorage.token}`
      : ''
    }
    const reqOptions = {
      url:`${STORAGE_MANAGEMENT_API_URL}/v2/image/user/profile-picture?isoCountry=${country.toLowerCase()}`,
      method: "POST",
      headers: headersList,
      data: formData,
    }
    const res = await axios.request(reqOptions)
    if (res.status === 200) {
      dispatch({type: PROFILE_UPLOADED_SUCCESSFULLY, payload: res.data.imageName })
    } else { 
      console.log('error',res.data.message)
    }
  } catch (error) {
    console.log('error',error)
  }
} 