
import { 
  CREATE_BLOG_POST, BLOG_POST_LIST, DELETE_BLOG_POST, BLOG_POST_MODAL, DATE_FILTER, BLOG_POST_COUNTRY_FILTER, BLOG_POST_LIST_IS_LOADING, EDIT_BLOG_POST, SHOW_EDIT_MODAL 
} from './util/types.action';
import api from '../lib/api.lib'
import { photoModal } from './util/photoModal.action'
import { getSignedUrls, getProfilePicSignedUrls } from 'actions/getSignedUrls'
const API_URL = process.env.REACT_APP_API || "http://localhost:4000";

const createdBlogPost = (data) => ({
  type: CREATE_BLOG_POST,
  payload: data
})

export const blogPostModal = ({ isShowBlogPostModal }) => ({
  type: BLOG_POST_MODAL,
  payload: { isShowBlogPostModal }
})

const deletedBlogPost = (data) => ({
  type: DELETE_BLOG_POST,
  payload: data
})

const blogPostListData = (data) => ({
  type: BLOG_POST_LIST,
  payload: data
})

export const setCountryFilter = ({ selectedCountryFilter, isCountryChanged }) => ({
  type: BLOG_POST_COUNTRY_FILTER,
  payload: { selectedCountryFilter, isCountryChanged }
})

export const setDateFilter = (filter) => ({
  type: DATE_FILTER,
  payload: filter
})

export const createBlogPost = (data) => async (dispatch) => {
  try {
    const region = data.country === "IDN" ? "IDN" : "IND"
    const res = await api.post(`${API_URL}/campaign/feed-post/${region}`, data)
      if (res.status === 200) {
        await dispatch(createdBlogPost(res.data))
        dispatch(photoModal({isShowPhotoModal: false, object: {}, isPreviewOption: false, selectedIndex: 0}))
        dispatch(blogPostModal({ isShowBlogPostModal: false }))
        await dispatch(getBlogPostListByCampaignIds({ isoCountry: data.country, ids: [data.campaignId], isMonthFilterApplied: data.isMonthFilterApplied }))
      } else { 
        console.log('error',res.data.message)
      }
    } catch (error) {
        console.log('error',error)
    }
}

export const toggleEditModal = (data) => async (dispatch) => {
  await dispatch({ type: SHOW_EDIT_MODAL, payload: data })
}

export const editBlogPost = (data) => async (dispatch) => {
  await dispatch({ type: EDIT_BLOG_POST, payload: { status: false } })
  try {
    const region = data.country === "IDN" ? "IDN" : "IND"
    const res = await api.post(`${API_URL}/campaign/feed-post/${region}/${data._id}`, data)
    if (res.status === 200) {
      await dispatch({
        type: EDIT_BLOG_POST,
        payload: {
          status: true,
          edited: res.data.data,
          files: [
            ...data.files.filter(fu => !fu.deleted),
            ...(data.selfLinks?.map(sl => ({ imageUrl: sl })) || [])
          ]
        }
      })
      await dispatch({ type: SHOW_EDIT_MODAL, payload: null })
    } else {
      console.log('error', res.data.message)
    }
  } catch (error) {
    console.log('error',error)
  } finally {
    await dispatch({ type: EDIT_BLOG_POST, payload: { status: true } })
  }
} 

export const getBlogPostListByCampaignIds = (data) => async (dispatch, getState) => {
  await dispatch(blogPostListData(null))
  await dispatch({ type: BLOG_POST_LIST_IS_LOADING, payload: true })
  try {
    const ids= data.ids
    const isMonthFilterApplied = data.isMonthFilterApplied
    const userRole = data.userRole
    const region = data.isoCountry === "IDN" ? "IDN" : "IND"
    const params = { isoCountry: data.isoCountry, isMonthFilterApplied, ids, region, userRole }
    let res ={ status: 0 }
    res = await api.get(`${API_URL}/campaign/feed-post/campaign/${region}`, {
      params: params
    })
    if (res.status === 200) {
      const imageUrls = []
      const profileUrls = []
      res.data.data.map((object)=> {
        imageUrls.push({ imageNames: object.fileUrls, id: object._id})
        profileUrls.push({ imageName: object.profileUrl, id: object._id })
        return object
      })
      await dispatch(getProfilePicSignedUrls(region, profileUrls, userRole))
      await dispatch(getSignedUrls(region, imageUrls, userRole))
      const urls = getState().signedUrl.signedUrl
      const profilePicUrls = getState().signedUrl.profilePicSignedUrls
      const campaignsSelected = getState().campaigns.campaignsSelected
      res.data.data.map((object) => {
        object.files = []
        profilePicUrls.map((url) => {
          if(url.id === object._id){
            object.profilePicUrl = url.imageUrl
          }
          return url
        })
        campaignsSelected.map((campaign) => {
          if(campaign._id === object.campaignId){
            object.name = campaign.name
          }
          return campaign
        })
        urls.map((url) => {
          if(url.id === object._id){
            object.files.push(url)
          }
          return url
        })
        return object
      })
      await dispatch(blogPostListData(res.data))
      await dispatch({ type: BLOG_POST_LIST_IS_LOADING, payload: false })
    } else {
      await dispatch(blogPostListData([]))
      await dispatch({ type: BLOG_POST_LIST_IS_LOADING, payload: false })
    }
  } catch (error) {
    await dispatch(blogPostListData([]))
    await dispatch({ type: BLOG_POST_LIST_IS_LOADING, payload: false })
  }
}

export const deleteBlogPost = (data) => async (dispatch) => {
  try {
    const region = data.country === "IDN" ? "IDN" : "IND"
    const id = data.id
    const params = { userId: data.userId }
    const res = await api.delete(`${API_URL}/campaign/feed-post/${region}/${id}`,{
      params: params
    })
    if (res.status === 200) {
      await dispatch(deletedBlogPost(res.data))
      await dispatch(getBlogPostListByCampaignIds({ isoCountry: data.country, ids: [data.campaignId], isMonthFilterApplied: data.isMonthFilterApplied }))
    } else { 
      console.log('error',res.data.message)
    }
  } catch (error) {
      console.log('error',error)
  }
} 
