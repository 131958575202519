import { updateCAUser, updateCAPassword } from 'lib/centralizedAuthAPI'
import React, { useEffect, useState } from 'react'
import { Button, Container, Form, Spinner, Tab, Tabs } from 'react-bootstrap'
import { toast } from 'react-hot-toast'
import { connect } from 'react-redux'
import { startSession, startLogout } from '../../actions/auth.action'
import { CrossStorageClient } from 'cross-storage'
import { AUTH_CLIENT_HOST_URL } from 'lib/centralizedAuthUrls'
import '../../res/styles/profile-settings.styles.scss'
import useTitle from 'hooks/useTitle'

const Profile = ({ user, startLogout, startSession }) => {

  useTitle('Profile')

  const [formValues, setFormValues] = useState(null)
  const [isRequesting, setIsRequesting] = useState({
    passwordUpdate: false,
    profileUpdate: false
  })

  useEffect(() => {
    setFormValues({
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      username: user.username,
      currentPassword: '',
      newPassword: '',
      confirmPassword: ''
    })
  }, [user])

  const handleControlChange = (e) => {
    if (formValues !== null) {
      setFormValues({
        ...formValues,
        [e.target.name]: e.target.value
      })
    }
  }

  const validateProfile = () => {
    if (formValues.firstName.length < 2) {
      toast.error('The first name must contain at least 2 letters')
      return false
    } else if (formValues.lastName.length < 2) {
      toast.error('The last name must contain at least 2 letters')
      return false
    }
    return true
  }

  const updateProfile = (e) => {
    e.preventDefault()
    if (validateProfile()) {
      setIsRequesting({ ...isRequesting, profileUpdate: true })
      updateCAUser(user, formValues).then(res => {
        setIsRequesting({ ...isRequesting, profileUpdate: false })
        if (res.success === true) {
          toast.success('Profile details updated!')
          postUpdateProfile()
        } else {
          for (const message of res.message) {
            toast.error(message)
          }
        }
      }).catch(err => {
        console.log(err)
        toast.error('Something went wrong!')
      })
    }
  }

  const postUpdateProfile = async () => {
    toast.loading('Refreshing session...')
    if (user?.userRole?.includes('app-external-user')) {
      await startSession(localStorage.token, user.id, 'insights', 'app-external-user')
      window.location.reload()
    } else {
      const storage = new CrossStorageClient(AUTH_CLIENT_HOST_URL, {
        timeout: 10000,
        frameId: 'storageFrame',
      })
      storage.onConnect().then(async () => {
        const session = JSON.parse(await storage.get('session'))
        const isSessionActive = session.insights.isActive
        if (isSessionActive) {
          const token = session.token
          const userId = session.userId
          const appId = session.insights.appId
          if (token) {
            await startSession(token, userId, appId, '')
            window.location.reload()
          }
        }
      })
        .catch(function (err) { })
    }
  }

  const validatePassword = () => {
    if (!formValues.currentPassword) {
      toast.error('Current password is required')
      return false
    } else if (!formValues.newPassword) {
      toast.error('New password is required')
      return false
    } else if (formValues.newPassword.length < 8 || formValues.newPassword.length > 32) {
      toast.error('The password length must be between 8 and 32')
      return false
    } else if (formValues.newPassword !== formValues.confirmPassword) {
      toast.error('The password confirmation did not match with your new password')
      return false
    }
    return true
  }

  const updatePassword = (e) => {
    e.preventDefault()
    if (validatePassword()) {
      setIsRequesting({ ...isRequesting, passwordUpdate: true })
      updateCAPassword(formValues, user).then(res => {
        setIsRequesting({ ...isRequesting, passwordUpdate: false })
        if (res.success === true) {
          toast.success('Profile details updated!')
          toast.loading('Refreshing session...')
          setTimeout(() => {
            startLogout(user)
          }, 2000)
        } else {
          for (const message of res.message) {
            toast.error(message)
          }
        }
      }).catch(err => {
        toast.error('Something went wrong!')
      })
    }
  }

  return <Container className='profile-settings'>
    <div className='mt-5 container-small'>

      <h6 className='fw-bold mb-4'>Profile Settings</h6>

      <Tabs defaultActiveKey="general" id="uncontrolled-tab-example" className="mb-3">
        <Tab eventKey="general" title="General">
          <Form onSubmit={updateProfile}>
            <Form.Group className="mb-3">
              <Form.Label className='fs-sm fw-500'>First Name</Form.Label>
              <Form.Control type='text' maxLength={128} value={formValues?.firstName || ''} onChange={handleControlChange} name='firstName' className='fs-sm fw-500 py-2 rounded-3 text-capitalize' />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className='fs-sm fw-500'>Last Name</Form.Label>
              <Form.Control type='text' maxLength={128} value={formValues?.lastName || ''} onChange={handleControlChange} name='lastName' className='fs-sm fw-500 py-2 rounded-3 text-capitalize' />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className='fs-sm fw-500'>Username</Form.Label>
              <Form.Control type='text' disabled value={formValues?.username || ''} className='fs-sm fw-500 py-2 rounded-3' />
            </Form.Group>
            {user.userRole.includes('app-external-user') &&
              <>
                <Form.Label className='fs-sm fw-500'>Organisation</Form.Label>
                <div className='ps-2 fs-sm fw-bold'>
                  {user.organization.map((val) => val.name).join(', ')}
                </div>
              </>
            }
            <div className='border-top pt-3'>
              <Button variant="primary" disabled={isRequesting.profileUpdate} type="submit">
                {isRequesting.profileUpdate ? <Spinner animation="border" size='sm' className='me-2' /> : null}
                Update
              </Button>
            </div>
          </Form>
        </Tab>
        <Tab eventKey="password" title="Update Password">
          <Form onSubmit={e => updatePassword(e)}>
            <Form.Group className="mb-3">
              <Form.Label className='fs-sm fw-500'>Current Password</Form.Label>
              <Form.Control type='password' name='currentPassword' value={formValues?.currentPassword || ''} onChange={handleControlChange} className='fs-sm fw-500 py-2 rounded-3' />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className='fs-sm fw-500'>New Password</Form.Label>
              <Form.Control type='password' name='newPassword' value={formValues?.newPassword || ''} onChange={handleControlChange} className='fs-sm fw-500 py-2 rounded-3' />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className='fs-sm fw-500'>Confirm new password</Form.Label>
              <Form.Control type='password' name='confirmPassword' value={formValues?.confirmPassword || ''} onChange={handleControlChange} className='fs-sm fw-500 py-2 rounded-3' />
            </Form.Group>

            <div className='border-top pt-3'>
              <Button variant="primary" type="submit" disabled={isRequesting.passwordUpdate}>
                {isRequesting.passwordUpdate ? <Spinner animation="border" size='sm' className='me-2' /> : null}
                Change Password
              </Button>
            </div>
          </Form>
        </Tab>
      </Tabs>



    </div>
  </Container>
}

const mapStateToProps = (state) => ({
  user: state.auth.user
})

export default connect(mapStateToProps, { startSession, startLogout })(Profile)