import api from "lib/api.lib";
import { NEWS_LETTER_SUBSCRIPTION_STATUS, NEWS_LETTER_SUBSCRIPTION_GET_STATUS, NEWS_LETTER_SUBSCRIPTION_POST_STATUS } from "./util/types.action";

export const getSubscriptionStatus = () => async (dispatch, getState) => {
  try {
    await dispatch({ type: NEWS_LETTER_SUBSCRIPTION_GET_STATUS, payload: 'requesting' });
    await dispatch({ type: NEWS_LETTER_SUBSCRIPTION_STATUS, payload: null });

    const res = await api.get(`${process.env.REACT_APP_API}/news-letter/subscription-status?userRole=${encodeURIComponent(getState()?.auth?.user.userRole)}`);
    if (res.status === 200) {
      await dispatch({ type: NEWS_LETTER_SUBSCRIPTION_STATUS, payload: res.data });
      await dispatch({ type: NEWS_LETTER_SUBSCRIPTION_GET_STATUS, payload: 'success' });
    } else {
      await dispatch({ type: NEWS_LETTER_SUBSCRIPTION_GET_STATUS, payload: 'failed' });
    }
  } catch (error) {
    await dispatch({ type: NEWS_LETTER_SUBSCRIPTION_GET_STATUS, payload: 'failed' });
  }
};

export const setSubscriptionStatus = (status) => async (dispatch, getState) => {
  try {
    await dispatch({ type: NEWS_LETTER_SUBSCRIPTION_POST_STATUS, payload: 'requesting' });
    await dispatch({ type: NEWS_LETTER_SUBSCRIPTION_STATUS, payload: null });

    const res = await api.post(`${process.env.REACT_APP_API}/news-letter/subscription-status?userRole=${encodeURIComponent(getState()?.auth?.user.userRole)}`, status);
    if (res.status === 200) {
      await dispatch({ type: NEWS_LETTER_SUBSCRIPTION_STATUS, payload: res.data });
      await dispatch({ type: NEWS_LETTER_SUBSCRIPTION_POST_STATUS, payload: 'success' });
    } else {
      await dispatch({ type: NEWS_LETTER_SUBSCRIPTION_POST_STATUS, payload: 'failed' });
    }
  } catch (error) {
    await dispatch({ type: NEWS_LETTER_SUBSCRIPTION_POST_STATUS, payload: 'failed' });
  }
};

export const resetInquiryStatus = () => async (dispatch) => {
  await dispatch({ type: NEWS_LETTER_SUBSCRIPTION_GET_STATUS, payload: '' });
  await dispatch({ type: NEWS_LETTER_SUBSCRIPTION_POST_STATUS, payload: '' });
}