import { GET_SIGNED_URLS, GET_PROFILE_PIC_SIGNED_URL, GET_PROFILE_PIC_SIGNED_URLS } from "actions/util/types.action"

const initialState = {
  signedUrl : [],
  profilePicSignedUrl: '',
  profilePicSignedUrls: []
}

const signedUrlReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case GET_SIGNED_URLS:
      return {
        ...state,
        signedUrl: payload.imageUrls
      }
    case GET_PROFILE_PIC_SIGNED_URL:
      return {
        ...state,
        profilePicSignedUrl: payload.imageUrl
      }
    case GET_PROFILE_PIC_SIGNED_URLS:
      return {
        ...state,
        profilePicSignedUrls: payload.imageUrls
      }
    default:
      return state
  }
}

export default signedUrlReducer