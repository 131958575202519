import React from 'react'
import { Container, Navbar } from 'react-bootstrap'
import Logo from '../../res/img/reach52-beta-logo.png'

const GuestNavbar = () => {
  return <Navbar className='top-navbar border-bottom' expand="lg" fixed='top'>
    <Container className='d-flex align-items-center'>
        <Navbar.Brand href='/' className='d-flex align-items-center'>
        <img src={Logo} height="24" className="d-inline-block align-top" alt="Logo"/>
        </Navbar.Brand>
    </Container>
  </Navbar>
}

export default GuestNavbar