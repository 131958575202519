import React, { useState, useEffect } from 'react'
import { Container, Accordion } from "react-bootstrap";
import ProgressBar from 'react-bootstrap/ProgressBar'; 
import '../../res/styles/viewCampaignDetails.styles.scss';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getUserCountryName, getFormattedDate } from '../../lib/common.lib'
import { Dropdown } from 'react-bootstrap'
import currentDiagnosis from '../util/static/DiagnosisList.static'

const ViewCampaignsDetails = ({
    campaignsSelected, campaignProgress
}) => {
    const [SelectedEngagement, setSelectedEngagement] = useState(Array(campaignsSelected.length).fill({
        name: '',
        location: '',
        age: '',
        gender: '',
        disease: ''
    }));
    const [overallEngagementProgress, setOverallEngagementProgress] = useState(0);
    const [targetEngagement, setTargetEngagement] = useState(0);
    const [overallTrainingProgress, setOverallTrainingProgress] = useState(0);
    const [targetTraining, setTargetTraining] = useState(0);
    const [engagementIndexArray, setEngagementIndexArray] = useState(Array(campaignsSelected.length).fill(0));

    const onValueChange = (engagement, index, engagementIndex) => {
        if (engagement) {
            const diagnosis = engagement?.criteria?.currentDiagnosis?.length > 0 ? engagement.criteria.currentDiagnosis : engagement?.criteriaV2?.medicalConditions?.length > 0 ? engagement.criteriaV2.medicalConditions: []
            const data = {
                name: engagement.name,
                location: (engagement?.criteriaV2?.level3 ? engagement.criteriaV2.level3 + ', ' : '') + (engagement?.criteriaV2?.level2 ? engagement.criteriaV2.level2 + ', ' : '') + (engagement?.criteriaV2?.countryISO? getUserCountryName(engagement?.criteriaV2?.countryISO): ''),
                age: (engagement?.criteriaV2?.age?.minimum || engagement?.criteriaV2?.age?.minimum === 0 ? engagement.criteriaV2.age.minimum + '-' : '') + (engagement?.criteriaV2?.age?.maximum ? engagement.criteriaV2.age.maximum + ' years' : ''),
                gender: engagement?.criteriaV2?.genders?.length >= 2 ? 'All' : engagement?.criteriaV2?.genders ? engagement.criteriaV2.genders[0] : '',
                disease: diagnosis?.map((element) => currentDiagnosis[element]? currentDiagnosis[element] : element).join(', ')
            }
            let newData = SelectedEngagement[index] || {}
            newData=data
            SelectedEngagement[index]=newData
            setSelectedEngagement(SelectedEngagement => [...SelectedEngagement])

            let newEngagementIndex = engagementIndexArray[index] || 0
            newEngagementIndex = engagementIndex
            engagementIndexArray[index]=newEngagementIndex
            setEngagementIndexArray(engagementIndexArray => [...engagementIndexArray])
        }
    }
    const onItemChange = (object, index) => {
        if (object.engagementsData?.length > 0) {
            onValueChange(
                object.engagementsData
                ?
                object.engagementsData[engagementIndexArray[index] ? engagementIndexArray[index]: 0]
                : '',
                index,
                engagementIndexArray[index] ? engagementIndexArray[index] : 0
            )
        }
    }

    useEffect(()=> {
        if (Object.keys(campaignProgress).length > 0) {
          let overallProgress = 0
          let target = 0
          for (const obj of campaignProgress.engagement) {
              overallProgress += obj.overallProgress
              target += obj.targetEngagement
          }
          setTargetEngagement(target)
          setOverallEngagementProgress(overallProgress)
          overallProgress = 0
          target = 0
          for (const obj of campaignProgress.training) {
              overallProgress += obj.overallProgress
              target += obj.targetTraining
          }
          setTargetTraining(target)
          setOverallTrainingProgress(overallProgress)
        }
      }, [setTargetEngagement, setOverallEngagementProgress, setTargetTraining, setOverallTrainingProgress, campaignProgress])
    
    return ( <>
        { campaignsSelected.length >= 1 
            ? 
            (<Container fluid className='custom-container'>
                <div>
                    <h5 className="fw-500 fs-3">Campaign Details</h5>
                </div>
                <div className='border-top mt-2 mb-3'></div>
                <div className="row g-3 row-cols-1 row-cols-lg-3 w-75 pb-2">
                    <div >
                        <div className='text-title'>Target Residents</div>
                        <div className='text-ans'>{overallEngagementProgress.toString() + '/' + targetEngagement.toString()}</div>
                        <div > <ProgressBar className='progress-actual'  >
                            <ProgressBar className='tr-progress-now' now={overallEngagementProgress} max={targetEngagement} /> </ProgressBar>
                        </div>
                    </div>
                    <div >
                        <div className='text-title'>Target Trainings</div>
                        <div className='text-ans'>{overallTrainingProgress.toString() + '/' + targetTraining.toString()}</div>
                        <div > <ProgressBar className='progress-actual'  >
                            <ProgressBar className='tt-progress-now' data-aos="slide-right" data-aos-duration="500" now={overallTrainingProgress} max={targetTraining} />
                        </ProgressBar>
                        </div>
                    </div>
                </div>
                <Accordion flush>
                {campaignsSelected.map((campaign,index) => {
                    const targetResident = campaign.targetResidents !== 0 ? campaign.targetResidents : campaign.targetEngagements.target
                    return (
                        <Accordion.Item eventKey={`${index}`} key={index}>
                            <Accordion.Header onClick={() => { onItemChange(campaign, index) }}>
                                <h5 className="fw-500 fs-3">{campaign.name}</h5>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className="row g-3 row-cols-1 row-cols-lg-3 w-75">
                                    <div className=' row-cols-2'>
                                        <div className='d-inline-flex flex-column'>
                                            <div className='text-title'>Country</div>
                                            <div className='text-ans'>{campaign.country}</div>
                                        </div>

                                        <div className='d-inline-flex flex-column'>
                                            <div className='text-title'>Start Date</div>
                                            <div className='text-ans'>{campaign?.duration?.dateStart?.length > 1 ? getFormattedDate(campaign.duration.dateStart) : ''}</div>
                                        </div>
                                    </div>
                                    <div >
                                        <div className='text-title'>Target Residents</div>
                                        <div className='text-ans'>{campaign.targetEngagements.achieved.toString() + '/' + targetResident.toString()}</div>
                                        {/* <div className='text-ans'>{campaign.targetEngagements.achieved.toString() + '/' + campaign.targetEngagements.target.toString()}</div> */}
                                        <div > <ProgressBar className='progress-actual'  >
                                        {/* <ProgressBar className='tr-progress-now' now={campaign.targetEngagements.achieved} max={campaign.targetEngagements.target} /> </ProgressBar> */}
                                            <ProgressBar className='tr-progress-now' now={campaign.targetEngagements.achieved} max={targetResident} /> </ProgressBar>
                                        </div>
                                    </div>
                                    <div >
                                        <div className='text-title'>Target Trainings</div>
                                        <div className='text-ans'>{campaign.targetTrainings.achieved.toString() + '/' + campaign.targetTrainings.target.toString()}</div>
                                        <div > <ProgressBar className='progress-actual'  >
                                            <ProgressBar className='tt-progress-now' data-aos="slide-right" data-aos-duration="500" now={campaign.targetTrainings.achieved} max={campaign.targetTrainings.target} />
                                        </ProgressBar>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <h5 className="fw-700 mt-4 fs-4">Campaign Details</h5>
                                </div>

                                <div className='flex-column'>
                                    <div className='d-inline-flex flex-row mt-1 width-90 gap-4'>
                                        <div className='text-title w-25'>Description</div>
                                        <div className='text-ans w-75 ps-2'>{campaign.description}</div>
                                    </div>
                                    <div className='d-inline-flex flex-row mt-3 width-90 gap-4'>
                                        <div className='text-title w-25'>Campaign Manager</div>
                                        <div className='text-ans w-75 ps-2'>Maria Winarni, maria@reach52.com</div>
                                    </div>
                                </div>

                                <div className='border-top mt-4'></div>
                                <div>
                                    <h5 className="fw-700 mt-3 fs-4">Target Profile</h5>
                                </div>
                                {campaign.engagementsData ?
                                    (
                                        <div className='flex-column gap-4'>
                                            <div className='d-inline-flex flex-row width-90 gap-4'>
                                                <div className='text-title w-25'>Engagement</div>
                                                <div className='text-ans w-75 ps-2'>
                                                    <Dropdown
                                                        drop={'down'}
                                                        hidden={campaign?.engagementsData?.length === 0}>
                                                        <Dropdown.Toggle size='sm' id="dropdown-basic">
                                                            {SelectedEngagement.length >= 1 ? SelectedEngagement[index].name || '' : ''}
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>
                                                            {campaign.engagementsData.map((obj, objIndex) => {
                                                                return (
                                                                    <Dropdown.Item onClick={() => { onValueChange(obj, index, objIndex) }} key={'item-' + objIndex} >{obj.name}</Dropdown.Item>
                                                                )
                                                            })}

                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    <span hidden={campaign?.engagementsData?.length > 0}>-</span>
                                                </div>
                                            </div>

                                            <div className='d-inline-flex flex-row mt-2 width-90 gap-4'>
                                                <div className='text-title w-25'>Location</div>
                                                <div className='text-ans w-75 ps-2'>{SelectedEngagement.length >= 1 ? SelectedEngagement[index].location || '-' : '-'}</div>
                                            </div>
                                            <div className='d-inline-flex flex-row mt-2 width-90 gap-4'>
                                                <div className='text-title w-25'>Age</div>
                                                <div className='text-ans w-75 ps-2'>{SelectedEngagement.length >= 1 ? SelectedEngagement[index].age || '-': '-'}</div>
                                            </div>
                                            <div className='d-inline-flex flex-row mt-2 width-90 gap-4'>
                                                <div className='text-title w-25'>Gender</div>
                                                <div className='text-ans w-75 ps-2'>{SelectedEngagement.length >= 1 ? SelectedEngagement[index].gender || '-': '-'}</div>
                                            </div>
                                            {/* Hidden the list of diseases */}
                                            {/* <div className='d-inline-flex flex-row mt-2 width-90 gap-4'>
                                                <div className='text-title w-25'>Disease</div>
                                                <div className='text-ans w-75 ps-2'>{SelectedEngagement.length > 0 ? SelectedEngagement[index].disease || '-': '-'}</div>
                                            </div> */}

                                        </div>
                                    ) : ''
                                }

                            </Accordion.Body>
                        </Accordion.Item>
                    )
                })}
                </Accordion>
            </Container>)
            :
            <></> }
        </>
    );
}


ViewCampaignsDetails.propTypes = {
    campaignsSelected: PropTypes.array.isRequired,
    campaignProgress: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    campaignsSelected: state.campaigns.campaignsSelected,
    campaignProgress: state.campaigns.campaignProgress
})

export default connect(mapStateToProps, { })(ViewCampaignsDetails)