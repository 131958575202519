import React, { useState, useEffect } from 'react'
import { Button, Container, Form, Modal, ProgressBar } from "react-bootstrap";
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { GetCampaignById, getCampaignProgress, updateCampaignProgress, deleteProgress } from '../../actions/campaign.action'
import { getFormattedDate } from 'lib/common.lib';
import dayjs from 'dayjs';
import { GoPencil, GoTrashcan } from 'react-icons/go'
import toast from 'react-hot-toast';
import Loading from 'components/util/Loading';
import useTitle from 'hooks/useTitle';

const UpdateCampaignProgress = ({
    campaign, campaignProgress, GetCampaignById, getCampaignProgress, updateCampaignProgress, user, deleteProgress
}) => {
    
    const title = useTitle()

    const { campaignId } = useParams()

    const [updateModalStatus, setUpdateModalStatus] = useState(null)
    const [filterGroup, setFilterGroup] = useState({
        engagement: 'datePosted',
        training: 'datePosted'
    })
    const [progressToDelete, setProgressToDelete] = useState(null)

    useEffect(() => {
        if (campaign?.name) {
            title.setTitle(`Update Progress - ${campaign.name}`)
        }
    }, [campaign])

    useEffect(() => {
        if (campaignId) {
            GetCampaignById(campaignId, user.userRole.includes('app-external-user') ? 'app-external-user': '', user.country.iso3)
        }
    }, [GetCampaignById, campaignId, user])

    useEffect(() => {
        if(campaignId){
            getCampaignProgress(campaignId, filterGroup.engagement, 'engagement', user.userRole.includes('app-external-user') ? 'app-external-user': '')
        }
    }, [campaignId, getCampaignProgress, filterGroup.engagement, user])

    useEffect(() => {
        if(campaignId){
            getCampaignProgress(campaignId, filterGroup.training, 'training', user.userRole.includes('app-external-user') ? 'app-external-user': '')
        }
    }, [campaignId, getCampaignProgress, filterGroup.training, user])

    useEffect(() => {
        if(campaignProgress.engagements.updateStatus.status === 'success') {
            toast.success(campaignProgress.engagements.updateStatus.message)
            setUpdateModalStatus(null)
        } else if (campaignProgress.engagements.updateStatus.status === 'error') {
            toast.error(campaignProgress.engagements.updateStatus.message)
        }
        // Disabling esline in next lince since we do not need to include campaignId in the dependacy array, yet it is required.
        // eslint-disable-next-line
    }, [campaignProgress.engagements.updateStatus])

    useEffect(() => {
        if(campaignProgress.trainings.updateStatus.status === 'success') {
            toast.success(campaignProgress.trainings.updateStatus.message)
            setUpdateModalStatus(null)
        } else if (campaignProgress.trainings.updateStatus.status === 'error') {
            toast.error(campaignProgress.trainings.updateStatus.message)
        }
        // Disabling esline in next lince since we do not need to include campaignId in the dependacy array, yet it is required.
        // eslint-disable-next-line
    }, [campaignProgress.trainings.updateStatus])
    
    useEffect(() => {
        if(campaignProgress.deleteStatus === 'success') {
            toast.success('Progress deleted!')
            setProgressToDelete(null)
        } else if (campaignProgress.deleteStatus === 'error') {
            toast.error('Something went wrong!')
        }
    }, [campaignProgress.deleteStatus])

    const newProgress = (progressType) => {
        setUpdateModalStatus({
            campaignId,
            progressType,
            date: dayjs().format('YYYY-MM-DD'),
            progress: 0
        })
    }
    
    const editProgress = (progress) => {
        setUpdateModalStatus({
            ...progress,
            date: dayjs(progress.date).format('YYYY-MM-DD')
        })
    }

    const onProgresUpdateChange = e => {
        setUpdateModalStatus({
            ...updateModalStatus,
            [e.target.name]: e.target.value
        })
    }

    const saveProgress = () => {
        if (!updateModalStatus.date) {
            toast.error('Please choose a date')
        } else if (updateModalStatus.progress <= 0) {
            toast.error('Please choose a value grater than or equal to 1')
        } else {
            updateCampaignProgress(updateModalStatus, filterGroup[updateModalStatus.progressType])
        }
    }

    const onFilterGroupChange = (type, e) => {
        setFilterGroup({
            ...filterGroup,
            [type]: e.target.value
        })
    }

    const getEngagementCumilative = progressIndex => {
        return campaignProgress.engagements.data.reduce(
            (previousValue, currentValue, currentIndex) =>
                previousValue + (currentIndex >= progressIndex ? currentValue.progress : 0), 0)
    }

    const getTrainingCumilative = progressIndex => {
        return campaignProgress.trainings.data.reduce(
            (previousValue, currentValue, currentIndex) =>
                previousValue + (currentIndex >= progressIndex ? currentValue.progress : 0), 0)
    }
    const targetResident = campaign.targetResidents !== 0 ? campaign.targetResidents : campaign.targetEngagements.target
    return (

        <Container className='custom-container ps-0'>
            <div>
                <h5 className="fw-500 fs-3">{campaign.name}</h5>
            </div>
            <div className='border-top mt-2 mb-3'></div>

            <div className="row g-3 row-cols-1 row-cols-lg-3 w-75">
                <div className=' row-cols-2'>
                    <div className='d-inline-flex flex-column'>
                        <div className='text-title'>Country</div>
                        <div className='text-ans'>{campaign.country}</div>
                    </div>

                    <div className='d-inline-flex flex-column'>
                        <div className='text-title'>Start Date</div>
                        <div className='text-ans'>{campaign?.duration?.dateStart?.length > 1 ? getFormattedDate(campaign.duration.dateStart) : ''}</div>
                    </div>
                </div>
                <div >
                    <div className='text-title'>Target Residents</div>
                    {/* <div className='text-ans'>{campaign.targetEngagements.achieved.toString() + '/' + campaign.targetEngagements.target.toString()}</div> */}
                    <div className='text-ans'>{campaign.targetEngagements.achieved.toString() + '/' + targetResident.toString()}</div>
                    <div>
                        <ProgressBar className='progress-actual'>
                        {/* <ProgressBar className='tr-progress-now' now={campaign.targetEngagements.achieved} max={campaign.targetEngagements.target} /> */}
                            <ProgressBar className='tr-progress-now' now={campaign.targetEngagements.achieved} max={targetResident} />
                        </ProgressBar>
                    </div>
                </div>
                <div >
                    <div className='text-title'>Target Trainings</div>
                    <div className='text-ans'>{(campaign.targetTrainings.achieved?.toString() || 0) + '/' + (campaign.targetTrainings?.target.toString() || 0)}</div>
                    <div>
                        <ProgressBar className='progress-actual'>
                            <ProgressBar className='tt-progress-now' data-aos="slide-right" data-aos-duration="500" now={campaign.targetTrainings.achieved} max={campaign.targetTrainings.target} />
                        </ProgressBar>
                    </div>
                </div>
            </div>

            <div className='mt-5' id='engagement'>
                <div className='d-flex align-items-center justify-content-between'>
                    <h5 className='fw-bold'>Engagement Progress</h5>
                    <select className='border border-dark px-3 py-2 rounded-pill' onChange={e => onFilterGroupChange('engagement', e)} value={filterGroup.engagement}>
                        <option value='datePosted'>By date posted</option>
                        <option value='monthlyAggregate'>By month</option>
                    </select>
                </div>
                <div className='text-center my-5' hidden={campaignProgress.engagements.data.length > 0 || campaignProgress.engagements.fetchStatus === 'requesting'}>
                    No data found!
                </div>
                <div className='text-center my-5' hidden={campaignProgress.engagements.fetchStatus !== 'requesting'}>
                    <Loading height='100'/>
                </div>
                <div className='pb-4 mb-2 border-bottom mt-4' hidden={campaignProgress.engagements.data.length === 0 || campaignProgress.engagements.fetchStatus === 'requesting'}>
                    <table className='w-100 max-w-1000'>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th className='border-2 border-bottom-0 border-end border-start-0 border-top-0'>Completed</th>
                                <th className='ps-4'>Completed (Cumulative)</th>
                                <th>Remaining</th>
                                <th>Progress</th>
                                <th className='ps-2' hidden={filterGroup.engagement === 'monthlyAggregate' || (!user.userRole.includes('insights-super-admin') && !user.userRole.includes('insights-campaign'))}>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            { campaignProgress.engagements.data.map((progress, progressIndex) => {
                                const cumilativeProgress = getEngagementCumilative(progressIndex)
                                return <tr key={progress._id}>
                                    <td className='py-1'>{
                                        filterGroup.engagement === 'datePosted'
                                        ? dayjs(progress.date).format('DD MMM YYYY')
                                        : dayjs(progress._id+'-01', 'YYYY-MM-DD').format('MMM YYYY')
                                    }</td>

                                    <td className='border-2 border-bottom-0 border-end border-start-0 border-top-0'>{progress.progress}</td>
                                    <td className='ps-4'>{cumilativeProgress}</td>
                                    {/* <td>{campaign.targetEngagements.target-cumilativeProgress}</td> */}
                                    <td>{campaign.targetEngagement-cumilativeProgress}</td>
                                    <td>
                                        <ProgressBar className='rounded-pill border h-15px'>
                                        {/* <ProgressBar now={cumilativeProgress} max={campaign.targetEngagements.target} className='bg-cyan rounded-pill' /> */}
                                            <ProgressBar now={cumilativeProgress} max={targetResident} className='bg-cyan rounded-pill' />
                                        </ProgressBar>
                                    </td>
                                    <td>
                                        <span onClick={() => editProgress(progress)} hidden={filterGroup.engagement === 'monthlyAggregate' || (!user.userRole.includes('insights-super-admin') && !user.userRole.includes('insights-campaign'))} className='cursor-pointer ms-3'>
                                            <GoPencil />
                                        </span>
                                        <span onClick={() => setProgressToDelete({ progressId: progress._id, type: 'engagement' })} hidden={filterGroup.engagement === 'monthlyAggregate' || (!user.userRole.includes('insights-super-admin') && !user.userRole.includes('insights-campaign'))} className='cursor-pointer ms-2 text-danger'>
                                            <GoTrashcan />
                                        </span>
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
                <button onClick={() => newProgress('engagement')} hidden={!user.userRole.includes('insights-super-admin') && !user.userRole.includes('insights-campaign')} className='btn btn-primary btn-lg'>Update Progress</button>
            </div>

            <div className='mt-5' id='training'>
                <div className='d-flex align-items-center justify-content-between'>
                    <h5 className='fw-bold'>Training Progress</h5>
                    <select className='border border-dark px-3 py-2 rounded-pill' onChange={e => onFilterGroupChange('training', e)} value={filterGroup.training}>
                        <option value='datePosted'>By date posted</option>
                        <option value='monthlyAggregate'>By month</option>
                    </select>
                </div>
                <div className='text-center my-5' hidden={campaignProgress.trainings.data.length > 0 || campaignProgress.trainings.fetchStatus === 'requesting'}>
                    No data found!
                </div>
                <div className='text-center my-5' hidden={campaignProgress.trainings.fetchStatus !== 'requesting'}>
                    <Loading height='100'/>
                </div>
                <div className='pb-4 mb-2 border-bottom mt-4' hidden={campaignProgress.trainings.data.length === 0 || campaignProgress.trainings.fetchStatus === 'requesting'}>
                    <table className='w-100 max-w-1000'>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th className='border-2 border-bottom-0 border-end border-start-0 border-top-0'>Completed</th>
                                <th className='ps-4'>Completed (Cumulative)</th>
                                <th>Remaining</th>
                                <th>Progress</th>
                                <th className='ps-2' hidden={filterGroup.training === 'monthlyAggregate'|| (!user.userRole.includes('insights-super-admin') && !user.userRole.includes('insights-campaign'))}>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            { campaignProgress.trainings.data.map((progress, progressIndex) => {
                                const targetProgress = campaign.targetTrainings.target
                                const cumilativeProgress = getTrainingCumilative(progressIndex)
                                return <tr key={progress.date}>
                                    <td className='py-1'>{
                                        filterGroup.engagement === 'datePosted'
                                        ? dayjs(progress.date).format('DD MMM YYYY')
                                        : dayjs(progress._id+'-01', 'YYYY-MM-DD').format('MMM YYYY')
                                    }</td>

                                    <td className='border-2 border-bottom-0 border-end border-start-0 border-top-0'>{progress.progress}</td>
                                    <td className='ps-4'>{cumilativeProgress}</td>
                                    <td>{targetProgress-cumilativeProgress}</td>
                                    <td>
                                        <ProgressBar className='rounded-pill border h-15px'>
                                            <ProgressBar now={cumilativeProgress} max={targetProgress} className='bg-cyan rounded-pill' />
                                        </ProgressBar>
                                    </td>
                                    <td>
                                        <span onClick={() => editProgress(progress)} hidden={filterGroup.training === 'monthlyAggregate' || (!user.userRole.includes('insights-super-admin') && !user.userRole.includes('insights-campaign'))} className='cursor-pointer ms-3'>
                                            <GoPencil />
                                        </span>
                                        <span onClick={() => setProgressToDelete({ progressId: progress._id, type: 'training' })} hidden={filterGroup.training === 'monthlyAggregate' || (!user.userRole.includes('insights-super-admin') && !user.userRole.includes('insights-campaign'))} className='cursor-pointer ms-2 text-danger'>
                                            <GoTrashcan />
                                        </span>
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
                <button onClick={() => newProgress('training')} hidden={!user.userRole.includes('insights-super-admin') && !user.userRole.includes('insights-campaign')} className='btn btn-primary btn-lg'>Update Progress</button>
            </div>

            <Modal show={updateModalStatus !== null} onHide={() => setUpdateModalStatus(null)}>
                <Modal.Header closeButton>
                    <Modal.Title className='text-capitalize'>Update {updateModalStatus?.progressType} progress</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Date</Form.Label>
                        <Form.Control type='date' name='date' max={dayjs().format('YYYY-MM-DD')} readOnly={updateModalStatus?._id} onChange={onProgresUpdateChange} value={updateModalStatus?.date || dayjs().format('YYYY-MM-DD')}/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Progress</Form.Label>
                        <Form.Control type='number' min={0} name='progress' onClick={e => e.target.select()} onChange={onProgresUpdateChange} value={updateModalStatus?.progress ?? ''}/>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        onClick={saveProgress}
                        disabled={campaignProgress.trainings.updateStatus.status === 'requesting' || campaignProgress.engagements.updateStatus.status === 'requesting'}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={progressToDelete !== null} onHide={() => setProgressToDelete(null)}>
                <Modal.Header closeButton>
                    <Modal.Title className='text-capitalize'>Delete progress</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to dete this progress?
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setProgressToDelete(null)}
                        disabled={campaignProgress.deleteStatus === 'requesting'}>
                        Cancel
                    </Button>
                    <Button
                        variant="danger"
                        onClick={() => deleteProgress(progressToDelete)}
                        disabled={campaignProgress.deleteStatus === 'requesting'}>
                        Yes, Delete
                    </Button>
                </Modal.Footer>
            </Modal>

        </Container>
    );
}


UpdateCampaignProgress.propTypes = {
    campaign: PropTypes.object.isRequired,
    GetCampaignById: PropTypes.func.isRequired,
    getCampaignProgress: PropTypes.func.isRequired,
    updateCampaignProgress: PropTypes.func.isRequired,
    deleteProgress: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    campaign: state.campaigns.campaign,
    campaignProgress: state.campaigns.progress,
    user: state.auth.user
})

export default connect(mapStateToProps, { GetCampaignById, getCampaignProgress, updateCampaignProgress, deleteProgress })(UpdateCampaignProgress)