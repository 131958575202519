import { imageFileTypes } from "actions/util/static-data.util";
import React from "react";
import { IoChevronBackOutline, IoChevronForwardOutline } from "react-icons/io5";

const GalleryPreviewModal = ({ previewIndex, item, file, onNext, onPrevious, maxPosts }) => {

  const isImage = (item) => {
    if(item.isPreviewOption){
      if(item.isLiveUrl){
        return imageFileTypes.some(type => file.imageUrl.includes(`.${type}`))
      }
      return imageFileTypes.includes(file.name.split('.').pop())
    }
    return imageFileTypes.some(type => file.imageUrl.includes(`.${type}`))
  }

  return (
    <div className="d-flex align-items-center image-div position-relative">
      { isImage(item) ? 
        (item.isPreviewOption ?
          <img className="image-view"  src={item.isLiveUrl ? file.imageUrl : URL.createObjectURL(file)} alt='Attachment'/>
          :
          <img className="image-view" src={file.imageUrl} alt='Attachment'/>
        )
        :
        (item.isPreviewOption ?
          <video className="image-view" controls  src={item.isLiveUrl ? file.imageUrl : URL.createObjectURL(file)} type="video/mp4, video/quicktime, video/x-msvideo, video/*, .mkv"/>
          :
          <video className="image-view" controls src={file.imageUrl} type="video/mp4, video/quicktime, video/x-msvideo, video/*, .mkv"/>
        )
      }
      { previewIndex > 0 ? <div className="h-100 position-absolute top-0 start-0 px-3 d-flex align-items-center cursor-pointer navigation-left pointer-events-none">
        <div className="shadow d-inline-flex align-items-center justify-content-center rounded-circle slider-arrow-icons" onMouseDown={e => e.preventDefault()} onClick={onPrevious}>
          <IoChevronBackOutline fill="black" size={24} className="me-1"/>
        </div>
      </div> : <div/> }
      { previewIndex < maxPosts-1 ? <div className="h-100 position-absolute top-0 end-0 px-3 d-flex align-items-center cursor-pointer navigation-right pointer-events-none">
        <div className="shadow d-inline-flex align-items-center justify-content-center rounded-circle slider-arrow-icons" onMouseDown={e => e.preventDefault()} onClick={onNext}>  
          <IoChevronForwardOutline fill="black" size={24} className="ms-1"/>
        </div>
      </div> : <div/> }
    </div>
  );
};

export default GalleryPreviewModal;
