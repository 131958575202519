import { AUTH_CLIENT_HOST_URL, AUTH_SESSION_API_URL } from 'lib/centralizedAuthUrls';
import api from '../lib/api.lib'
import setAuthToken from '../lib/setAuthToken.lib'
import { LOCAL_LOGIN_STATUS, LOGIN_SUCCESS, LOGOUT, LOGOUT_STATE } from './util/types.action';
import { setCountryFilter } from './blogPost.action'
import { setIsFromCampaignLisitingScreen } from './util/campaignListing.action'
import { getProfilePicSignedUrl } from './getSignedUrls'

const login = (isAuthenticated, user) => ({
  type: LOGIN_SUCCESS,
  payload: user
})

export const startSession = (token, userId, appId, userRole, navigate = null) => async (dispatch, getState) => {
  let responseData = null;
  try {
    let res 
    if (userRole === 'app-external-user') {
      res = await api.post(`${process.env.REACT_APP_API}/session`, {
        token,
        userId,
        appId,
        region: 'IND'
      })
    } else {      
       res = await api.post(`${AUTH_SESSION_API_URL}/session`, {
        token,
        userId,
        appId,
      })
    }
    if (res.status === 200) {
      responseData = res.data.data
      const countryiso3 = responseData?.user?.country?.iso3
      const profilePicUrl = getState().signedUrl.profilePicSignedUrl
      responseData.user.profilePicUrl = profilePicUrl
      await dispatch(login(true, res.data.data))
      await dispatch(setCountryFilter({ selectedCountryFilter: countryiso3 ? countryiso3 === 'all' ? 'IND' : countryiso3.toUpperCase() : 'Select country',
      isCountryChanged: false }))

      if(typeof(navigate) === 'function'){
        if(responseData.user.userRole.includes('app-external-user')) {
          setIsFromCampaignLisitingScreen({isFromCampaignLisitingScreen: false})
          navigate('/dashboard')
        } else {
          navigate('/campaign')
        }
      }
    } else { 
      localStorage.removeItem('user')
      localStorage.removeItem('token')
    }
  } catch (error) {
  } finally {
    setAuthToken(responseData?.token, responseData?.user)
  }
} 

export const logout = (uid) => ({
  type: LOGOUT,
})

export const logoutState = (uid) => ({
  type: LOGOUT_STATE,
})

export const loginCA = (username, password, reCaptchaToken, navigate) => async (dispatch) => {
  dispatch({ type: LOCAL_LOGIN_STATUS, payload: 'requesting' })
  const body = { username, password, 'g-recaptcha-response': reCaptchaToken, appId: 'insights', region: 'IND' }
  try {
    const res = await api.post(`${process.env.REACT_APP_API}/auth/login`, body)
    if(res.status === 200) {
      if(res.data.data.user.userRole.includes('app-external-user')){
        await dispatch(startSession(res.data.data.token, res.data.data.user.id, 'insights', 'app-external-user', navigate))
        await dispatch(getProfilePicSignedUrl({region: res.data.data.user.country.iso3==='idn'?"IDN":"IND", imageName: res.data.data.user.profilePic, token: res.data.data.token, userRole: 'app-external-user' }))
        await dispatch(startSession(res.data.data.token, res.data.data.user.id, 'insights', 'app-external-user', navigate))
        dispatch({ type: LOCAL_LOGIN_STATUS, payload: 'success' })
      } else {
        await dispatch({ type: LOCAL_LOGIN_STATUS, payload: 'invalid_credential' })
        dispatch({ type: LOCAL_LOGIN_STATUS, payload: 'idle' })
      }
    }
  } catch (error) {
    if (error) {
      if (error.response) {
        if (error.response.status === 403) {
          await dispatch({ type: LOCAL_LOGIN_STATUS, payload: 'no_privillege' })
          dispatch({ type: LOCAL_LOGIN_STATUS, payload: 'idle' })
        } else if (error.response.status === 422){
          await dispatch({ type: LOCAL_LOGIN_STATUS, payload: 'invalid_captcha' })
          dispatch({ type: LOCAL_LOGIN_STATUS, payload: 'idle' })
        } else {
          await dispatch({ type: LOCAL_LOGIN_STATUS, payload: 'invalid_credential' })
          dispatch({ type: LOCAL_LOGIN_STATUS, payload: 'idle' })
        }
      } else {
        await dispatch({ type: LOCAL_LOGIN_STATUS, payload: 'invalid_credential' })
        dispatch({ type: LOCAL_LOGIN_STATUS, payload: 'idle' })
      }
    }
  }
}

export const startLogout = (user) => async (dispatch, getState) => {
  let redirectUrl = null
  if(!getState().auth?.user || getState().auth?.user?.userRole?.includes('app-external-user')){
    redirectUrl = '/'
    localStorage.setItem('skipSessionCheckOnce', true)
  } else {
    redirectUrl = AUTH_CLIENT_HOST_URL
  }

  if (user?.userRole?.includes('app-external-user')) {
    api.delete(`${process.env.REACT_APP_API}/session`, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
    }).finally(()=>{
      localStorage.removeItem('token')
      localStorage.removeItem('user')
      dispatch(logout())
      window.location.replace(redirectUrl)
    })
  } else {
    api.delete(`${AUTH_SESSION_API_URL}/session`, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
    }).finally(()=>{
      localStorage.removeItem('token')
      localStorage.removeItem('user')
      dispatch(logout())
      window.location.replace(redirectUrl)
    })
  }
}

export const authenticateSession = () => async (dispatch, getState) => {
  let redirectUrl = null
  if(getState().auth?.user){
    redirectUrl = '/'
  } else {
    redirectUrl = AUTH_CLIENT_HOST_URL
  }
  const session = await api.get(`${AUTH_SESSION_API_URL}/session`)
  if (session.status !== 200)
    window.location.replace(redirectUrl)
}