import React, { useState, useEffect } from "react";
import { Button, Container, Form, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getSubscriptionStatus, setSubscriptionStatus } from "../../actions/newsLetter.action";
import "../../res/styles/inquiry-page.styles.scss";
import { RiMailCheckLine } from "react-icons/ri";
import toast from "react-hot-toast";
import Loading from "components/util/Loading";
import useTitle from "hooks/useTitle";

const NewsLetterSubscription = ({ user, subscriptionStatus, subscriptionGetStatus, subscriptionPostStatus, getSubscriptionStatus, setSubscriptionStatus }) => {

  useTitle('News Letters')

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')

  useEffect(() => {
    getSubscriptionStatus()
  }, [getSubscriptionStatus, user]);

  useEffect(() => {
    if(user){
      if(subscriptionGetStatus.subscribed){
        setFirstName(subscriptionGetStatus.subscriber.firstName)
        setLastName(subscriptionGetStatus.subscriber.lastName)
        setEmail(subscriptionGetStatus.subscriber.email)
      } else {
        setFirstName(user.firstName)
        setLastName(user.lastName)
        setEmail(user.email)
      }
    }
  }, [setFirstName, setLastName, setEmail, user, subscriptionGetStatus])

  useEffect(() => {
    switch(subscriptionPostStatus){
      case 'success':
        toast.success('You have successfully subscribed to the news letter!')
        break
      case 'failed':
        toast.error('Something went wrong! Please try again')
        break
      default:
        break
    }
  }, [subscriptionPostStatus])

  const onSubmitForm = (e) => {
    e.preventDefault();
    if(subscriptionStatus.subscribed){
      setSubscriptionStatus({ subscribe: false });
    } else {
      setSubscriptionStatus({
        subscribe: true,
        subscriber: {
          firstName,
          lastName,
          email
        }
      })
    }
  };

  return (
    <Container className="inquiry-page">
      <div className="mt-5 container-small">
        {
          subscriptionGetStatus === 'requesting'
          ? <Loading height='100px'/>
          : <>
            {
              subscriptionStatus?.subscribed
              ? <h5 className="fw-bold mb-4">Subscription Active!</h5>
              : <h5 className="fw-bold mb-4">Subscribe to our newsletter!</h5> 
            }
            <div className="text-black fw-500">
              {
                subscriptionStatus?.subscribed
                ? <div>
                    <div className="">
                      <RiMailCheckLine size={40} className="text-success mb-3"/>
                    </div>
                    <small className="text-success">Your subscription to our newsletter is active.</small>
                  </div>
                : <small>Get the latest updates, news, views, case studies, and any insights from our campaigns directly to your inbox.</small>
              }
            </div>

            { subscriptionPostStatus === 'requesting' && <div className="alert alert-primary position-relative pe-5">
              <Spinner
                animation="border"
                variant="primary"
                size="sm"
                className="position-absolute end-0 me-3 mt-1"
              />
              Please wait...
            </div> }

            <Form onSubmit={onSubmitForm} hidden={subscriptionStatus?.subscribed}>

              <Form.Group className="mb-3">
                <Form.Label className="fs-sm fw-500">First Name</Form.Label>
                <Form.Control disabled={subscriptionGetStatus.subscribed} className="form-control rounded-3 fw-500 fs-sm" placeholder="Your first name" value={firstName} onChange={e => setFirstName(e.target.value)}/>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label className="fs-sm fw-500">Last Name</Form.Label>
                <Form.Control disabled={subscriptionGetStatus.subscribed} className="form-control rounded-3 fw-500 fs-sm" placeholder="Your last name" value={lastName} onChange={e => setLastName(e.target.value)}/>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label className="fs-sm fw-500">Email</Form.Label>
                <Form.Control disabled={subscriptionGetStatus.subscribed} className="form-control rounded-3 fw-500 fs-sm" placeholder="Your email address" value={email} onChange={e => setEmail(e.target.value)}/>
              </Form.Group>

              <div className="mt-4 border-top pt-4 d-grid">
                <Button
                  variant="primary"
                  type="submit"
                  size="lg"
                  disabled={!["", "failed"].includes(subscriptionPostStatus) || subscriptionGetStatus.subscribed}
                >
                  Subscribe
                </Button>
              </div>
            </Form>
          </>
        }
      </div>
    </Container>
  );
};

NewsLetterSubscription.propTypes = {
  getSubscriptionStatus: PropTypes.func.isRequired,
  setSubscriptionStatus: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  subscriptionStatus: state.newsLetter.subscriptionStatus,
  subscriptionGetStatus: state.newsLetter.subscriptionGetStatus,
  subscriptionPostStatus: state.newsLetter.subscriptionPostStatus
});

export default connect(mapStateToProps, { getSubscriptionStatus, setSubscriptionStatus })(
  NewsLetterSubscription
);
