import React from 'react'
import { Navbar } from 'react-bootstrap'

const ContentWrapper = ({ children, noMarginY = false }) => {
  return <div className={noMarginY ? '' : 'content-wrapper'}>
    {children}
    <Navbar className='footer border-top' fixed='bottom'>
      <div className='fw-600 text-center mx-auto py-1'>
        &copy; Copyright reach52 Pte. Ltd, {(new Date()).getFullYear()}. All rights reserved.
      </div>
    </Navbar>
  </div>
}

export default ContentWrapper