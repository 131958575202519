import React from "react";
import { MdOutlineHelpOutline } from "react-icons/md";
import ReactTooltip from "react-tooltip";

const HelpTooltipIcon = ({ className, tooltip, size = 20 }) => {
  return (
    <div className={className}>
      <MdOutlineHelpOutline className="ms-3 cursor-pointer" data-tip={tooltip} size={size}/>
      <ReactTooltip multiline={true} className="react-tooltip"/>
    </div>
  );
};

export default HelpTooltipIcon;
