import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { connect } from 'react-redux'
import ViewCampaignDetails from './components/myCampaign/ViewCampaignDetails';
import ViewCampaignsDetails from './components/myCampaign/ViewCampaignsDetails';
import Dashboard from './components/myCampaign/Dashboard';
import Gallery from './components/gallery/Gallery';
import ExpandCampaign from './components/expandCampaign/ExpandCampaign';
import CampaignForm from './components/myCampaign/CampaignForm';
import CampaignListing from './components/myCampaign/CampaignListing';
import PrivateRoute from './components/routes/PrivateRoute';
import Login from './components/login/Login';
import { useEffect } from "react";
import setAuthToken from "lib/setAuthToken.lib";
import Loading from "components/util/Loading";
import { logout } from "actions/auth.action";
import AOS from "aos";
import ViewNewsArticle from "components/newsAndViews/ViewNewsArticle";
import PhotoModal from "components/util/PhotoModal";
import Profile from "components/profile/Profile";
import "aos/dist/aos.css";
import ManageUsers from "components/partners/ManageUsers";
import Partners from "components/partners/ManageExternalPartners";
import CreatePartner from "components/partners/PartnerForm";
import Inquiry from "components/inquiry/Inquiry";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime"
import PublicOrPrivateRoute from "components/routes/PublicOrPrivateRoute";
import NewsLetterSubscription from "components/newsLetter/NewsLetterSubscription";
import UpdateCampaignProgress from "components/myCampaign/UpdateCampaignProgress";
import TermsAndConditions from "components/static/TermsAndConditions";
import PrivacyPolicy from "components/static/PrivacyPolicy";
import NewsNewsTab from "./components/newsAndViews/NewsNewsTab";
import NewsViewsTab from "components/newsAndViews/NewsViewsTab";
import NewsInsightsTab from "components/newsAndViews/NewsInsightsTab";
import ReactGA from "react-ga4";

dayjs.extend(relativeTime)

function App({
  loading: { message, isLoading, isLoadingImage},
  photoModal: {isShowPhotoModal, object, isPreviewOption},
  logout
}) {

  useEffect(() => {
    AOS.init();
    AOS.refresh();
    ReactGA.initialize(process.env.REACT_APP_GANALYTICS_TAG);
  }, []);

  useEffect(() => {
    if (localStorage.token && localStorage.user) {
      setAuthToken(localStorage.token, JSON.parse(localStorage.user))
    }

    // log user out from all tabs if they log out in one tab
    window.addEventListener('storage', () => {
      if (!localStorage.token || !localStorage.user) {
        logout()
        window.location.replace('/')
      }
    })
  }, [logout])

  return (
    <BrowserRouter>
      { isLoading && <Loading message = { message } isLoadingImage = {isLoadingImage} /> }
      { isShowPhotoModal && <PhotoModal isShowPhotoModal={isShowPhotoModal} object={object} isPreviewOption={isPreviewOption}></PhotoModal> }
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path='/dashboard' element={ <PrivateRoute component={Dashboard} /> }/>
        <Route path="/gallery" element={<PrivateRoute component={Gallery} />}/>

        <Route path="/news" element={<PrivateRoute component={NewsNewsTab} />} />
        <Route path="/views" element={<PrivateRoute component={NewsViewsTab} />} />
        <Route path="/insights" element={<PrivateRoute component={NewsInsightsTab} />} />

        <Route path="/news/:articleId" element={<PrivateRoute component={ViewNewsArticle} />} />
        <Route path="/views/:articleId" element={<PrivateRoute component={ViewNewsArticle} />} />
        <Route path="/insights/:articleId" element={<PrivateRoute component={ViewNewsArticle} />} />

        <Route path="/expand-campaign" element={<PublicOrPrivateRoute component={ExpandCampaign}/>} />
        <Route path="/create-campaign" element={<PrivateRoute component={CampaignForm} />} />
        <Route path="/campaign-details/:_id" element={<PrivateRoute component={ViewCampaignDetails} />}/>
        <Route path="/campaign-details" element={<PrivateRoute component={ViewCampaignsDetails} />}/>
        <Route path="/campaign-progress/:campaignId" element={<PrivateRoute component={UpdateCampaignProgress} />}/>
        <Route path="/campaign" element={<PrivateRoute component={CampaignListing} />} />
        <Route path="/profile" element={<PrivateRoute component={Profile} />} />
        <Route path="/partners" element={<PrivateRoute component={Partners} />} />
        <Route path="/create-partner" element={<PrivateRoute component={CreatePartner} />} />
        <Route path="/update-partner/:_id" element={<PrivateRoute component={CreatePartner} />} />
        <Route path="/manage-users" element={<PrivateRoute component={ManageUsers} />} />
        <Route path="/feedback" element={<PrivateRoute component={Inquiry} />} />
        <Route path="/news-letter" element={<PrivateRoute component={NewsLetterSubscription} />} />
        <Route path="/terms-and-conditions" element={<PublicOrPrivateRoute component={TermsAndConditions} />} />
        <Route path="/privacy-policy" element={<PublicOrPrivateRoute component={PrivacyPolicy} />} />
      </Routes>
    </BrowserRouter>
  )
}

const mapStateToProps = (state) => ({
  loading: state.loading,
  photoModal: state.photoModal,
  logout
})

export default connect(mapStateToProps, { logout })(App);
