
// Auth action constants
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGOUT_STATE = 'LOGOUT_STATE'
export const LOGOUT = 'LOGOUT'
export const LOCAL_LOGIN_STATUS = 'LOCAL_LOGIN_STATUS'

//News
export const GET_NEWS = 'GET_NEWS'
export const GET_NEWS_ARTICLE = 'GET_NEWS_ARTICLE'
export const CLEAR_NEWS_ARTICLES = 'CLEAR_NEWS_ARTICLES'
export const NEWS_LIST_IS_LOADING = 'NEWS_LIST_IS_LOADING'
export const NEWS_ITEM_IS_LOADING = 'NEWS_ITEM_IS_LOADING'

// Loding action constants
export const LOADING = 'LOADING'

//Get signed url action constants
export const GET_SIGNED_URLS = 'GET_SIGNED_URLS'
export const GET_PROFILE_PIC_SIGNED_URL = 'GET_PROFILE_PIC_SIGNED_URL'
export const GET_PROFILE_PIC_SIGNED_URLS = 'GET_PROFILE_PIC_SIGNED_URLS'

//blog post action constants
export const FILE_UPLOAD = 'FILE_UPLOAD'
export const FILE_UPLOAD_PROGRESS = 'FILE_UPLOAD_PROGRESS'
export const DATE_FILTER = 'DATE_FILTER'
export const CREATE_BLOG_POST = 'CREATE_BLOG_POST'
export const SHOW_PHOTO_MODAL = 'SHOW_PHOTO_MODAL'
export const BLOG_POST_LIST = 'BLOG_POST_LIST'
export const BLOG_POST_LIST_IS_LOADING = 'BLOG_POST_LIST_IS_LOADING'
export const DELETE_BLOG_POST = 'DELETE_BLOG_POST'
export const BLOG_POST_MODAL = 'BLOG_POST_MODAL'
export const BLOG_POST_COUNTRY_FILTER = 'BLOG_POST_COUNTRY_FILTER'
export const EDIT_BLOG_POST = 'EDIT_BLOG_POST'
export const SHOW_EDIT_MODAL = 'SHOW_EDIT_MODAL'

//partners
export const PARTNER_API_STATUS = 'PARTNER_API_STATUS'
export const GET_PARTNER_USER = 'GET_PARTNER_USER'
export const DELETE_PARTNER_USER = 'DELETE_PARTNER_USER'
export const USER_CREATED_SUCCESS = 'USER_CREATED_SUCCESS'
export const PROFILE_UPLOADED_SUCCESSFULLY = 'PROFILE_UPLOADED_SUCCESSFULLY'
export const GET_PARTNERS_LIST = 'GET_PARTNERS_LIST'
export const PARTNERS_LIST_FETCHING_STATUS = 'PARTNERS_LIST_FETCHING_STATUS'
export const AT_SET_ORGANIZATION_PARTNERTS = 'AT_SET_ORGANIZATION_PARTNERTS'

export const PARTNERS_SET_CAMPAIGN_MANAGERS_DATA = 'PARTNERS_SET_CAMPAIGN_MANAGERS_DATA'
export const PARTNERS_SET_PARTNERSHIP_MANAGERS_DATA = 'PARTNERS_SET_PARTNERSHIP_MANAGERS_DATA'

export const PARTNERS_CLEAR_CAMPAIGN_MANAGERS = 'PARTNERS_CLEAR_CAMPAIGN_MANAGERS'
export const PARTNERS_CLEAR_PARTNERSHIP_MANAGERS = 'PARTNERS_CLEAR_PARTNERSHIP_MANAGERS'

export const PARTNERS_SET_CAMPAIGN_FETCHING_STATUS = 'PARTNERS_SET_CAMPAIGN_FETCHING_STATUS'
export const PARTNERS_SET_PARTNERSHIP_FETCHING_STATUS = 'PARTNERS_SET_PARTNERSHIP_FETCHING_STATUS'

export const PARTNERS_SET_CAMPAIGN_MANAGER_DATA = 'PARTNERS_SET_CAMPAIGN_MANAGER_DATA'
export const PARTNERS_SET_PARTNERSHIP_MANAGER_DATA = 'PARTNERS_SET_PARTNERSHIP_MANAGER_DATA'

export const PARTNERS_SET_CAMPAIGN_MANAGER_FETCHING_STATUS = 'PARTNERS_SET_CAMPAIGN_MANAGER_FETCHING_STATUS'
export const PARTNERS_SET_PARTNERSHIP_MANAGER_FETCHING_STATUS = 'PARTNERS_SET_PARTNERSHIP_MANAGER_FETCHING_STATUS'

export const PARTNERS_SET_CAMPAIGN_MANAGER_SAVE_STATUS = 'PARTNERS_SET_CAMPAIGN_MANAGER_SAVE_STATUS'
export const PARTNERS_SET_PARTNERSHIP_MANAGER_SAVE_STATUS = 'PARTNERS_SET_PARTNERSHIP_MANAGER_SAVE_STATUS'

export const PARTNERS_APPEND_CAMPAIGN_MANAGER = 'PARTNERS_APPEND_CAMPAIGN_MANAGER'
export const PARTNERS_APPEND_PARTNERSHIP_MANAGER = 'PARTNERS_APPEND_PARTNERSHIP_MANAGER'

export const PARTNERS_REMOVE_CAMPAIGN_MANAGER = 'PARTNERS_REMOVE_CAMPAIGN_MANAGER'
export const PARTNERS_REMOVE_PARTNERSHIP_MANAGER = 'PARTNERS_REMOVE_PARTNERSHIP_MANAGER'

export const PARTNERS_UPDATE_CAMPAIGN_MANAGER = 'PARTNERS_UPDATE_CAMPAIGN_MANAGER'
export const PARTNERS_UPDATE_PARTNERSHIP_MANAGER = 'PARTNERS_UPDATE_PARTNERSHIP_MANAGER'

//Campaign action constants
export const CAMPAIGNS_DATA = 'CAMPAIGNS_DATA'
export const CAMPAIGNS_DATA_PAGINATED = 'CAMPAIGNS_DATA_PAGINATED'
export const CAMPAIGN = 'CAMPAIGN'
export const IS_FROM_CAMPAIGN_LISTING_SCREEN = 'IS_FROM_CAMPAIGN_LISTING_SCREEN'

export const CAMPAIGN_PROGRESS_TRAININGS_LIST = 'CAMPAIGN_PROGRESS_TRAININGS_LIST'
export const CAMPAIGN_PROGRESS_ENGAGEMENTS_LIST = 'CAMPAIGN_PROGRESS_ENGAGEMENTS_LIST'
export const CAMPAIGN_PROGRESS_TRAINING_FETCH_STATUS = 'CAMPAIGN_PROGRESS_TRAINING_FETCH_STATUS'
export const CAMPAIGN_PROGRESS_ENGAGEMENT_FETCH_STATUS = 'CAMPAIGN_PROGRESS_ENGAGEMENT_FETCH_STATUS'
export const CAMPAIGN_PROGRESS_TRAINING_UPDATE_STATUS = 'CAMPAIGN_PROGRESS_TRAINING_UPDATE_STATUS'
export const CAMPAIGN_PROGRESS_ENGAGEMENT_UPDATE_STATUS = 'CAMPAIGN_PROGRESS_ENGAGEMENT_UPDATE_STATUS'
export const CAMPAIGN_PROGRESS_ENGAGEMENT_GRAPH_STATUS = 'CAMPAIGN_PROGRESS_ENGAGEMENT_GRAPH_STATUS'
export const CAMPAIGN_PROGRESS_TRAINING_GRAPH_STATUS = 'CAMPAIGN_PROGRESS_TRAINING_GRAPH_STATUS'
export const CAMPAIGN_PROGRESS_ENGAGEMENT_GRAPH_DATA = 'CAMPAIGN_PROGRESS_ENGAGEMENT_GRAPH_DATA'
export const CAMPAIGN_PROGRESS_TRAINING_GRAPH_DATA = 'CAMPAIGN_PROGRESS_TRAINING_GRAPH_DATA'
export const CAMPAIGN_PROGRESS_DELETE_STATUS = 'CAMPAIGN_PROGRESS_DELETE_STATUS'
export const CAMPAIGN_PROGRESS_DELETE_ITEM = 'CAMPAIGN_PROGRESS_DELETE_ITEM'
export const ALL_CAMPAIGN_PROGRESS = 'ALL_CAMPAIGN_PROGRESS'
export const ALL_CAMPAIGN_PROGRESS_VIEW = 'ALL_CAMPAIGN_PROGRESS_VIEW'
export const CAMPAIGNS = 'CAMPAIGNS'
export const CAMPAIGN_FILTER = 'CAMPAIGN_FILTER'
export const CAMPAIGN_LISTING_IS_FETCHING = 'CAMPAIGN_LISTING_IS_FETCHING'

// Inquiry page
export const INQUIRY_STATUS = 'INQUIRY_STATUS'

// Expand campaign
export const EXP_CAMPAIGN_STATUS = 'EXP_CAMPAIGN_STATUS'

// Gallery
export const GALLERY_SET_DATA = 'GALLERY_SET_DATA'
export const GALLERY_IS_FETCHING = 'GALLERY_IS_FETCHING'
export const GALLERY_APPEND_SIGNED_URLS = 'GALLERY_APPEND_SIGNED_URLS'

// News letter subscription
export const NEWS_LETTER_SUBSCRIPTION_POST_STATUS = 'NEWS_LETTER_SUBSCRIPTION_POST_STATUS'
export const NEWS_LETTER_SUBSCRIPTION_GET_STATUS = 'NEWS_LETTER_SUBSCRIPTION_GET_STATUS'
export const NEWS_LETTER_SUBSCRIPTION_STATUS = 'NEWS_LETTER_SUBSCRIPTION_STATUS'