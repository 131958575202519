import {
  CREATE_BLOG_POST, BLOG_POST_LIST, DELETE_BLOG_POST, BLOG_POST_MODAL, DATE_FILTER,BLOG_POST_COUNTRY_FILTER, BLOG_POST_LIST_IS_LOADING, EDIT_BLOG_POST, SHOW_EDIT_MODAL
} from "actions/util/types.action"

const initialState = {
  createdBlogPost : [],
  blogPostListData: null,
  blogPostListIsLoading: false,
  isMonthFilterApplied: 'ALL',
  deletedBlogPostMessage: '',
  isShowBlogPostModal: false,
  selectedCountryFilter:  localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user')).country.iso3 === 'all' ? 'IND' 
    : JSON.parse(localStorage.getItem('user')).country.iso3?.toUpperCase()
    : '',
  isCountryChanged: false,
  showEditModal: null,
  isUpdatingBlogPost: false
}

const blogPostReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case CREATE_BLOG_POST:
      return {
        ...state,
        createdBlogPost: payload.data
      }
    case EDIT_BLOG_POST:
      if (payload.status) {
        if (payload.edited) {
          return {
            ...state,
            isUpdatingBlogPost: false,
            blogPostListData: state.blogPostListData.map(
              bpl => (
                bpl._id === payload.edited._id
                ? payload.edited
                : bpl
              )
            )
          }
        } else {
          return { ...state, isUpdatingBlogPost: false }
        }
      } else {
        return { ...state, isUpdatingBlogPost: true }
      }
    case SHOW_EDIT_MODAL:
      return {
        ...state,
        showEditModal: payload
      }
    case BLOG_POST_LIST:
      return {
        ...state,
        blogPostListData: payload?.data ? payload.data : payload
      }
    case BLOG_POST_LIST_IS_LOADING:
      return {
        ...state,
        blogPostListIsLoading: payload
      }
    case DATE_FILTER:
      return {
        ...state,
        isMonthFilterApplied: payload
      }
    case DELETE_BLOG_POST:
      return {
        ...state,
        deletedBlogPostMessage: payload.message
      }
    case BLOG_POST_MODAL:
      return {
        ...state,
        isShowBlogPostModal: payload.isShowBlogPostModal
      }
    case BLOG_POST_COUNTRY_FILTER:
      return {
        ...state,
        selectedCountryFilter: payload.selectedCountryFilter,
        isCountryChanged: payload.isCountryChanged
      }
    default:
      return state
  }
}

export default blogPostReducer