import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import GalleryItem from "./GalleryItem"
import { getPosts } from '../../actions/galleryPosts.action'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Loading from 'components/util/Loading'
import '../../res/styles/gallery.styles.scss'
import { GetCampaigns } from '../../actions/campaign.action'
import useTitle from 'hooks/useTitle'

const Gallery = ({ CampaignsData, user, GetCampaigns, campaignsSelected, posts, isFetching, getPosts }) => {

  useTitle('Gallery')

  const [page, setPage] = useState(1)

  useEffect(() => {
    if(page>posts.pagination.currentPage){
      if(campaignsSelected.length > 0) {
        getPosts(page, campaignsSelected)
      } else {
        if (user?.campaign?.length > 0){
          const campaignIds = user.campaign.map((obj) => obj.campaignId)
          GetCampaigns({ countryCode: user.country.iso3.toUpperCase(), campaignIds, userRole: user.userRole })
        }else{
          GetCampaigns({ countryCode: user.country.iso3.toUpperCase(), userRole: user.userRole })
        }
      }
    }
  }, [campaignsSelected, GetCampaigns, user, getPosts, page, posts.pagination.currentPage])

  useEffect(() => {
    if(CampaignsData.length > 0 && campaignsSelected.length === 0){
      getPosts(page, CampaignsData)
    }
  }, [CampaignsData, getPosts, page, campaignsSelected])
  
  return <Container>
    <div className="flex-column d-flex align-items-center padding-bottom-10">
      <div className="gallery-header justify-content-center d-flex align-items-center">
        <h5 className="fw-bold mb-0 me-3 d-inline-block">Gallery</h5>
      </div>
      <div className="cards">
        <div className="d-flex display-gap">
        {posts.dataProcessed.map((galleryItem, id) => (
          <GalleryItem
            item={galleryItem}
            key={`${galleryItem.post._id}-${id}`}
            itemId={id}
            />
        ))}
        </div>
      </div>
      { isFetching ? <Loading height='100px'/> : null }
      { posts.pagination.currentPage < posts.pagination.totalPages
        ? <div className='mt-3 text-center'>
          <button className='btn btn-primary btn-sm' onClick={() => setPage(page+1)}>Load More</button>
        </div>
        : null
      }
    </div>
  </Container>
}

Gallery.propTypes = {
  getPosts: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  posts: PropTypes.object.isRequired,
  GetCampaigns: PropTypes.func.isRequired,
  CampaignsData: PropTypes.array.isRequired,
  campaignsSelected: PropTypes.array.isRequired
}

const mapStateToProps = (state) => ({
  posts: state.galleryPosts.posts,
  user: state.auth.user,
  campaignsSelected: state.campaigns.campaignsSelected,
  isFetching: state.galleryPosts.isFetching,
  CampaignsData: state.campaigns.CampaignsData
})

export default connect(mapStateToProps , { getPosts, GetCampaigns })(Gallery)
