import { useState } from 'react'
import ReactGA from 'react-ga4'

const useTitle = (title = null) => {
  const [lastLocation, setLastLocation] = useState(null)

  const setTitle = (title) => {
    const newTitle = title ? `${title} - reach52 Insights` : 'reach52 Insights'
    document.title = newTitle
    if (title) {
      ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: document.title })
    }
  }

  if (title !== null) {
    if (window.location.pathname !== lastLocation) {
      setLastLocation(window.location.pathname)
      setTitle(title)
    }
  }

  return { setTitle }
}

export default useTitle