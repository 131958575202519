const env = process.env.REACT_APP_REACH52_CONNECT_APP_ENV

let CENTRALIZED_AUTH_CLIENT_HOST_URL
let CENTRALIZED_AUTH_SESSION_API_URL

switch (env) {
  case 'LIVE':
    CENTRALIZED_AUTH_SESSION_API_URL =
      'https://auth-manager-api.reach52.com'
    CENTRALIZED_AUTH_CLIENT_HOST_URL =
      'https://auth-manager.reach52.com/dashboard'
    break

  case 'DEMO':
    CENTRALIZED_AUTH_SESSION_API_URL =
      'https://demo-auth-api.reach52.com'
    CENTRALIZED_AUTH_CLIENT_HOST_URL =
      'https://demo-auth-sso.reach52.com/dashboard'
    break

  case 'UAT':
    CENTRALIZED_AUTH_SESSION_API_URL =
      'https://dev-ma-authapi.reach52.com'
    CENTRALIZED_AUTH_CLIENT_HOST_URL =
      'https://dev-auth-manager.reach52.com/dashboard'
    break
  case 'DEV':
    CENTRALIZED_AUTH_SESSION_API_URL = 'https://dev-ma-authapi.reach52.com'
    CENTRALIZED_AUTH_CLIENT_HOST_URL = 'https://dev-auth-manager.reach52.com/dashboard'
    break
  default:
    CENTRALIZED_AUTH_SESSION_API_URL = 'http://localhost:4005'
    CENTRALIZED_AUTH_CLIENT_HOST_URL = 'http://localhost:3000/dashboard'
    break
}

/*Centralized Auth urls*/

export const AUTH_CLIENT_HOST_URL = CENTRALIZED_AUTH_CLIENT_HOST_URL
export const AUTH_SESSION_API_URL = CENTRALIZED_AUTH_SESSION_API_URL
