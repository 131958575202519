import { FILE_UPLOAD, PROFILE_UPLOADED_SUCCESSFULLY, FILE_UPLOAD_PROGRESS } from "actions/util/types.action"

const initialState = {
  uploadedFilesData : [],
  uploadProgress: 0,
  profilePicture: ''
}

const fileUploadReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case FILE_UPLOAD:
      return {
        ...state,
        uploadedFilesData: payload.data,
      }
      case PROFILE_UPLOADED_SUCCESSFULLY:
        return {
          ...state,
          profilePicture: payload
        }
    case FILE_UPLOAD_PROGRESS:
      return {
        ...state,
        uploadProgress: payload
      }
    default:
      return state
  }
}

export default fileUploadReducer