const getFormattedDate = (dateTime) => {
    let a = [{ day: 'numeric' }, { month: 'short' }, { year: 'numeric' }];
    function format(m) {
        let f = new Intl.DateTimeFormat('en', m);
        return f.format(new Date(dateTime));
    }
    return a.map(format).join(' ');
}


const getUserCountryList = (countryCode) => {

    const countries = [
        { code: 'IND', name: 'India' },
        { code: 'PHL', name: 'Philippines' },
        { code: 'KHM', name: 'Cambodia' },
        { code: 'KEN', name: 'Kenya' },
        { code: 'ZAF', name: 'South Africa'}
    ]
    switch (countryCode) {
        case 'all':
            return countries
        case 'ind':
            return countries.filter((country) => country.code === 'IND')

        case 'phl':
            return countries.filter((country) => country.code === 'PHL')

        case 'khm':
            return countries.filter((country) => country.code === 'KHM')

        case 'ken':
            return countries.filter((country) => country.code === 'KEN')

        case 'zaf':
            return countries.filter((country) => country.code === 'ZAF')

        case 'idn':
            return [{ code: 'IDN', name: 'Indonesia' }]
        default:
            return []
    }

}

const getUserCountryName = (countryCode) => {

    return {
        all: 'India',
        IND: 'India',
        PHL: 'Philippines',
        KHM: 'Cambodia',
        KEN: 'Kenya',
        IDN: 'Indonesia',
        ZAF: 'South Africa'
    }[countryCode]

}

export {
    getFormattedDate,
    getUserCountryList,
    getUserCountryName
}