import React, { useEffect, useState } from 'react'
import { Button, Container } from 'react-bootstrap'
import PropTypes from 'prop-types'
import NewsListItem from './NewsListItem'
import '../../res/styles/news-and-views.styles.scss'
import SubscriptionPanel from './SubscriptionPanel';
import { getNews } from 'actions/news.action';
import { connect } from 'react-redux';
import Loading from 'components/util/Loading'
import useTitle from 'hooks/useTitle'

const NewsViewsTab = ({ getNews, news }) => {

  useTitle('Views')

  const [page, setPage] = useState(1)

  useEffect(() => {
    setPage(1);
  }, [])

  useEffect(() => {
    getNews('views', page)
  }, [page, getNews])

  const nextPage = () => {
    setPage(page+1)
  }
  
  return <Container className='news-and-views'>
    <div className='news-and-views-container mb-5'>
      <div className='row'>
        { news.articles.map( (article, articleId) => <NewsListItem uri='views' key={article.id} colspan={articleId ? 1 : 2} article={article}/>) }
      </div>

      { news.listIsLoading ? <div data-aos="zoom-in">
        <Loading height='auto' className='py-5'/>
      </div> : null }

      { page < news.paging.totalPages
        ? <div className='text-center mb-5'>
          <Button variant='light fw-500 border fs-sm' onClick={nextPage}>Load more</Button>
        </div> : null }
      <SubscriptionPanel />
    </div>
  </Container>
}

NewsViewsTab.propTypes = {
  news: PropTypes.any.isRequired
}

const mapStateToProps = (state) => ({
  news: state.news.categories.views
})

export default connect(mapStateToProps , { getNews })(NewsViewsTab)