import React, { useEffect, useState } from 'react'
import { useRef } from 'react'

const ExpandableText = ({ children, lines = 3, className = '', maxExpandedHeight = undefined }) => {
  const container = useRef()
  
  const [showExpander, setShowExpander] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)

  useEffect(() => {
    if(container.current?.scrollHeight - container.current?.getBoundingClientRect().height > 10){
      setShowExpander(true)
    } else {
      setShowExpander(false)
    }
  }, [container, children])

  useEffect(() => {
    setIsExpanded(false)
  }, [children])
  

  return (<div>
    <div className={`${className} ` + (isExpanded ? '' : `line-clamp-${lines}`)} style={{ whiteSpace: "pre-line", maxHeight: isExpanded ? `${maxExpandedHeight}px` : undefined, overflowY: isExpanded ? 'auto' : undefined }} ref={container}>
      {children}
    </div>
    { showExpander
      ? <div className='text-end mt-1'>
          <span className='cursor-pointer text-muted' onClick={() => setIsExpanded(!isExpanded)}>...See {isExpanded ? 'less': 'more'}</span>
        </div>
      : null
    }
  </div>)
}

export default ExpandableText