import React, { useEffect, useState } from 'react'
import { Button, Container, Form } from 'react-bootstrap'
import "../../res/styles/campaign-form.styles.scss";
import { getOrganiztionPartners, createUser, getUser, updateUser } from '../../actions/managePartners.action'
import PropTypes from 'prop-types'
import { BsExclamationTriangle } from 'react-icons/bs';
import { connect } from 'react-redux'
import { GetCampaigns } from '../../actions/campaign.action'
import { MultiSelect } from "react-multi-select-component"
import { useMatch, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-hot-toast'
import { getAvailableCountries } from 'actions/util/static-data.util';
import useTitle from 'hooks/useTitle';


const PartnerForm = ({
  createUser,
  updateUser,
  getUser,
  getOrganiztionPartners,
  GetCampaigns,
  user,
  campaignsData,
  selectedPartner,
  partnerAPIStatus,
  organizationPartners
}) => {
  const { _id } = useParams()
  const navigate = useNavigate()
  const createPartner = useMatch('/create-partner')
  const countries = getAvailableCountries(user, true).map(c => ({ iso3: c.value.toLowerCase(), name: c.label }))
  const initialState = {
    firstname: '',
    lastname: '',
    username: '',
    password: '',
    email: '',
    contactNo: '',
    associate: [],
    country: '',
    isCampaignRoleRead: 'true',
    organization: [],
    permission: ['insights'],
    userRole: ['app-external-user'],
    campaign: [],
    profilePic: []
  }
  const [selectedOrganization, setSelectedOrganization] = useState([])
  const [selectedCampaigns, setSelectedCampaigns] = useState([])
  const [formValues, setFormValues] = useState(initialState)

  useTitle((createPartner ? 'Create' : 'Update') + ' Partner')

  useEffect(() => {
    if (selectedPartner._id === '' && _id) {
      getUser({ user: user, _id: _id, isNavigateRequired: false })
    }
    if (selectedPartner && _id) {
      const userProfile = {
        firstname: '',
        lastname: '',
        username: '',
        password: '',
        email: '',
        contactNo: '',
        associate: [],
        country: '',
        isCampaignRoleRead: 'true',
        organization: [],
        permission: ['insights'],
        userRole: ['app-external-user'],
        campaign: [],
        profilePic: []
      }

      for (const key in selectedPartner) {
        if (key in userProfile) {
          if (key === 'profilePic') {
            userProfile[key].push(selectedPartner[key])
          } else {
            userProfile[key] = selectedPartner[key]
          }
        }
      }
      for (const key in selectedPartner) {
        if (key in userProfile && (key !== 'organization' || key !== 'campaign')) {
          userProfile[key] = selectedPartner[key]
        }
      }
      userProfile.country = selectedPartner['country'].iso3
      getOrganiztionPartners({ organizationType: 'EXP', country: selectedPartner['country'].iso3.toUpperCase() })
      const organizationId = []
      selectedPartner.organization.map(
        (obj) => {
          organizationId.push(obj.code)
          return obj
        }
      )
      GetCampaigns({ countryCode: selectedPartner['country'].iso3.toUpperCase(), organizationPartnerId: organizationId, userRole: '' })
      if (selectedPartner) {
        if (selectedPartner.organization.length > 0) {
          setSelectedOrganization(selectedPartner.organization.map(
            (obj) => {
              return {
                label: obj.name,
                value: obj.code
              }
            }
          ))
        }
        if (selectedPartner.campaign.length > 0) {
          setSelectedCampaigns(selectedPartner.campaign.map(
            (obj) => {
              return {
                label: obj.name,
                value: obj.campaignId
              }
            }
          ))
        }
      }
      setFormValues(userProfile)
    } else {
      getOrganiztionPartners({ organizationType: 'EXP', country: user.country.iso3.toUpperCase() })
      GetCampaigns({ countryCode: user.country.iso3.toUpperCase(), userRole: '' })
    }
  }, [getOrganiztionPartners, getUser, GetCampaigns, user, selectedPartner, _id])

  const handleControlChange = (e) => {
    if (e.target.name === 'country') {
      let orgIds = []
      if(selectedOrganization) {
        for (const org of selectedOrganization) {
          orgIds.push(org.value)
        }
      }
      GetCampaigns({ countryCode: e.target.value.toUpperCase(), organizationPartnerId: orgIds, userRole: '' })
      getOrganiztionPartners({ organizationType: 'EXP', country: e.target.value.toUpperCase() })
      setSelectedOrganization([])
      setSelectedCampaigns([])
    }
    if (formValues !== null) {
      setFormValues({
        ...formValues,
        [e.target.name]: e.target.value
      })
    }
  }

  const onOrganizationSelected = (e) => {
    setSelectedOrganization(e)
    const ids = []
    for (const obj of e) {
      ids.push(obj.value)
    }
    setSelectedCampaigns([])
    GetCampaigns({ countryCode: formValues.country ? formValues.country.toUpperCase() : user.country.iso3.toUpperCase(), organizationPartnerId: ids, userRole: '' })
  }

  const onFileSelected = (e) => {
    const name = 'profilePic'
    if (e.target.files && e.target.files.length > 0) {
      setFormValues({
        ...formValues,
        [name]: e.target.files
      })
    }
  }

  const {
    profilePic
  } = formValues

  const validateMultiSelectDropdowns = () => {
    if (selectedOrganization.length === 0) {
      toast.error('Please assign organisation to the user!')
      return false
    }
    else if (selectedCampaigns.length === 0) {
      toast.error('Please assign campaign to the user!')
      return false
    }
    return true
  }

  const onSubmit = (e) => {
    e.preventDefault()
    if (validateMultiSelectDropdowns()) {
      let countryObject = countries.find(
        (ctr) => ctr.iso3 === formValues.country
      )
      let userProfile = {
        ...formValues,
        country: countryObject,
      }
      let organizationData = []
      let campaignData = []
      organizationData = organizationPartners.filter((obj) =>
        selectedOrganization.map((org) => org.value).includes(obj._id)
      )
      organizationData = organizationData.map((obj) => {
        return {
          code: obj._id,
          name: obj.name
        }
      })
      campaignsData.forEach((obj) => {

        if (selectedCampaigns.map((cam) => cam.value).includes(obj.campaignId)) {
          campaignData.push({
            campaignId: obj.campaignId,
            name: obj.name
          })
        }
      }

      )
      userProfile.userRole = ['app-external-user']
      userProfile.profilePic = profilePic[0]
      userProfile.organization = organizationData
      userProfile.campaign = campaignData
      createPartner ? createUser(user, userProfile, navigate) :
        updateUser(user, userProfile, _id, navigate, '/partners')
    }
  }

  return <Container className='campaign-form'>
    <div className="d-flex flex-column gap-3">
      {(() => {
        switch (partnerAPIStatus) {
          case 'duplicate-username':
            return <div className='alert alert-danger fs-sm position-fixed d-flex align-self-center pe-5'>
              <BsExclamationTriangle size={22} className='position-absolute fs-sm end-0 me-3' />
              User name is already in use.
            </div>
          case 'duplicate-email':
            return <div className='alert alert-danger fs-sm position-fixed d-flex align-self-center pe-5'>
              <BsExclamationTriangle size={22} className='position-absolute fs-sm end-0 me-3' />
              An account under this email has been added previously, please check for the account on the Partners dashboard.
            </div>
          default:
            return <></>
        }
      })()}
      <div className="d-flex flex-column pb-2 pt-1">
        <h1 className='fw-bold form-title'>{createPartner ? 'Add New Partner' : 'Update Partner'}</h1>
        {createPartner ? <div className="fw-500 fs-md">Add a partner account to access selected campaigns' progress based on their organization and country affliations. Please check that a duplicate account is not being added.</div> : ''}
      </div>
      <Form onSubmit={onSubmit}>
        <div className='row'>
          <div className="col-12 col-md-4 mb-4">
            <Form.Group>
              <Form.Label className='fs-sm fw-600'>First Name <span className='asterisk'>*</span></Form.Label>
              <Form.Control minLength={2} required disabled={!createPartner} type='text' maxLength={128} value={formValues?.firstname} onChange={handleControlChange} name='firstname' className='fs-sm fw-600 py-2' />
            </Form.Group>
          </div>
          <div className="col-12 col-md-4 mb-4">
            <Form.Group>
              <Form.Label className='fs-sm fw-600'>Last Name <span className='asterisk'>*</span></Form.Label>
              <Form.Control minLength={2} required type='text' disabled={!createPartner} maxLength={128} value={formValues?.lastname} onChange={handleControlChange} name='lastname' className='fs-sm fw-600 py-2' />
            </Form.Group>
          </div>
          <div className="col-12 col-md-4 mb-4">
            <Form.Group>
              <Form.Label className='fs-sm fw-600'>User Name <span className='asterisk'>*</span></Form.Label>
              <Form.Control minLength={2} required type='text' disabled={!createPartner} maxLength={32} value={formValues?.username} onChange={handleControlChange} name='username' className='fs-sm fw-600 py-2' />
            </Form.Group>
          </div>
        </div>
        <div className='row'>
          <div className="col-12 col-md-4 mb-4">
            <Form.Group>
              <Form.Label className='fs-sm fw-600'>Email <span className='asterisk'>*</span></Form.Label>
              <Form.Control required type='email' disabled={!createPartner} maxLength={128} value={formValues?.email} onChange={handleControlChange} name='email' className='fs-sm fw-600 py-2' />
            </Form.Group>
          </div>
          <div className="col-12 col-md-4 mb-4">
            <Form.Group>
              <Form.Label className='fs-sm fw-600'>Contact No. <span className='asterisk'>*</span></Form.Label>
              <Form.Control minLength={7} required type='number' maxLength={16} value={formValues?.contactNo} onChange={handleControlChange} name='contactNo' className='fs-sm fw-600 py-2' />
            </Form.Group>
          </div>
          <div className="col-12 col-md-4 mb-4">
            <Form.Group>
              <Form.Label className='fs-sm fw-600'>Country <span className='asterisk'>*</span></Form.Label>
              <Form.Select onChange={handleControlChange} value={formValues?.country} name='country' className='fs-sm fw-600 py-2' required>
                <option disabled value=''>Select...</option>
                {countries.map(country => (<option value={country.iso3} key={country.iso3}>{country.name}</option>))}
              </Form.Select>
            </Form.Group>
          </div>
        </div>
        <div className='row'>
          {createPartner ? (
            <div className="col-12 col-md-4 mb-4">
              <Form.Group>
                <Form.Label className='fs-sm fw-600'>Password <span className='asterisk'>*</span></Form.Label>
                <Form.Control type='password' minLength={8} maxLength={128} value={formValues?.password} onChange={handleControlChange} name='password' className='fs-sm fw-600 py-2' />
              </Form.Group>
            </div>) : ''}

          <div className="col-12 col-md-4 mb-4">
            <Form.Group>
              <Form.Label className='fs-sm fw-600'>Organisation <span className='asterisk'>*</span></Form.Label>
              <MultiSelect
                hasSelectAll={false}
                className='react-multiselect'
                options={organizationPartners.map((obj) => {
                  return {
                    label: obj.name,
                    value: obj._id
                  }
                })}
                value={selectedOrganization}
                onChange={onOrganizationSelected}
                labelledBy="Select Organisation"
              />
            </Form.Group>
          </div>
          <div className="col-12 col-md-4 mb-4">
            <Form.Group>
              <Form.Label className='fs-sm fw-600'>Campaign <span className='asterisk'>*</span></Form.Label>
              <MultiSelect
                hasSelectAll={false}
                className='react-multiselect'
                options={campaignsData.map((obj) => {
                  return {
                    label: obj.name,
                    value: obj.campaignId
                  }

                })}
                value={selectedCampaigns}
                onChange={setSelectedCampaigns}
                labelledBy="Select Campaign"
              />
            </Form.Group>
          </div>
        </div>
        <div className='row'>
          <div className="col-12 col-md-4 mb-4">
            <Form.Group>
              <Form.Label className='fs-sm fw-600'>Profile Picture <span className='asterisk'>*</span></Form.Label>
              <Form.Control required={createPartner} accept="image/*" type='file' onChange={onFileSelected} name='profilePic' className='fs-sm fw-600 py-2' />
            </Form.Group>
          </div>
        </div>
        <div className='d-flex pt-2'>
          <Button variant="primary" className="submit-button" type="submit">Save</Button>
        </div>
      </Form>
    </div>
  </Container>
}

PartnerForm.propTypes = {
  partnerAPIStatus: PropTypes.string.isRequired,
  selectedPartner: PropTypes.object.isRequired,
  getUser: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  createUser: PropTypes.func.isRequired,
  GetCampaigns: PropTypes.func.isRequired,
  getOrganiztionPartners: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  campaignsData: state.campaigns.CampaignsData,
  user: state.auth.user,
  organizationPartners: state.managePartners.organizationPartners,
  selectedPartner: state.managePartners.selectedPartner,
  partnerAPIStatus: state.managePartners.partnerAPIStatus
})

export default connect(mapStateToProps, {
  getOrganiztionPartners, GetCampaigns, createUser, getUser, updateUser
})(PartnerForm)