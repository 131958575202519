import React, { useEffect, useState } from 'react'
import { Container, Table, Button, Modal } from 'react-bootstrap'
import "../../res/styles/manage-partners.styles.scss";
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { loadExternalUsers, getUser, deleteUser } from '../../actions/managePartners.action'
import { Dropdown } from 'react-bootstrap'
import { BsThreeDots } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import HelpTooltipIcon from 'components/common/HelpTooltipIcon';
import Pagination from 'components/util/Pagination';
import Loading from 'components/util/Loading';
import useTitle from 'hooks/useTitle';

const ManagePartners = ({
  partnersData,
  DataCount,
  loadExternalUsers,
  getUser,
  deleteUser,
  user,
  isFetching
}) => {

  useTitle('External Partners')

  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 5;

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(null)

  useEffect(() => {
    loadExternalUsers({ pageIndex: currentPage - 1, pageSize: ITEMS_PER_PAGE, user: user })
  }, [loadExternalUsers, user, currentPage, ITEMS_PER_PAGE])

  const navigateToUpdateForm = (data) => {
    getUser({ user: user, _id: data._id, navigate: navigate, isNavigateRequired: true })
  }

  const isCampaignAdminWrite = () => {
    const associate = user.associate.find((val)=> val?.type === 'campaign' && val?.role === 'read/write')
    return (associate?.role === 'read/write' && user.userRole.includes('insights-campaign'))
  }

  const deleteExternalPartner = () => {
    deleteUser({ user: user, obj: showDeleteConfirmation })
    setShowDeleteConfirmation(null)
  }

  return <Container className='campaign-listing'>
    <div className='d-flex flex-column h-100 gap-4'>
      <div className='d-flex justify-content-between flex-row'>
        <div className="d-inline-flex align-items-center">
          <h2 className='fw-bold title'>Partner Database</h2>
          <HelpTooltipIcon tooltip="This page shows all partners who have an account on the reach52 Connect platform. You can toggle access to campaigns and by country to each user."/>
        </div>
        <div className='d-flex'>
          { (user.userRole.includes('insights-super-admin') || isCampaignAdminWrite()) &&
          <Link className='custom-link' to='/create-partner'>
            <Button variant="primary" className="submit-button" type="submit" >Add Partner</Button>
          </Link> }
        </div>
      </div>
      <div className='bg-white border rounded-3 shadow-sm mt-4 overflow-hidden mb-5 position-relative'>
        { isFetching && <Loading height='350px'/> }
        { !isFetching && <Table responsive='xl' borderless className='campaign-table mb-0'>
          <thead>
            <tr className='border-bottom'>
              <th className='p-3'>Name</th>
              <th className='p-3'>Contact No.</th>
              <th className='p-3'>Email</th>
              <th className='p-3'>Organisation</th>
              <th className='p-3'>Campaign</th>
              <th className='p-3'>Country</th>
              {/* <th className='p-3'>Password</th> */}
              <th className='p-3'></th>
            </tr>
          </thead>
          <tbody>
          {
            partnersData.map((Obj, id) => {
              return (
                <tr key={"row-" + id.toString()}>
                  <td className='px-3 pt-3 pb-2 text-capitalize fw-400'>{Obj.fullname}</td>
                  <td className='px-3 pt-3 pb-2 text-capitalize fw-400'>{Obj.contactNo}</td>
                  <td className='px-3 pt-3 pb-2 text-capitalize fw-400'>{Obj?.email}</td>
                  <td className='px-3 pt-3 pb-2 text-capitalize fw-400'>{Obj?.organization ? Obj.organization.map((obj) => obj.name).join(', ') : '-'}</td>
                  <td className='px-3 pt-3 pb-2 text-capitalize fw-400'>
                    <span >{Obj?.campaign ? Obj.campaign.map((obj) => obj.name).join(', ') : '-'}</span>
                  </td>
                  <td className='px-3 pt-3 pb-2 text-capitalize fw-400'>{Obj.country.name}</td>
                  {/* <td className='px-3 pt-3 pb-2' >
                 <Button className='btn-password' type="submit" >Generate</Button>
                  </td> */}
                  <td className='px-3 pt-3 pb-2'>
                  { (user.userRole.includes('insights-super-admin') || isCampaignAdminWrite()) &&
                    <Dropdown>
                      <Dropdown.Toggle as={'span'} className='no-caret'>
                        <BsThreeDots className='cursor-pointer' size={22} />
                      </Dropdown.Toggle>
                      <Dropdown.Menu align="end">
                        <Dropdown.Item className='py-1 fs-sm' onClick={() => { navigateToUpdateForm(Obj) }}>Update</Dropdown.Item>
                        <Dropdown.Item className='py-1 fs-sm' onClick={() => { setShowDeleteConfirmation(Obj) }}>Delete</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown> }
                  </td>
                </tr>
              )
            })
          }
          </tbody>
        </Table> }
        <Pagination disabled={false} totalPages={Math.ceil(DataCount/ITEMS_PER_PAGE)} currentPage={currentPage} setPage={setCurrentPage} />
      </div>
    </div>

    <Modal show={showDeleteConfirmation !== null} onHide={() => {setShowDeleteConfirmation(null)}}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Partner</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to delete <strong>{showDeleteConfirmation?.fullname}</strong> from the list of external users?</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => {setShowDeleteConfirmation(null)}}>
          Close
        </Button>
        <Button variant="danger" onClick={deleteExternalPartner}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>

  </Container>
}

ManagePartners.propTypes = {
  getUser: PropTypes.func.isRequired,
  loadExternalUsers: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  partnersData: PropTypes.array.isRequired,
  DataCount: PropTypes.number.isRequired
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  DataCount: state.managePartners.DataCount,
  partnersData: state.managePartners.partnersData,
  isFetching: state.managePartners.partnersListIsFetching
})

export default connect(mapStateToProps, { loadExternalUsers, getUser, deleteUser })(ManagePartners)