import React, { useCallback, useEffect, useState } from "react"
import { Button, Container, Dropdown, Table } from "react-bootstrap"
import { connect } from "react-redux"
import CampaignManagerModal from "./CampaignManagerModal"
import PartnershipManagerModal from "./PartnershipManagerModal"
import { getPartners, deletePartner, savePartner } from '../../actions/partnerEmail.action'
import { GetCampaigns } from '../../actions/campaign.action'
import { countries } from "actions/util/static-data.util"
import { BsThreeDots } from "react-icons/bs"
import Pagination from "components/util/Pagination"
import toast from "react-hot-toast"
import Loading from "components/util/Loading"
import HelpTooltipIcon from "components/common/HelpTooltipIcon"
import useTitle from "hooks/useTitle"

const ManageUsers = ({ user, campaign, partnership, campaignSaveStatus, partnerSaveStatus, getPartners, deletePartner, GetCampaigns }) => {

  useTitle('Manage Users')

  const [page, setPage] = useState({
    campaign: 1,
    partnership: 1
  })

  const [campaignManagerModal, setCampaignManagerModal] = useState({
    show: false,
    manager: null
  })

  const [partnershipManagerModal, setPartnershipManagerModal] = useState({
    show: false,
    manager: null
  })

  useEffect(() => {
    getPartners('campaign', page.campaign)
  }, [getPartners, page.campaign])

  useEffect(() => {
    getPartners('partnership', page.partnership)
  }, [getPartners, page.partnership])

  useEffect(() => {
    if(campaignSaveStatus === 'deleted' || partnerSaveStatus === 'deleted'){
      toast.success('Partner Deleted')
    }
  }, [campaignSaveStatus, partnerSaveStatus])
  
  useEffect(() => {
    GetCampaigns({ countryCode: user.country.iso3.toUpperCase(), userRole: '' })
  }, [GetCampaigns, user])

  const newCampaignManager = () => {
    setCampaignManagerModal({ manager: null, show: true })
  }

  const newPartnershipManager = () => {
    setPartnershipManagerModal({ manager: null, show: true })
  }

  const editCampaignManager = (manager) => {
    setCampaignManagerModal({ manager, show: true })
  }

  const editPartnershipManager = (manager) => {
    setPartnershipManagerModal({ manager, show: true })
  }

  const deleteCampaignManager = (manager) => {
    deletePartner('campaign', manager._id)
  }

  const deletePartnershipManager = (manager) => {
    deletePartner('partnership', manager._id)
  }

  const onHideCampaignManagerModal = useCallback(() => {
    setCampaignManagerModal({ manager: null, show: false })
  }, [])

  const onHidePartnershipManagerModal = useCallback(() => {
    setPartnershipManagerModal({ manager: null, show: false })
  }, [])

  const setCampaignPage = (pn) => {
    setPage({ ...page, campaign: pn })
  }

  const setPartnershipPage = (pn) => {
    setPage({ ...page, partnership: pn })
  }

  const isCampaignAdminWrite = () => {
    const associate = user.associate.find((val)=> val?.type === 'campaign' && val?.role === 'read/write')
    return (associate?.role === 'read/write' && user.userRole.includes('insights-campaign'))
  }

  return (<>
    <Container className="partners-page">
      <div className="mt-5 container-small">
        <div className="d-inline-flex">
          <h3 className="fw-bold mb-4">Manage Users</h3>
          <HelpTooltipIcon className="mt-1" tooltip="This page shows Campaign Managers who are responsible for posting monthly updates on their assigned campaigns on reach52 Connect and Partnership Managers who receive inquiries regarding expanding campaigns."/>
        </div>

        <div className="d-flex justify-content-between align-items-center">
          <div className="d-inline-flex align-items-center">
            <h5 className="fw-bold mb-0">Campaign Manager</h5>
            <HelpTooltipIcon tooltip="Select who receives new incoming customer campaign requests"/>
          </div>
          { (user.userRole.includes('insights-super-admin') || isCampaignAdminWrite()) &&
          <Button variant="primary" onClick={newCampaignManager}>Add Manager</Button> }
        </div>
        <div className='bg-white border rounded-3 shadow-sm mt-4 overflow-hidden mb-5 position-relative'>
          { campaign.pagination.totalRecords > 0
            ? <>
              <Table responsive="xl" borderless className="mb-0">
                <thead>
                  <tr className="border-bottom">
                    <th className="p-3">Name</th>
                    <th className="p-3">Email</th>
                    <th className="p-3">Campaign</th>
                    <th className="p-3">Country</th>
                    <th className="p-3"></th>
                  </tr>
                </thead>
                <tbody>
                  { campaign.data.map(c => (
                    <tr key={c._id}>
                      <td className="px-3 pt-3 pb-2">
                        <span className="fw-600">{ c.partnerName }</span>
                      </td>
                      <td className="px-3 pt-3 pb-2">{ c.email }</td>
                      <td className="px-3 pt-3 pb-2">
                        { c.receiveAll ? <span className="badge bg-success">Receive All</span> : <>
                          <div>{ c.campaign?.name }</div>
                          <small className='text-muted'>{ c.campaign?.id }</small>
                        </> }
                      </td>
                      <td className="px-3 pt-3 pb-2">
                        { c.receiveAll ? <span className="badge bg-success">Receive All</span> : c.countries.map(c => countries.find(cc => cc.value === c).label).join(', ') }
                      </td>
                      <td className="px-3 pt-3 pb-2 text-end">
                      { (user.userRole.includes('insights-super-admin') || isCampaignAdminWrite()) &&
                      <Dropdown>
                        <Dropdown.Toggle as={'span'} className='no-caret'>
                          <BsThreeDots className='cursor-pointer' size={20}/>
                        </Dropdown.Toggle>
                        <Dropdown.Menu align="end">
                          <Dropdown.Item onClick={() => {editCampaignManager(c)}}>Edit</Dropdown.Item>
                          <Dropdown.Item onClick={() => {deleteCampaignManager(c)}}>Delete</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown> }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Pagination disabled={campaign.isFetching} totalPages={campaign.pagination.totalPages} currentPage={page.campaign} setPage={setCampaignPage} />
            </>
            : <div className="my-5 text-center">
              { campaign.isFetching ? <>&nbsp;</> : 'No records found...' }
            </div>
          }
          { campaign.isFetching
            ? <div className="bg-white top-0 start-0 position-absolute w-100 h-100 bg-opacity-50 d-flex align-items-center justify-content-center">
              <Loading height="150px" className="mt-5"/>
            </div>
            : <></>
          }
        </div>

        <div className="d-flex justify-content-between align-items-center">
          <div className="d-inline-flex align-items-center">
            <h5 className="fw-bold mb-0">Partnerships Manager</h5>
            <HelpTooltipIcon tooltip="Select who receives new incoming campaign expansion requests"/>
          </div>
          { (user.userRole.includes('insights-super-admin') || isCampaignAdminWrite()) &&
          <Button variant="primary" onClick={newPartnershipManager}>Add Manager</Button> }
        </div>
        <div className='bg-white border rounded-3 shadow-sm mt-4 overflow-hidden mb-5 position-relative'>
          { partnership.pagination.totalRecords > 0
            ? <>
            <Table responsive="xl" borderless className="mb-0">
              <thead>
                <tr className="border-bottom">
                  <th className="p-3">Name</th>
                  <th className="p-3">Email</th>
                  <th className="p-3">Countries</th>
                  <th className="p-3"></th>
                </tr>
              </thead>
              <tbody>
                { partnership.data.map(c => (
                  <tr key={c._id}>
                    <td className="px-3 pt-3 pb-2">
                      <span className="fw-600">{ c.partnerName }</span>
                    </td>
                    <td className="px-3 pt-3 pb-2">{ c.email }</td>
                    <td className="px-3 pt-3 pb-2">
                      { c.receiveAll ? <span className="badge bg-success">Receive All</span> : c.countries.map(c => countries.find(cc => cc.value === c).label).join(', ') }
                    </td>
                    <td className="px-3 pt-3 pb-2 text-end">
                    { (user.userRole.includes('insights-super-admin') || isCampaignAdminWrite()) &&
                    <Dropdown>
                      <Dropdown.Toggle as={'span'} className='no-caret'>
                        <BsThreeDots className='cursor-pointer' size={20}/>
                      </Dropdown.Toggle>
                      <Dropdown.Menu align="end">
                        <Dropdown.Item onClick={() => {editPartnershipManager(c)}}>Edit</Dropdown.Item>
                        <Dropdown.Item onClick={() => {deletePartnershipManager(c)}}>Delete</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown> }
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Pagination disabled={partnership.isFetching} totalPages={partnership.pagination.totalPages} currentPage={page.partnership} setPage={setPartnershipPage} />
            </>
            : <div className="my-5 text-center">
              { partnership.isFetching ? <>&nbsp;</> : 'No records found...' }
            </div>
          }
          { partnership.isFetching
            ? <div className="bg-white top-0 start-0 position-absolute w-100 h-100 bg-opacity-50 d-flex align-items-center justify-content-center">
              <Loading height="150px" className="mt-5"/>
            </div>
            : <></>
          }
        </div>

      </div>
    </Container>
    <CampaignManagerModal onHide={onHideCampaignManagerModal} modalData={campaignManagerModal}/>
    <PartnershipManagerModal onHide={onHidePartnershipManagerModal} modalData={partnershipManagerModal}/>
  </>);
};

ManageUsers.propTypes = {};

const mapStateToProps = (state) => ({
  campaign: state.partnerEmail.campaign,
  partnership: state.partnerEmail.partnership,
  user: state.auth.user,
  campaignSaveStatus: state.partnerEmail.currentCampaignManager.saveStatus,
  partnerSaveStatus: state.partnerEmail.currentPartnershipManager.saveStatus
});

export default connect(mapStateToProps, { getPartners, deletePartner, savePartner, GetCampaigns })(ManageUsers);
