const env = process.env.REACT_APP_REACH52_CONNECT_APP_ENV

let FILE_UPLOAD_API_URL

switch (env) {
  case 'LIVE':
    FILE_UPLOAD_API_URL =
      'https://storage-api.reach52.com'
    break

  case 'DEMO':
    FILE_UPLOAD_API_URL =
      'https://storage-api-demo.reach52.com'
    break

  case 'UAT':
    FILE_UPLOAD_API_URL =
      'https://storage-api-uat.reach52.com'
    break
  case 'DEV':
    FILE_UPLOAD_API_URL = 'https://storage-api-uat.reach52.com'
    break
  default:
    FILE_UPLOAD_API_URL = 'http://localhost:4004'
    break
}

export const STORAGE_MANAGEMENT_API_URL = FILE_UPLOAD_API_URL
