import React, { useState, useEffect } from 'react'
import { Container } from "react-bootstrap";
import ProgressBar from 'react-bootstrap/ProgressBar'; 
import '../../res/styles/viewCampaignDetails.styles.scss';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getUserCountryName, getFormattedDate } from '../../lib/common.lib'
import { Dropdown } from 'react-bootstrap'
import currentDiagnosis from '../util/static/DiagnosisList.static'
import { useParams } from 'react-router-dom'
import { GetCampaignById } from '../../actions/campaign.action'
import useTitle from 'hooks/useTitle';

const ViewCampaignDetails = ({
    campaign, GetCampaignById, user
}) => {

    const title = useTitle()

    const [SelectedEngagement, setSelectedEngagement] = useState({
        name: '',
        location: '',
        age: '',
        gender: '',
        disease: ''
    });

    const { _id } = useParams()
    const onValueChange = (engagement) => {
        if (engagement) {
            const diagnosis = engagement?.criteria?.currentDiagnosis?.length > 0 ? engagement.criteria.currentDiagnosis : engagement?.criteriaV2?.medicalConditions?.length > 0 ? engagement.criteriaV2.medicalConditions: []
            let data = {
                name: engagement.name,
                location: (engagement?.criteriaV2?.level3 ? engagement.criteriaV2.level3 + ', ' : '') + (engagement?.criteriaV2?.level2 ? engagement.criteriaV2.level2 + ', ' : '') + (engagement?.criteriaV2?.countryISO? getUserCountryName(engagement?.criteriaV2?.countryISO): ''),
                age: (engagement?.criteriaV2?.age?.minimum || engagement?.criteriaV2?.age?.minimum === 0 ? engagement.criteriaV2.age.minimum + '-' : '') + (engagement?.criteriaV2?.age?.maximum ? engagement.criteriaV2.age.maximum + ' years' : ''),
                gender: engagement?.criteriaV2?.genders?.length >= 2 ? 'All' : engagement?.criteriaV2?.genders ? engagement.criteriaV2.genders[0] : '',
                disease: diagnosis?.map((element) => currentDiagnosis[element]? currentDiagnosis[element] : element).join(', ')
            }
            setSelectedEngagement(data)
        }
    }

    useEffect(() => {
        if (_id) {
            GetCampaignById(_id, user.userRole.includes("app-external-user") ? 'app-external-user' : '', user.country.iso3)
        }
    }, [GetCampaignById, _id, user])

    useEffect(() => {
        if (campaign?.engagementsData?.length > 0) {
            onValueChange(campaign?.engagementsData ? campaign.engagementsData[0] : '')
        }
        title.setTitle(campaign.name)
    }, [title, campaign])

    const targetResident = campaign.targetResidents !== 0 ? campaign.targetResidents : campaign.targetEngagements.target
    return (

        <Container fluid className='custom-container'>
            <div>
                <h5 className="fw-500 fs-3">{campaign.name}</h5>
            </div>
            <div className='border-top mt-2 mb-3'></div>

            <div className="row g-3 row-cols-1 row-cols-lg-3 w-75">
                <div className=' row-cols-2'>
                    <div className='d-inline-flex flex-column'>
                        <div className='text-title'>Country</div>
                        <div className='text-ans'>{campaign.country}</div>
                    </div>

                    <div className='d-inline-flex flex-column'>
                        <div className='text-title'>Start Date</div>
                        <div className='text-ans'>{campaign?.duration?.dateStart?.length > 1 ? getFormattedDate(campaign.duration.dateStart) : ''}</div>
                    </div>
                </div>
                <div >
                    <div className='text-title'>Target Residents</div>
                    {/* <div className='text-ans'>{campaign.targetEngagements.achieved.toString() + '/' + campaign.targetEngagements.target.toString()}</div> */}
                    <div className='text-ans'>{campaign.targetEngagements.achieved.toString() + '/' + targetResident.toString()}</div>
                    <div > <ProgressBar className='progress-actual'  >
                        {/* <ProgressBar className='tr-progress-now' now={campaign.targetEngagements.achieved} max={campaign.targetEngagements.target} />  */}
                        <ProgressBar className='tr-progress-now' now={campaign.targetEngagements.achieved} max={targetResident} /> 
                        </ProgressBar>
                    </div>
                </div>
                <div >
                    <div className='text-title'>Target Trainings</div>
                    <div className='text-ans'>{campaign.targetTrainings.achieved.toString() + '/' + campaign.targetTrainings.target.toString()}</div>
                    <div > <ProgressBar className='progress-actual'  >
                        <ProgressBar className='tt-progress-now' data-aos="slide-right" data-aos-duration="500" now={campaign.targetTrainings.achieved} max={campaign.targetTrainings.target} />
                    </ProgressBar>
                    </div>
                </div>
            </div>

            <div>
                <h5 className="fw-700 mt-4 fs-4">Campaign Details</h5>
            </div>

            <div className='flex-column'>
                <div className='d-inline-flex flex-row mt-1 width-90 gap-4'>
                    <div className='text-title w-25'>Description</div>
                    <div className='text-ans w-75 ps-2'>{campaign.description}</div>
                </div>
                <div className='d-inline-flex flex-row mt-3 width-90 gap-4'>
                    <div className='text-title w-25'>Campaign Manager</div>
                    <div className='text-ans w-75 ps-2'>Maria Winarni, maria@reach52.com</div>
                </div>
            </div>

            <div className='border-top mt-4'></div>
            <div>
                <h5 className="fw-700 mt-3 fs-4">Target Profile</h5>
            </div>
            {campaign.engagementsData ?
                (
                    <div className='flex-column gap-4'>
                        <div className='d-inline-flex flex-row width-90 gap-4'>
                            <div className='text-title w-25'>Engagement</div>
                            <div className='text-ans w-75 ps-2'>
                                <Dropdown
                                    drop={'down'}
                                    hidden={campaign?.engagementsData?.length === 0}>
                                    <Dropdown.Toggle size='sm' id="dropdown-basic">
                                        {SelectedEngagement.name}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        {campaign.engagementsData.map((obj, id) => {
                                            return (
                                                <Dropdown.Item onClick={() => { onValueChange(obj) }} key={'item-' + id} >{obj.name}</Dropdown.Item>
                                            )
                                        })}

                                    </Dropdown.Menu>
                                </Dropdown>
                                <span hidden={campaign?.engagementsData?.length > 0}>-</span>
                            </div>
                        </div>

                        <div className='d-inline-flex flex-row mt-2 width-90 gap-4'>
                            <div className='text-title w-25'>Location</div>
                            <div className='text-ans w-75 ps-2'>{SelectedEngagement.location || '-'}</div>
                        </div>
                        <div className='d-inline-flex flex-row mt-2 width-90 gap-4'>
                            <div className='text-title w-25'>Age</div>
                            <div className='text-ans w-75 ps-2'>{SelectedEngagement.age || '-'}</div>
                        </div>
                        <div className='d-inline-flex flex-row mt-2 width-90 gap-4'>
                            <div className='text-title w-25'>Gender</div>
                            <div className='text-ans w-75 ps-2'>{SelectedEngagement.gender || '-'}</div>
                        </div>
                        {/* Hidden the list of diseases */}
                        {/* <div className='d-inline-flex flex-row mt-2 width-90 gap-4'>
                            <div className='text-title w-25'>Disease</div>
                            <div className='text-ans w-75 ps-2'>{SelectedEngagement.disease || '-'}</div>
                        </div> */}

                    </div>
                ) : ''
            }
        </Container>
    );
}


ViewCampaignDetails.propTypes = {
    campaign: PropTypes.object.isRequired,
    GetCampaignById: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    campaign: state.campaigns.campaign
})

export default connect(mapStateToProps, { GetCampaignById })(ViewCampaignDetails)