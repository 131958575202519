import { LOADING } from "actions/util/types.action"

const initialState = {
  isLoading: false,
  message: '',
  isLoadingImage: false
}

const loading = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case LOADING:
      return {
        ...state,
        isLoading: payload.isLoading,
        message: payload.message,
        isLoadingImage: payload.isLoadingImage
      }
    default:
      return state
  }
}

export default loading