import { AUTH_SESSION_API_URL } from './centralizedAuthUrls'
import axios from 'axios'
import api from './api.lib'

const apiCA = axios.create({
  baseURL: AUTH_SESSION_API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
})

const updateCAUser = async (user, updates) => {
  try{
    let res
    if (user.userRole.includes('insights-super-admin') || user.userRole.includes('insights-campaign')) {
      res = await apiCA.put(`user/${user.id}`, {
        ...user,
        firstname: updates.firstName,
        lastname: updates.lastName
      }, {
        headers: {
          Authorization:
            localStorage.token && localStorage.user
              ? `Bearer ${localStorage.token}`
              : '',
        }
      })
    } else {
      res = await api.put(`${process.env.REACT_APP_API}/external-partner/${user.id}/IND`, {
        ...user,
        region: 'IND',
        firstname: updates.firstName,
        lastname: updates.lastName
      }, {
        headers: {
          Authorization:
            localStorage.token && localStorage.user
              ? `Bearer ${localStorage.token}`
              : '',
        }
      })
    }
    if(res.status === 200){
      return {
        success: true
      }
    }
    return {
      success: false,
      message: res.data.message
    }
  } catch (error) {
    return {
      success: false,
      message: error.response.data.message[0] === undefined
        ? [error.response.data.message]
        : error.response.data.message.map(m => m.message)
    }
  }
}

const updateCAPassword = async (updates, user) => {
  try {
    let res
    if (user.userRole.includes('insights-super-admin') || user.userRole.includes('insights-campaign')) {
      res = await api.post('/user/password', {
        userId: updates.id,
        currentPassword: updates.currentPassword,
        password: updates.newPassword,
        confirmPassword: updates.confirmPassword
      }, {
        headers: {
          Authorization:
            localStorage.token && localStorage.user
              ? `Bearer ${localStorage.token}`
              : '',
        },
      })
    } else {
      res = await api.post(`${process.env.REACT_APP_API}/external-partner/password`, {
        userId: updates.id,
        userRole: user.userRole,
        region: 'IND',
        currentPassword: updates.currentPassword,
        password: updates.newPassword,
        confirmPassword: updates.confirmPassword
      }, {
        headers: {
          Authorization:
            localStorage.token && localStorage.user
              ? `Bearer ${localStorage.token}`
              : '',
        },
      })
    }
    if(res.status === 200){
      return {
        success: true
      }
    }
    return {
      success: false,
      message: res.data.message
    }
  } catch (error) {
    return {
      success: false,
      message: error.response.data.message[0] === undefined
        ? [error.response.data.message]
        : error.response.data.message.map(m => m.message)
    }
  }
}

export { updateCAUser, updateCAPassword }