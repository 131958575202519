import React from 'react'

const NameDisplayPicture = ({ firstName = '', lastName = '', size = 40, color='#FFF', backgroundColor = '#AB218F', className }) => {
  return (
    <div className={`d-inline-flex align-items-center justify-content-center text-uppercase fw-600 rounded-circle ${className}`} style={{ backgroundColor, width: size, height: size }}>
      <span style={{ color }}>
        {firstName.charAt(0)}{lastName.charAt(0)}
      </span>
    </div>
  )
}

export default NameDisplayPicture