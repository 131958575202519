import React, { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { getPartner, savePartner } from '../../actions/partnerEmail.action'
import { MultiSelect } from "react-multi-select-component"
import { countries, getAvailableCountries } from 'actions/util/static-data.util'
import toast from 'react-hot-toast'

const initialFormData = {
  partnerName: '',
  email: '',
  partnerType: 'campaign',
  receiveAll: false,
  campaign: {
    id: null,
    name: ''
  },
  countries: [],
  isDeleted: false
}

export const CampaignManagerModal = ({ user, modalData, saveStatus, onHide, savePartner, campaigns }) => {

  const [formData, setFormData] = useState(null)
  const [selectedCountries, setSelectedCountries] = useState([])

  useEffect(() => {
    if(modalData.manager !== null){
      setFormData(modalData.manager)
      setSelectedCountries(countries.filter(
        c => modalData.manager.countries.includes(c.value)
      ))
    } else {
      setFormData(initialFormData)
      setSelectedCountries([])
    }
  }, [modalData.manager])

  const onShowModal = () => {
    if(modalData.manager !== null){
      setFormData(initialFormData)
      setSelectedCountries([])
    }
  } 

  useEffect(() => {
    if(saveStatus === 'saved'){
      toast.success('Changed saved!')
      onHide()
    } else if(saveStatus === 'created'){
      toast.success('Manager added!')
      onHide()
    } else if(saveStatus === 'failed'){
      toast.error('Something went wrong!')
    }
  }, [onHide, saveStatus])
  
  
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  const handleCheckbox = (e) => {
    if(e.target.name === 'receiveAll'){
      setSelectedCountries([])
    }
    setFormData({
      ...formData,
      [e.target.name]: e.target.checked
    })
  }

  const validateAndParseData = () => {
    if(!formData.receiveAll && selectedCountries.length === 0){
      toast.error('Please choose at least one country')
    } else if(!formData.email.endsWith('@reach52.com')) {
      toast.error('Invalid email address')
    } else {
      return {
        ...formData,
        countries: selectedCountries.map(c => c.value)
      }
    }
  }

  const onChangeCampaign = (e) => {
    const selectedCampaign = campaigns.find(c => c.campaignId === e.target.value)
    setFormData({
      ...formData,
      campaign: {
        id: selectedCampaign.campaignId,
        name: selectedCampaign.name
      }
    })
  }

  const onSave = (e) => {
    e.preventDefault()
    const parsedData = validateAndParseData()
    if(parsedData){
      savePartner('campaign', parsedData)
    }
  }

  return (
    <Modal size="md" show={modalData.show} onShow={onShowModal} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          { modalData.manager?._id ? 'Edit' : 'New' } Campaign Manager
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={onSave}>
        <Modal.Body>
          <Form.Group className='mb-2'>
            <Form.Label>Name</Form.Label>
            <Form.Control type='text' required maxLength={127} minLength={2} value={formData?.partnerName || ''} name='partnerName' onChange={handleChange}/>
          </Form.Group>
          <Form.Group className='mb-2'>
            <Form.Label>Email</Form.Label>
            <Form.Control type='email' required maxLength={127} value={formData?.email || ''} name='email' onChange={handleChange}/>
          </Form.Group>
          <Form.Group>
            <Form.Check label='Receive all queries' onChange={handleCheckbox} name='receiveAll' checked={formData?.receiveAll}/>
          </Form.Group>
          <Form.Group className='mb-2' hidden={formData?.receiveAll}>
            <Form.Label>Campaign</Form.Label>
            <Form.Select defaultValue={formData?.campaign?.id || ''} name='campaign' required={!formData?.receiveAll} onChange={onChangeCampaign}>
              <option disabled value=''>---Select Campaign---</option>
              {campaigns.map(c => <option key={c.campaignId} value={c.campaignId}>{c.name}</option>)}
            </Form.Select>
          </Form.Group>
          <Form.Group className='mb-2' hidden={formData?.receiveAll}>
            <Form.Label>Countries</Form.Label>
            <MultiSelect
              className='react-multiselect'
              options={getAvailableCountries(user)}
              value={selectedCountries} 
              onChange={setSelectedCountries}
              labelledBy="Select Countries"
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onHide} variant="secondary">Close</Button>
          <Button type='submit' disabled={saveStatus === 'saving'}>Save</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

CampaignManagerModal.propTypes = {}

const mapStateToProps = (state) => ({
  managerData: state.partnerEmail.currentCampaignManager.data,
  isFetching: state.partnerEmail.currentCampaignManager.isFetching,
  saveStatus: state.partnerEmail.currentCampaignManager.saveStatus,
  campaigns: state.campaigns.CampaignsData,
  user: state.auth.user
})

const mapDispatchToProps = {
  getPartner,
  savePartner
}

export default connect(mapStateToProps, mapDispatchToProps)(CampaignManagerModal)