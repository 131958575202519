import setAuthToken from 'lib/setAuthToken.lib'
import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './reducers'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const initialState = {}

const middleware = [thunk]

const store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(applyMiddleware(...middleware))
)


/*
  initialize current state from redux store for subscription comparison
  preventing undefined error
*/
let currentState = store.getState()

store.subscribe(() => {
  let previousState = currentState
  currentState = store.getState()
  if (previousState?.login?.token !== currentState?.login?.token) {
    const token = currentState.login.token
    const user = currentState.login.user
    setAuthToken(token, user)
  }
})
  
  
export default store