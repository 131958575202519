import { range } from 'actions/util/functions.util'
import React from 'react'

const Pagination = ({ setPage, currentPage, totalPages, disabled = false }) => {

  let paginationWidth = 3
  let pageButtonsStart = Math.max(1, currentPage-paginationWidth)
  let pageButtonsEnd = Math.min(currentPage+paginationWidth, totalPages)

  const pagesLeft = range(pageButtonsStart, currentPage).length-1
  const pagesRight = range(currentPage, pageButtonsEnd).length-1

  if(pagesLeft<paginationWidth){
    pageButtonsEnd = Math.min(totalPages, pageButtonsEnd + Math.min(paginationWidth-pagesLeft, totalPages-currentPage))
  } else if(pagesRight<paginationWidth){
    pageButtonsStart = Math.max(1, pageButtonsStart - Math.max(paginationWidth-pagesRight, totalPages-currentPage))
  }

  return <div className="border-top d-flex justify-content-end p-2">
    <ul className="pagination m-1">
      <li className="page-item">
        <button className="page-link" aria-label="First" onClick={() => setPage(1)} disabled={disabled || currentPage === 1}>
          <span aria-hidden="true">&laquo;</span>
        </button>
      </li>
      <li className="page-item">
        <button className="page-link" aria-label="Previous" onClick={() => setPage(currentPage-1)} disabled={disabled || currentPage === 1}>
          <span aria-hidden="true">&lt;</span>
        </button>
      </li>
      { range(pageButtonsStart, pageButtonsEnd).map((pn) =>
        <li key={pn} className={`page-item ${pn === currentPage ? 'active' : ''}`}>
          <button className="page-link" disabled={disabled} onClick={() => setPage(pn)}>{pn}</button>
        </li>
      )}
      <li className="page-item">
        <button className="page-link" aria-label="First" disabled={disabled || currentPage===totalPages} onClick={() => setPage(currentPage+1)}>
          <span aria-hidden="true">&gt;</span>
        </button>
      </li>
      <li className="page-item">
        <button className="page-link" aria-label="Previous" disabled={disabled || currentPage===totalPages} onClick={() => setPage(totalPages)}>
          <span aria-hidden="true">&raquo;</span>
        </button>
      </li>
    </ul>
  </div>
}

export default Pagination