import {
    CAMPAIGNS_DATA,
    CAMPAIGN,
    CAMPAIGNS_DATA_PAGINATED,
    CAMPAIGN_PROGRESS_ENGAGEMENTS_LIST,
    CAMPAIGN_PROGRESS_ENGAGEMENT_FETCH_STATUS,
    CAMPAIGN_PROGRESS_TRAININGS_LIST,
    CAMPAIGN_PROGRESS_TRAINING_FETCH_STATUS,
    CAMPAIGN_PROGRESS_TRAINING_UPDATE_STATUS,
    CAMPAIGN_PROGRESS_ENGAGEMENT_UPDATE_STATUS,
    CAMPAIGN_PROGRESS_DELETE_STATUS,
    CAMPAIGN_PROGRESS_DELETE_ITEM,
    ALL_CAMPAIGN_PROGRESS,
    ALL_CAMPAIGN_PROGRESS_VIEW,
    CAMPAIGNS,
    CAMPAIGN_FILTER
} from "actions/util/types.action"

const initialState = {
    CampaignsData: [],
    CampaignsDataPaginated: [],
    DataCount: 0,
    campaign: {
        _id: "",
        _metadata: {
            updated: {
                date: ""
            }
        },
        campaignId: "",
        countryISO: "",
        description: "",
        duration: {
            dateStart: "",
            dateEnd: ""
        },
        name: "",
        organizationPartnerId: "",
        engagementsData: [
        ],
        targetEngagements: {
            achieved: 0,
            target: 0
        },
        status: "",
        targetTrainings: {
            target: 0,
            achieved: 0
        },
        country: "",
        campaignStatus: "",
        targetResidents: 0
    },
    progress: {
        trainings: {
            fetchStatus: '',
            updateStatus: {
                status: '',
                message: ''
            },
            data: []
        },
        engagements: {
            fetchStatus: '',
            updateStatus: {
                status: '',
                message: ''
            },
            data: []
        },
        deleteStatus: ''
    },
    graphData: { engagement: [], training: [] },
    campaignProgress: {
        engagement: [],
        training: []
    },
    isAllCampaignProgressView: false,
    campaignsSelected: [],
    selectedCampaignsFilter: []
}

const campaignReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case CAMPAIGNS_DATA:
            return {
                ...state,
                CampaignsData: payload.rows,
                DataCount: payload.count,
            }
        case CAMPAIGNS_DATA_PAGINATED:
            return {
                ...state,
                CampaignsDataPaginated: payload.rows,
                DataCount: payload.count,
            }
        case ALL_CAMPAIGN_PROGRESS: 
            return {
                ...state,
                campaignProgress: payload.campaignProgress,
                graphData: payload.graphData
            }
        case CAMPAIGN_FILTER:
            return {
                ...state,
                selectedCampaignsFilter: payload
            }
        case ALL_CAMPAIGN_PROGRESS_VIEW: 
            return {
                ...state,
                isAllCampaignProgressView: payload
            }
        case CAMPAIGN:
            return {
                ...state,
                campaign: payload ? payload : initialState.campaign
            }
        case CAMPAIGNS:
            return {
                ...state,
                campaignsSelected: payload ? payload : initialState.campaignsSelected
            }
        case CAMPAIGN_PROGRESS_TRAININGS_LIST:
            return {
                ...state,
                progress: {
                    ...state.progress,
                    trainings: {
                        ...state.progress.trainings,
                        data: payload
                    }
                }
            }
        case CAMPAIGN_PROGRESS_ENGAGEMENTS_LIST:
            return {
                ...state,
                progress: {
                    ...state.progress,
                    engagements: {
                        ...state.progress.engagements,
                        data: payload
                    }
                }
            }
        case CAMPAIGN_PROGRESS_TRAINING_FETCH_STATUS:
            return {
                ...state,
                progress: {
                    ...state.progress,
                    trainings: {
                        ...state.progress.trainings,
                        fetchStatus: payload
                    }
                }
            }
        case CAMPAIGN_PROGRESS_ENGAGEMENT_FETCH_STATUS:
            return {
                ...state,
                progress: {
                    ...state.progress,
                    engagements: {
                        ...state.progress.engagements,
                        fetchStatus: payload
                    }
                }
            }
        case CAMPAIGN_PROGRESS_TRAINING_UPDATE_STATUS:
            return {
                ...state,
                progress: {
                    ...state.progress,
                    trainings: {
                        ...state.progress.trainings,
                        updateStatus: payload
                    }
                }
            }
        case CAMPAIGN_PROGRESS_ENGAGEMENT_UPDATE_STATUS:
            return {
                ...state,
                progress: {
                    ...state.progress,
                    engagements: {
                        ...state.progress.engagements,
                        updateStatus: payload
                    }
                }
            }
        case CAMPAIGN_PROGRESS_DELETE_STATUS:
            return {
                ...state,
                progress: {
                    ...state.progress,
                    deleteStatus: payload
                }
            }
        case CAMPAIGN_PROGRESS_DELETE_ITEM:
            return {
                ...state,
                progress: {
                    ...state.progress,
                    engagements: (payload.type === 'engagement' ? {
                        ...state.progress.engagements,
                        data: state.progress.engagements.data.filter(p => p._id !== payload.progressId)
                    } : state.progress.engagements),
                    trainings: (payload.type === 'training' ? {
                        ...state.progress.trainings,
                        data: state.progress.trainings.data.filter(p => p._id !== payload.progressId)
                    } : state.progress.trainings)
                }
            }
        default:
            return state
    }
}

export default campaignReducer