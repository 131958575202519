import { SHOW_PHOTO_MODAL } from "actions/util/types.action"

const initialState = {
  isShowPhotoModal: false,
  object:{},
  isPreviewOption: false,
  isLiveUrl: false,
  selectedIndex: null
}

const photoModal = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case SHOW_PHOTO_MODAL:
      return {
        ...state,
        isShowPhotoModal: payload.isShowPhotoModal,
        object: payload.object,
        isPreviewOption: payload.isPreviewOption,
        isLiveUrl: payload.isLiveUrl,
        selectedIndex: payload.selectedIndex
      }
    default:
      return state
  }
}

export default photoModal