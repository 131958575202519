import React from 'react'
import { ProgressBar } from 'react-bootstrap'

const ProgressBarWithTarget = ({ now, max, className = '', color }) => {
  return <div className='position-relative'>
      <ProgressBar>
        <ProgressBar now={now > 0 ? now : 0} data-aos="slide-right" data-aos-duration="500" max={max || 1} className={className} style={{ backgroundColor: color }}></ProgressBar>
      </ProgressBar>
  </div>
}

export default ProgressBarWithTarget