export const countriesForExpandCampaign = [
    { label: 'Cambodia', value: 'KHM' },
    { label: 'India', value: 'IND' },
    { label: 'Indonesia', value: 'IDN' },
    { label: 'Kenya', value: 'KEN' },
    { label: 'Philippines', value: 'PHL' },
    { label: 'South Africa', value: 'ZAF' },
    { label: 'Nigeria', value: 'NGA' },
    { label: 'Tanzania', value: 'TZA' },
    { label: 'Uganda', value: 'UGA' },
    { label: 'Pakistan', value: 'PAK' },
    { label: 'Bangladesh', value: 'BGD' },
    { label: 'Vietnam', value: 'VNM' },
    { label: 'Thailand', value: 'THA' },
]
export const focusAreas = [
    'Maternal health',
    'Diabetes',
    'Hypertension',
    'Respiratory',
    'Vaccination',
    'Nutrition',
    'WASH',
    'Tropical Diseases',
    'Infectious Diseases',
    'Vision Care',
    'Others'
]
export const imageFileTypes = ['png','jpg','JPG','jpeg','JPEG','PNG','gif','GIF']

export const countries = [
    { label: 'Cambodia', value: 'KHM' },
    { label: 'India', value: 'IND' },
    { label: 'Indonesia', value: 'IDN' },
    { label: 'Kenya', value: 'KEN' },
    { label: 'Philippines', value: 'PHL' },
    { label: 'South Africa', value: 'ZAF' }
]

export const getAvailableCountries = (user, includeAll = false) => {
    let availableCountries = countries.filter(country => (user.country.iso3.toUpperCase() === 'ALL' && country.value !== 'IDN') || country.value === user.country.iso3.toUpperCase())
    if(includeAll && user.country.iso3.toUpperCase() === 'ALL'){
        availableCountries = [{ value: 'ALL', label: 'ALL' }, ...availableCountries]
    }
    return availableCountries
}