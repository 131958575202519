import React, { Fragment, useEffect, useLayoutEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { CrossStorageClient } from 'cross-storage'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import TopNavbar from 'components/layout/TopNavbar' 
import { authenticateSession, startLogout } from '../../actions/auth.action'
import { AUTH_CLIENT_HOST_URL } from 'lib/centralizedAuthUrls'
import ContentWrapper from 'components/common/ContentWrapper'
import Loading from 'components/util/Loading'

const PrivateRoute = ({
  component: Component,
  isAuthenticated,
  user,
  authenticateSession,
  startLogout
}) => {

  const location = useLocation();
  const [userValidated, setUserValidated] = useState(false)

  useLayoutEffect(() => {
    const validateSession = async () => {
      if(!user?.userRole?.includes('app-external-user')){
        const storage = new CrossStorageClient(AUTH_CLIENT_HOST_URL, {
          timeout: 10000,
          frameId: 'storageFrame',
        })
        await storage
          .onConnect()
          .then(async () => {
            const session = JSON.parse(await storage.get('session'))
            const isSessionActive = session.insights.isActive
            if (isSessionActive) {
              if (session.token) {
                await authenticateSession()
                setUserValidated(true)
              }
            } else {
              startLogout(user)
            }
          })
          .catch((err)=> {
            startLogout(user)
          })
      } else {
        setUserValidated(true)
      }
    }
    validateSession()
  }, [location, user, authenticateSession, startLogout])

  if (!isAuthenticated) {
    startLogout(user)
  }

  return (
    userValidated && user
    ? <Fragment>
        <TopNavbar/>
        <ContentWrapper>
          <Component />
        </ContentWrapper>
      </Fragment>
    : <Loading />
  )
}

PrivateRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user
})

export default connect(mapStateToProps, { authenticateSession, startLogout })(PrivateRoute)
