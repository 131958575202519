import { AUTH_SESSION_API_URL } from 'lib/centralizedAuthUrls';
import api from '../lib/api.lib'
import { GALLERY_APPEND_SIGNED_URLS, GET_SIGNED_URLS, GET_PROFILE_PIC_SIGNED_URL,
  GET_PROFILE_PIC_SIGNED_URLS } from './util/types.action';
import axios from 'axios'

const sigendUrl = (data) => ({
  type: GET_SIGNED_URLS,
  payload: data
})

const profilePicSigendUrls = (data) => ({
  type: GET_PROFILE_PIC_SIGNED_URLS,
  payload: data
})

const profilePicSigendUrl = (data) => ({
  type: GET_PROFILE_PIC_SIGNED_URL,
  payload: data
})

const sigendUrlsGallery = (data) => ({
  type: GALLERY_APPEND_SIGNED_URLS,
  payload: data
})

export const getSignedUrls = (region, imageUrls, userRole) => async (dispatch) => {
  try {
    if(imageUrls.length > 0) {
      if (userRole === 'app-external-user') {
        const res = await api.post(`${process.env.REACT_APP_API}/images/connect/campaign/`, {
          region, imageUrls, userRole
        })
        if (res.status === 200) {
          await dispatch(sigendUrl(res.data))
        } else {
          console.log('error', res.data.message)
        }
      } else {
        const res = await api.post(`${AUTH_SESSION_API_URL}/images/connect/campaign/`, {
          region, imageUrls
        })
        if (res.status === 200) {
          await dispatch(sigendUrl(res.data))
        } else {
          console.log('error', res.data.message)
        }
      }
    }
  } catch (error) {
    console.log('error', error)
  }
}

export const getSignedUrlsGallery = (region, imageUrls, userRole) => async (dispatch, getState) => {
  try {
    if(imageUrls.length > 0) {
      if (userRole === 'app-external-user') {
        const res = await api.post(`${process.env.REACT_APP_API}/images/connect/campaign/`, {
          region, imageUrls, userRole
        })
        if (res.status === 200) {
          await dispatch(sigendUrlsGallery(res.data))
        } else {
          console.log('error', res.data.message)
        }
      } else {
        const res = await api.post(`${AUTH_SESSION_API_URL}/images/connect/campaign/`, {
          region, imageUrls
        })
        if (res.status === 200) {
          await dispatch(sigendUrlsGallery(res.data))
        } else {
          console.log('error', res.data.message)
        }
      }
    }
  } catch (error) {
    console.log('error', error)
  }
} 

export const getProfilePicSignedUrl = ({region, imageName, token, userRole}) => async (dispatch) => {
  try {
    const apiImage = axios.create({
      baseURL: '/',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token ? `Bearer ${token}`: '',
        authenticateduser: localStorage.user ? JSON.parse(localStorage.user).id : undefined
      },
    })
    if (userRole === 'app-external-user') {
      const res = await apiImage.post(`${process.env.REACT_APP_API}/images/external-partner/profile-picture/`, {
        region, imageName, userRole
      })
      if (res.status === 200) {
        await dispatch(profilePicSigendUrl(res.data))
      } else {
        console.log('error', res.data.message)
      }
    } else {
      const res = await apiImage.post(`${AUTH_SESSION_API_URL}/images/user/profile-picture/`, {
        region, imageName
      })
      if (res.status === 200) {
        await dispatch(profilePicSigendUrl(res.data))
      } else {
        console.log('error', res.data.message)
      }
    }
  } catch (error) {
    console.log('error', error)
  }
}

export const getProfilePicSignedUrls = (region, imageUrls, userRole) => async (dispatch) => {
  try {
    if(imageUrls.length > 0) {
      if (userRole === 'app-external-user') {
        const res = await api.post(`${process.env.REACT_APP_API}/images/external-partner/profile-pictures/`, {
          region, imageUrls, userRole
        })
        if (res.status === 200) {
          await dispatch(profilePicSigendUrls(res.data))
        } else {
          console.log('error', res.data.message)
        }
      } else {
        const res = await api.post(`${AUTH_SESSION_API_URL}/images/user/profile-pictures/`, {
          region, imageUrls
        })
        if (res.status === 200) {
          await dispatch(profilePicSigendUrls(res.data))
        } else {
          console.log('error', res.data.message)
        }
      }
    }
  } catch (error) {
    console.log('error', error)
  }
}
