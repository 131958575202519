import { combineReducers } from "redux"
import auth from "./auth.reducer"
import news from "./news.reducer"
import loading from "./loading.reducer"
import fileUpload from "./fileUpload.reducer"
import blogPost from "./blogPost.reducer"
import photoModal from "./photoModal.reducer"
import signedUrl from "./getSignedUrls.reducer"
import partnerEmail from "./partnerEmail.reducer"
import managePartners from "./managePartners.reducer"
import campaigns from "./campaign.reducer"
import inquiry from "./inquiry.reducer"
import expandCampaign from "./expandCampaign.reducer"
import galleryPosts from "./galleryPosts.reducer"
import campaignListing from "./campaignListing.reducer"
import newsLetter from "./newsLetter.reducer"

const appReducer = combineReducers({
	auth,
	news,
	loading,
	fileUpload,
	blogPost,
	photoModal,
	signedUrl,
	partnerEmail,
	campaigns,
	inquiry,
	managePartners,
	expandCampaign,
	galleryPosts,
	campaignListing,
	newsLetter
})
  
const rootReducer = (state, action) => {
	if (action.type === 'LOGOUT') {
		return appReducer(undefined, action)
	}

	return appReducer(state, action)
}

export default rootReducer