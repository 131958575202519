import GuestNavbar from 'components/layout/GuestNavbar'
import React from 'react'
import { Container } from 'react-bootstrap'
import '../../res/styles/privacy-policy.scss'
import useTitle from 'hooks/useTitle'

const PrivacyPolicy = () => {

  useTitle('Privacy Policy')

  return <Container className='privacy-policy'>
    <GuestNavbar />
    <div className="row p-relative">
      <div className='col-7'>
        <div className='title px-3 py-4 font-bold fs-4 fw-bold'>Cookies and Privacy Policy</div>

        <div className='content mt-4'>
          <h6 id="Introduction"><strong>Introduction</strong></h6>
          <p>reach52 Asia Pte. Ltd. (&ldquo;reach52&rdquo;, &ldquo;we&rdquo; or &ldquo;us&rdquo;) takes the privacy of your information very seriously. This Privacy Policy applies to the <a href="https://insights.reach52.com/" rel="noreferrer noopener" target="_blank">https://insights.reach52.com/</a> website (the &ldquo;Website&rdquo;) and governs data collection, processing and usage in compliance with the Personal Data Protection Act 2012 (No. 26 of 2012) of Singapore (&ldquo;PDPA&rdquo;). By using the Website, you consent to the data practices described in this statement. Capitalized terms that are not defined in this Privacy Policy have the meaning given to them in our Terms of Service.</p>

          <h6 id="Information"><strong>Information Collected from All Visitors to our Website</strong></h6>
          <p>We will obtain personal data about you when you visit us. When you visit us, we may monitor the use of this Website through the use of cookies and similar tracking devices. For example, we may monitor the number of times you visit our Website or which pages you go to. This information helps us to build a profile of our users. Some of this data will be aggregated or statistical, which means that we will not be able to identify you individually.</p>
          <p>This Privacy Policy applies to all visitors to our Website.</p>

          <h6 id="Additional"><strong>Additional Personal Information that May be Collected</strong></h6>
          <p>reach52 may collect and process:</p>
          <p>Personally identifiable information, such as:</p>
          <p>Your e-mail address and name, when you contact us;</p>
          <p>Details contained in the relevant document that you key in when you use our Services. These details may include your name, handphone number, email, the purpose of your query, and details about your will; (&ldquo;Personal Information&rdquo;)</p>
          <p>Information about your computer hardware and software when you use our Website. The information can include: your IP address, browser type, domain names, access times and referring website addresses. This information is used by reach52 for the operation of the Services, to maintain quality of the Services, and to provide general statistics regarding use of the reach52.com website.</p>

          <h6 id="Personal-Information"><strong>Use of Personal Information</strong></h6>
          <p>reach52 uses the collected information:</p>
          <p>To operate the Website and deliver the Services;</p>
          <p>To process, and where necessary, respond to your application, enquiry or request;</p>
          <p>To gather customer feedback;</p>
          <p>To inform or update you of other products or services available from reach52 and its affiliates, where you have consented to be contacted for such purposes;</p>
          <p>To monitor, improve and administer the Website and Services, and to provide general statistics regarding user of the Website;</p>
          <p>To update you on changes to the Website and Services.</p>

          <h6 id="Non-disclosure"><strong>Non-disclosure</strong></h6>
          <p>reach52 does not sell, rent, lease, or release your Personal Information collected on this website to third parties. reach52 may, from time to time, contact you on behalf of external business partners about a particular offering that may be of interest to you. In those cases, your unique Personal Information is not transferred to the third party without your explicit consent. In addition, reach52 may share data with trusted partners to help us perform statistical analysis, send you email or provide customer support. All such third parties are prohibited from using your personal information except to provide these services to reach52, and they are required to maintain the confidentiality of your Personal Information.</p>
          <h6 id="Disclosure"><strong>Disclosure of Personal Information</strong></h6>
          <p>
            reach52 will disclose or share your Personal Information, without notice, only if required to do so by law or in the good faith belief that any such action is necessary to:
            <ol className='my-1'>
              <li>comply with any legal requirements or comply with legal process served on reach52 or the Website;</li>
              <li>protect and defend the rights or property of reach52; and</li>
              <li>act under exigent circumstances to protect the personal safety of users of <a href="https://insights.reach52.com/" rel="noreferrer noopener" target="_blank">https://insights.reach52.com/</a> or the general public.</li>
            </ol>
          </p>
          <p>
            We may disclose your personal information to third parties:
            <ol className='my-1'>
              <li>in the event that we sell or buy any business or assets, in which case we may disclose your personal data to the prospective seller or buyer of such business or assets; and</li>
              <li>if <a href="https://insights.reach52.com/" rel="noreferrer noopener" target="_blank">https://insights.reach52.com/</a>or substantially all of its assets are acquired by a third party, in which case personal data held by it about its customers will be one of the transferred assets.</li>
            </ol>
          </p>

          <h6 id="Use-Of-Cookies"><strong>Use of Cookies</strong></h6>
          <p>The Website uses &ldquo;cookies&rdquo; to help you personalize your online experience. A cookie is a text file that is placed on your hard drive by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you and can only be read by a web server in the domain that issued the cookie to you.</p>
          <p>Cookies on the Website may be used to ensure a smooth user experience, perform analytics, and for showing relevant advertisements. Please note that third parties (such as analytics software) may also use cookies, which are used in line with their own privacy policies. These cookies are likely to be analytical/performance cookies or targeting cookies. The Website uses Google Analytics. Please refer to <a href="http://www.google.com/policies/privacy/partners" rel="noreferrer noopener" target="_blank">http://www.google.com/policies&hellip;</a>to find out more about how Google uses data when you use our website and how to control the information sent to Google.</p>
          <p>We also use social media buttons and/or plugins on this website that allow you to connect with your social network, including Facebook, Instagram, Twitter, and LinkedIn. We set cookies through our site which may be used to enhance your profile on these sites or contribute to the data they hold in line with their respective privacy policies.</p>
          <p>Most Web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not be able to access all or parts of our Website or to fully experience the interactive features of the reach52 services or websites you visit.</p>

          <h6 id="Security"><strong>Security of your Personal Information</strong></h6>
          <p>We strive to maintain the safety of your Personal Information. Unfortunately, no internet-based service is completely secure. Although we will do our best to protect your personal data, we cannot guarantee the security of your data transmitted to our site; any transmission is at your own risk. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorised access.</p>

          <h6 id="Access"><strong>Access to, Updating, and Non-Use of Your Personal Information</strong></h6>
          <p>Subject to the exceptions referred to in section 21(2) of PDPA, you have the right to request a copy of the information that we hold about you. If you would like a copy of some or all of your personal information, please send an email to <a href="mailto:dataprivacy@reach52.com" rel="noreferrer noopener" target="_blank">dataprivacy@reach52.com</a>. We may make a small charge for this service.</p>
          <p>We want to ensure that your Personal Information is accurate and up to date. If any of the information that you have provided to reach52 changes, for example if you change your email address, name or contact number, please let us know the correct details by sending an email to <a href="mailto:dataprivacy@reach52.com" rel="noreferrer noopener" target="_blank">dataprivacy@reach52.com</a>. You may ask us, or we may ask you, to correct information you or we think is inaccurate, and you may also ask us to remove information which is inaccurate.</p>
          <p>You have the right to ask us not to collect, use, process, or disclose your Personal Information in any of the manner described herein. We will usually inform you (before collecting your Personal Information) if we intend to use your Personal Information for such purposes or if we intend to disclose your Personal Information to any third party for such purposes. You can give us notice of your intention to halt the collection, use, processing, or disclosure of your Personal Information at any time by contacting us at <a href="mailto:dataprivacy@reach52.com" rel="noreferrer noopener" target="_blank">dataprivacy@reach52.com</a></p>

          <h6 id="Links"><strong>Links to Other Websites</strong></h6>
          <p>Our Website may contain links to other websites. This Privacy Policy only applies to this website so when you link to other websites you should read their own privacy policies.</p>

          <h6 id="Changes"><strong>Changes To This Statement</strong></h6>
          <p>reach52 will occasionally update this Privacy Policy to reflect customer feedback. reach52 encourages you to periodically review this Privacy Policy to be informed of how reach52 is protecting your information.</p>

          <h6 id="Contact"><strong>Contact Information</strong></h6>
          <p>reach52 Asia Pte. Ltd. welcomes your comments regarding this Privacy Policy. If you believe that reach52.com has not adhered to this Privacy Policy, please contact us at <a href="mailto:dataprivacy@reach52.com" rel="noreferrer noopener" target="_blank">dataprivacy@reach52.com.</a></p>

        </div>
      </div>
      <div className='col-5 right-sidebar'>
        <ol className='text-primary'>
          <li><a href="#Introduction">Introduction</a></li>
          <li><a href="#Information">Information Collected from All Visitors to our Website</a></li>
          <li><a href="#Additional">Additional Personal Information that May be Collected</a></li>
          <li><a href="#Personal-Information">Use of Personal Information</a></li>
          <li><a href="#Non-disclosure">Non-disclosure</a></li>
          <li><a href="#Disclosure">Disclosure of Personal Information</a></li>
          <li><a href="#Use-Of-Cookies">Use of Cookies</a></li>
          <li><a href="#Security">Security of your Personal Information</a></li>
          <li><a href="#Access">Access to, Updating, and Non-Use of Your Personal Information</a></li>
          <li><a href="#Links">Links to Other Websites</a></li>
          <li><a href="#Changes">Changes To This Statement</a></li>
          <li><a href="#Contact">Contact Information</a></li>
        </ol>
      </div>

    </div>
  </Container>
}

export default PrivacyPolicy