import React from "react";
import Logo from '../../res/img/reach52-beta-logo.png';

import '../../res/styles/loader.styles.scss';

const Loading = ({ message = "", isLoadingImage = false, height = '100vh', className = '' }) => (
  <div className={`${className} loader`} style={{ height: height }}>
    {isLoadingImage && <img src={Logo} alt='' className="loader__image"/>}
    <div id="wave">
      <span className="dot" />
      <span className="dot" />
      <span className="dot" />
    </div>
    <p className="loader__message">{message}</p>
  </div>
);

export default Loading;
