import React, { useEffect, useLayoutEffect } from 'react'
import { Container } from 'react-bootstrap'
import PropTypes from 'prop-types'
import '../../res/styles/news-and-views.styles.scss'
import { getNewsArticle } from 'actions/news.action'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import '../../res/styles/wordpress.styles.scss'
import Loading from 'components/util/Loading'
import dayjs from 'dayjs'
import { IoMdOpen } from 'react-icons/io'
import ReactDOMServer from 'react-dom/server'
import useTitle from 'hooks/useTitle'

const ViewNewsArticle = ({ getNewsArticle, newsArticle, isLoading }) => {

  const title = useTitle()

  let { articleId } = useParams();

  useEffect(() => {
    getNewsArticle(articleId)
  }, [getNewsArticle, articleId])

  useEffect(() => {
    if (newsArticle) {
      title.setTitle(newsArticle.title)
    }
  }, [title, newsArticle])

  useLayoutEffect(() => {
    const wpContentNode = document.getElementById('root')
    const observer = new MutationObserver((mutationList, observer) => {
      const pdfEmbeds = document.getElementsByClassName("pdfemb-viewer")
      const videoEmbeds = document.querySelectorAll(".wp-video:not(.treated)")

      for (let embed of pdfEmbeds){
        const embedWrapper = document.createElement('div')
        const controlsWrapper = document.createElement('div')

        controlsWrapper.setAttribute('class', 'my-1 text-end')

        const embededPdf = document.createElement('embed')
        embededPdf.setAttribute('src', `${embed.href}#toolbar=0&navpanes=0&scrollbar=0`);
        embededPdf.classList.add('pdf-viewer')
        embededPdf.style.width = '100%'
        embededPdf.style.height = 'calc(100vh - 120px)'
        embededPdf.style.display = 'block'

        const openButton = document.createElement('a')
        openButton.href = embed.href
        openButton.classList.add('ms-1')
        openButton.innerHTML = ReactDOMServer.renderToString(IoMdOpen())
        openButton.setAttribute('target', '_blank')

        controlsWrapper.appendChild(openButton)

        embedWrapper.appendChild(embededPdf)
        embedWrapper.appendChild(controlsWrapper)

        embed.replaceWith(embedWrapper)
      }

      for (let videoEmbed of videoEmbeds) {
        videoEmbed.setAttribute('style', '')
        videoEmbed.classList.add('treated')
      }

    })
    observer.observe(wpContentNode, { attributes: true, childList: true, subtree: true })
    return () => {
      observer.disconnect()
    }
  }, [])

  if (isLoading) {
    return <Container>
      <div className='d-flex w-100 h-100'>
        <Loading height='auto' className='py-5'/>
      </div>
    </Container>
  }

  return <Container className='news-and-views'>
    <div className='news-and-views-container mb-5'>
      <h1 className='mt-5 mb-2 text-center' dangerouslySetInnerHTML={{ __html: newsArticle?.title || '' }}></h1>
      <div className='text-center mb-3 text-muted'>
        {newsArticle ? dayjs(newsArticle.date).format('DD MMMM YYYY') : ''}
      </div>
      <div dangerouslySetInnerHTML={{ __html: newsArticle?.content || '' }} className='wordpress-embed'></div>
    </div>
  </Container>
}

ViewNewsArticle.propTypes = {
  newsArticle: PropTypes.any,
  isLoading: PropTypes.bool.isRequired
}

const mapStateToProps = (state) => ({
  newsArticle: state.news.newsArticle,
  isLoading: state.news.itemIsLoading,
  getNewsArticle
})

export default connect(mapStateToProps , { getNewsArticle })(ViewNewsArticle)