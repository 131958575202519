import api from './api.lib'

const setAuthToken = (token, user) => {
  if (token && user) {
    api.defaults.headers.Authorization = `Bearer ${token}`
    api.defaults.headers.authenticateduser = user.id
    localStorage.setItem('token', token)
    localStorage.setItem('user', JSON.stringify(user))
  } else {
    delete api.defaults.headers.Authorization
    delete api.defaults.headers.authenticateduser
    localStorage.removeItem('token')
    localStorage.removeItem('user')
  }
}

export default setAuthToken