import { CAMPAIGN_LISTING_IS_FETCHING, IS_FROM_CAMPAIGN_LISTING_SCREEN } from './types.action';

export const setIsFromCampaignLisitingScreen = ({isFromCampaignLisitingScreen}) => ({
  type: IS_FROM_CAMPAIGN_LISTING_SCREEN,
  payload: {isFromCampaignLisitingScreen}
})

export const setIsCampaignLisitingFetching = (payload) => ({
  type: CAMPAIGN_LISTING_IS_FETCHING,
  payload
})
