import api from '../lib/api.lib'
import {
  GET_PARTNERS_LIST,
  AT_SET_ORGANIZATION_PARTNERTS,
  USER_CREATED_SUCCESS,
  GET_PARTNER_USER,
  PARTNER_API_STATUS,
  PARTNERS_LIST_FETCHING_STATUS,
  DELETE_PARTNER_USER
} from "./util/types.action";
import { AUTH_SESSION_API_URL } from 'lib/centralizedAuthUrls';
import { uploadProfilePicture } from './fileUpload.action'

export const loadExternalUsers =
  ({ pageIndex, pageSize, user }) =>
    async (dispatch) => {
      try {
        const region = 'IND'
        dispatch({ type: PARTNERS_LIST_FETCHING_STATUS, payload: true })
        const res = await api.get(`${process.env.REACT_APP_API}/external-partner/${region}`, {
          params: { pageIndex: pageIndex, pageSize: pageSize, isoCountry: user.country.iso3 },
        })
        dispatch({ type: PARTNERS_LIST_FETCHING_STATUS, payload: false })
        dispatch({
          type: GET_PARTNERS_LIST,
          payload: res.data.data,
        })
      } catch (error) {
        console.log(error)
      }
    }

export const getOrganiztionPartners = ({ organizationType, country }) => async (dispatch) => {
  try {
    const data = {
      organizationType: organizationType,
      country: country,
      region: country === 'IDN' ? 'IDN' : 'IND'
    }
    const res = await api.post(`${AUTH_SESSION_API_URL}/organization-partners/type`, data)
    const result = res.data
    if (result) {
      dispatch({
        type: AT_SET_ORGANIZATION_PARTNERTS,
        payload: result,
      })
    }
  } catch (error) {
    console.log(error)
  }
}

export const getUser =
  ({ user, _id, navigate, isNavigateRequired }) =>
    async (dispatch) => {
      try {
        const region = 'IND'
        const res = await api.get(`${process.env.REACT_APP_API}/external-partner/${_id}/${region}`, {
          params: { userRole: user.userRole }
        })
        if (res) {
          dispatch({
            type: GET_PARTNER_USER,
            payload: res.data.data,
          })
          if (isNavigateRequired) {
            navigate(`/update-partner/${_id}`)
          }
        }
      } catch (error) {
        console.log(error)
      }
    }

export const deleteUser =
  ({ user, obj }) =>
    async (dispatch) => {
      try {
        const _id = obj._id
        const region = 'IND'
        const res = await api.delete(`${process.env.REACT_APP_API}/external-partner/${_id}/${region}`, {
          params: { userRole: user.userRole }
        })
        if (res) {
          dispatch({
            type: DELETE_PARTNER_USER,
            payload: _id,
          })
        }
      } catch (error) {
        console.log(error)
      }
    }


export const createUser =
  (user, requestBody, navigate) =>
    async (dispatch, getState) => {
      try {
        await dispatch(uploadProfilePicture(requestBody.profilePic, requestBody.country.iso3))
        const imageName = getState().fileUpload.profilePicture
        requestBody.profilePic = imageName
        const region = 'IND'
        const res = await api.post(`${process.env.REACT_APP_API}/external-partner/${region}`, requestBody, {
          params: { userRole: user.userRole }
        })

        if (res) {
          dispatch({
            type: USER_CREATED_SUCCESS,
            payload: res.data.data,
          })
          navigate('/partners')
        }
      } catch (error) {
        if (error?.response?.status === 400) {
          if (error?.response?.data?.message === 'Username already in use') {
            dispatch({
              type: PARTNER_API_STATUS,
              payload: 'duplicate-username',
            })
          }
          if (error?.response?.data?.message === 'Email already in use') {
            dispatch({
              type: PARTNER_API_STATUS,
              payload: 'duplicate-email',
            })
          }
          setTimeout(
            () =>
              dispatch({
                type: PARTNER_API_STATUS,
                payload: ''
              }),
            4000
          )
        }
      }
    }

export const updateUser =
  (user, submittedData, userId, navigate, path) =>
    async (dispatch, getState) => {
      try {
        if (typeof submittedData.profilePic === 'object') {
          await dispatch(uploadProfilePicture(submittedData.profilePic, submittedData.country.iso3))
          const imageName = getState().fileUpload.profilePicture
          submittedData.profilePic = imageName
        }
        const region = 'IND'
        const res = await api.put(`${process.env.REACT_APP_API}/external-partner/${userId}/${region}`, submittedData, {
          params: { userRole: user.userRole }
        })
        if (res) {
          navigate(path)
        }
      } catch (error) {
      }

    }