import api from "lib/api.lib";
import { getSignedUrlsGallery, getProfilePicSignedUrls } from "./getSignedUrls";
import { GALLERY_SET_DATA, GALLERY_IS_FETCHING } from "./util/types.action";


export const setGalleryData = (data) => async (dispatch) => {
  await dispatch({type: GALLERY_SET_DATA, payload: data})
}

export const getPosts = (page, campaignsSelected) => async (dispatch, getState) => {
  try {
    await dispatch({ type: GALLERY_IS_FETCHING, payload: true });
    const campaignIds = []
    for (const obj of campaignsSelected ) {
      campaignIds.push(obj._id)
    }
    const params = { page, campaignIds, userRole: 'app-external-user' }
    const res = await api.get(`${process.env.REACT_APP_API}/gallery/`, {
      params: params
    });
    if (res.status === 200) {
      if(res.data.data.length > 0){
        const imageUrls = []
        const profileUrls = []
        res.data.data.map((object)=> {
          imageUrls.push({ imageNames: object.fileUrls, id: object._id})
          profileUrls.push({ imageName: object.profileUrl, id: object._id })
          return object
        })
        await dispatch(getProfilePicSignedUrls(res.data.data[0].country === 'IDN' ? 'IDN' : 'IND',profileUrls, 'app-external-user'))
        const profilePicUrls = getState().signedUrl.profilePicSignedUrls
        res.data.data.map((object) => {
          profilePicUrls.map((url) => {
            if(url.id === object._id){
              object.profilePicUrl = url.imageUrl
            }
            return url
          })
          campaignsSelected.map((campaign) => {
            if(campaign._id === object.campaignId){
              object.campaignName = campaign.name
            }
            return campaign
          })
          return object
        })
        await dispatch({ type: GALLERY_SET_DATA, payload: res.data })
        await dispatch(getSignedUrlsGallery(res.data.data[0].country === 'IDN' ? 'IDN' : 'IND', imageUrls, 'app-external-user'))
      }
    }
  } catch (error) {
  } finally {
    await dispatch({ type: GALLERY_IS_FETCHING, payload: false });
  }
};