import React, { useState, useEffect } from 'react'
import { Button, Container, Form, Spinner } from 'react-bootstrap'
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
import { sendInquiry, resetInquiryStatus } from '../../actions/inquiry.action'
import "../../res/styles/inquiry-page.styles.scss"
import { BsCheck2Circle, BsExclamationOctagon } from 'react-icons/bs';
import toast from 'react-hot-toast';
import { getAvailableCountries } from 'actions/util/static-data.util';
import useTitle from 'hooks/useTitle';

const Inquiry = ({ user, sendInquiry, resetInquiryStatus, inquiryStatus }) => {

  useTitle('Inquiry')

  const [message, setMessage] = useState('')
  const [selectedCountry, setSelectedCountry] = useState('')

  useEffect(() => {
    resetInquiryStatus()
  }, [resetInquiryStatus])

  useEffect(() => {
    if(inquiryStatus === 'sent'){
      setMessage('')
    }
  }, [inquiryStatus])
  
  
  const onSubmitForm = (e) => {
    e.preventDefault()
    if(!selectedCountry){
      toast.error('Please select a country to continue!')
      return
    }
    sendInquiry({ message, country: selectedCountry, userRole: 'app-external-user' })
  }

  return <Container className='inquiry-page'>
    <div className='mt-5 container-small'>
      <h6 className='fw-bold mb-4'>Any feedback?</h6>

      {(() => {
        switch(inquiryStatus){
          case 'sending':
            return <div className='alert alert-primary position-relative pe-5'>
              <Spinner animation='border' variant='primary' size='sm' className='position-absolute end-0 me-3 mt-1'/>
              Sending feedback...
            </div>
          case 'sent':
            return <div className='alert alert-success position-relative pe-5'>
              <BsCheck2Circle size={24} className='position-absolute end-0 me-3'/>
              Feedback received. We'll get back to you soon!
            </div>
          case 'failed':
            return <div className='alert alert-danger position-relative pe-5'>
              <BsExclamationOctagon size={24} className='position-absolute end-0 me-3'/>
              Something went wrong! Please try again.
            </div>
          default:
            return <></>
        }
      })()}

      <Form onSubmit={onSubmitForm}>

      <Form.Group className="mb-3">
          <Form.Label className='fs-sm fw-500'>Country</Form.Label>
          <Form.Select defaultValue={''} onChange={e => setSelectedCountry(e.target.value)} name='country' className='fs-sm fw-500 py-2 rounded-3' required>
            <option disabled value=''>---Select Country---</option>
            { getAvailableCountries(user).map(country => (<option value={country.value} key={country.value}>{country.label}</option>)) }
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label className='fs-sm fw-500'>Tell us more!</Form.Label>
          <textarea className='form-control rounded-3 fw-500 fs-sm' value={message} onChange={e => setMessage(e.target.value)} rows={6} maxLength={3000} placeholder="I would like to..."></textarea>
        </Form.Group>

        <div className='mt-4 border-top pt-4 d-grid'>
          <Button variant="primary" type="submit" disabled={!message || !['', 'failed'].includes(inquiryStatus)}>Submit</Button>
        </div>

        <p className='mt-3 text-muted fw-500'>Once submitted, our campaign manager will reach out to you regarding your feedback.</p>

      </Form>

    </div>
  </Container>
}

Inquiry.propTypes = {
  inquiryStatus: PropTypes.string.isRequired
}

const mapStateToProps = (state) => ({
  inquiryStatus: state.inquiry.inquiryStatus,
  campaignsData: state.campaigns.CampaignsData,
  user: state.auth.user,
  currentCampaign: state.campaigns.campaign
})

export default connect(mapStateToProps , { sendInquiry, resetInquiryStatus })(Inquiry)
