import {
  PARTNERS_CLEAR_CAMPAIGN_MANAGERS,
  PARTNERS_CLEAR_PARTNERSHIP_MANAGERS,
  PARTNERS_SET_CAMPAIGN_FETCHING_STATUS,
  PARTNERS_SET_PARTNERSHIP_FETCHING_STATUS,
  PARTNERS_SET_PARTNERSHIP_MANAGERS_DATA,
  PARTNERS_SET_CAMPAIGN_MANAGERS_DATA,
  PARTNERS_SET_CAMPAIGN_MANAGER_DATA,
  PARTNERS_SET_PARTNERSHIP_MANAGER_DATA,
  PARTNERS_SET_CAMPAIGN_MANAGER_FETCHING_STATUS,
  PARTNERS_SET_PARTNERSHIP_MANAGER_FETCHING_STATUS,
  PARTNERS_SET_CAMPAIGN_MANAGER_SAVE_STATUS,
  PARTNERS_SET_PARTNERSHIP_MANAGER_SAVE_STATUS,
  PARTNERS_APPEND_CAMPAIGN_MANAGER,
  PARTNERS_REMOVE_CAMPAIGN_MANAGER,
  PARTNERS_REMOVE_PARTNERSHIP_MANAGER,
  PARTNERS_APPEND_PARTNERSHIP_MANAGER,
  PARTNERS_UPDATE_CAMPAIGN_MANAGER,
  PARTNERS_UPDATE_PARTNERSHIP_MANAGER
} from "actions/util/types.action";

const initialState = {
  campaign: {
    data: [],
    isFetching: false,
    pagination: {
      currentPage: 1,
      totalPages: 1,
      totalRecords: 0
    }
  },
  partnership: {
    data: [],
    isFetching: false,
    pagination: {
      currentPage: 1,
      totalPages: 1,
      totalRecords: 0
    }
  },
  currentCampaignManager: {
    data: null,
    isFetching: false,
    saveStatus: 'idle'
  },
  currentPartnershipManager: {
    data: null,
    isFetching: false,
    saveStatus: 'idle'
  }
};

const partnerReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case PARTNERS_SET_CAMPAIGN_MANAGERS_DATA:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          data: payload.data,
          pagination: payload.pagination
        }
      }
    case PARTNERS_SET_PARTNERSHIP_MANAGERS_DATA:
      return {
        ...state,
        partnership: {
          ...state.partnership,
          data: payload.data,
          pagination: payload.pagination
        }
      }
    case PARTNERS_CLEAR_CAMPAIGN_MANAGERS:
      return {
        ...state,
        campaign: initialState.campaign
      }
    case PARTNERS_CLEAR_PARTNERSHIP_MANAGERS:
      return {
        ...state,
        partnership: initialState.partnership
      }
    case PARTNERS_SET_CAMPAIGN_FETCHING_STATUS:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          isFetching: payload
        }
      }
    case PARTNERS_SET_PARTNERSHIP_FETCHING_STATUS:
      return {
        ...state,
        partnership: {
          ...state.partnership,
          isFetching: payload
        }
      }
    case PARTNERS_SET_CAMPAIGN_MANAGER_DATA:
      return {
        ...state,
        currentCampaignManager: {
          ...state.currentCampaignManager,
          data: payload
        }
      }
    case PARTNERS_SET_PARTNERSHIP_MANAGER_DATA:
      return {
        ...state,
        currentPartnershipManager: {
          ...state.currentPartnershipManager,
          data: payload
        }
      }
    case PARTNERS_SET_CAMPAIGN_MANAGER_FETCHING_STATUS:
      return {
        ...state,
        currentCampaignManager: {
          ...state.currentCampaignManager,
          isFetching: payload
        }
      }
    case PARTNERS_SET_PARTNERSHIP_MANAGER_FETCHING_STATUS:
      return {
        ...state,
        currentPartnershipManager: {
          ...state.currentPartnershipManager,
          isFetching: payload
        }
      }
    case PARTNERS_SET_CAMPAIGN_MANAGER_SAVE_STATUS:
      return {
        ...state,
        currentCampaignManager: {
          ...state.currentCampaignManager,
          saveStatus: payload
        }
      }
    case PARTNERS_SET_PARTNERSHIP_MANAGER_SAVE_STATUS:
      return {
        ...state,
        currentPartnershipManager: {
          ...state.currentPartnershipManager,
          saveStatus: payload
        }
      }
    case PARTNERS_APPEND_CAMPAIGN_MANAGER:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          pagination: {
            ...state.campaign.pagination,
            totalRecords: state.campaign.pagination.totalRecords+1
          },
          data: [...state.campaign.data, payload]
        }
      }
    case PARTNERS_APPEND_PARTNERSHIP_MANAGER:
      return {
        ...state,
        partnership: {
          ...state.partnership,
          pagination: {
            ...state.partnership.pagination,
            totalRecords: state.partnership.pagination.totalRecords+1
          },
          data: [...state.partnership.data, payload]
        }
      }
    case PARTNERS_REMOVE_CAMPAIGN_MANAGER:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          pagination: {
            ...state.campaign.pagination,
            totalRecords: state.campaign.pagination.totalRecords-1
          },
          data: state.campaign.data.filter(c => c._id !== payload)
        }
      }
    case PARTNERS_REMOVE_PARTNERSHIP_MANAGER:
      return {
        ...state,
        partnership: {
          ...state.partnership,
          pagination: {
            ...state.partnership.pagination,
            totalRecords: state.partnership.pagination.totalRecords-1
          },
          data: state.partnership.data.filter(c => c._id !== payload)
        }
      }
    case PARTNERS_UPDATE_CAMPAIGN_MANAGER:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          data: state.campaign.data.map(c => c._id === payload._id ? payload : c)
        }
      }
    case PARTNERS_UPDATE_PARTNERSHIP_MANAGER:
      return {
        ...state,
        partnership: {
          ...state.partnership,
          data: state.partnership.data.map(c => c._id === payload._id ? payload : c)
        }
      }
    default:
      return state
  }
}

export default partnerReducer