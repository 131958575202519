import { GET_NEWS, GET_NEWS_ARTICLE, NEWS_LIST_IS_LOADING, NEWS_ITEM_IS_LOADING, CLEAR_NEWS_ARTICLES } from "actions/util/types.action"

const initialState = {
  categories: {
    news: {
      articles: [],
      listIsLoading: false,
      paging: {
        totalPages: 0
      }
    },
    views: {
      articles: [],
      listIsLoading: false,
      paging: {
        totalPages: 0
      }
    },
    insights: {
      articles: [],
      listIsLoading: false,
      paging: {
        totalPages: 0
      }
    }
  },
  itemIsLoading: false,
  newsArticle: null
}

const newsReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case GET_NEWS:
      return {
        ...state,
        categories: {
          ...state.categories,
          [payload.category]: {
            articles: payload.data.mode === '1'
              ? payload.articles
              : [...state.categories[payload.category].articles, ...payload.data.articles],
            paging: payload.data.paging
          }
        }
      }
    case GET_NEWS_ARTICLE:
      return {
        ...state,
        newsArticle: payload
      }
    case CLEAR_NEWS_ARTICLES:
      return {
        ...state,
        categories: {
          ...state.categories,
          [payload.category]: {
            articles: [],
            paging: {
              totalPages: 0
            }
          }
        }
      }
    case NEWS_LIST_IS_LOADING:
      return {
        ...state,
        categories: {
          ...state.categories,
          [payload.category]: {
            ...state.categories[payload.category],
            listIsLoading: payload.status
          }
        }
      }
    case NEWS_ITEM_IS_LOADING:
      return {
        ...state,
        itemIsLoading: payload
      }
    default:
      return state
  }
}

export default newsReducer