import { Dropdown, Modal, Button } from 'react-bootstrap'
import { BsThreeDots } from 'react-icons/bs'
import { connect } from 'react-redux'
import { toggleEditModal, deleteBlogPost } from 'actions/blogPost.action'
import { uploadEditedFiles } from 'actions/fileUpload.action'
import PropTypes from 'prop-types';
import { imageFileTypes } from 'actions/util/static-data.util';
import ExpandableText from 'components/util/ExpandableText';
import NameDisplayPicture from 'components/common/NameDisplayPicture';
import { useState } from 'react';
import { photoModal } from "actions/util/photoModal.action";
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { MdOutlineSettingsBackupRestore } from 'react-icons/md';
import { FiUpload } from 'react-icons/fi';
import UploadProgressBar from 'components/util/UploadProgressBar'

const FileEditPreview = ({ file, onDelete }) => {
  return <div className={`image-preview position-relative rounded-4 ${file.deleted ? 'deleted' : ''}`}>
    { imageFileTypes.some(type => file.imageUrl.includes(`.${type}`))
      ? <img src={file.imageUrl} alt='Attachment'/>
      : <video src={`${file.imageUrl}`} type="video/mp4, video/quicktime, video/x-msvideo, video/*, .mkv"/>
    }
    { file.deleted 
      ? <span onClick={onDelete} className='position-absolute rounded-4 px-1 text-success'><MdOutlineSettingsBackupRestore/></span>
      : <span onClick={onDelete} className='position-absolute rounded-4 px-1 text-danger'><AiOutlineCloseCircle/></span>
    }
  </div>
}
const NewFilePreview = ({ file, onDelete }) => {
  return <div className={`image-preview position-relative rounded-4 ${file.deleted ? 'deleted' : ''}`}>
    { imageFileTypes.some(type => file.type.includes(`${type}`))
      ? <img src={URL.createObjectURL(file)} alt='Attachment'/>
      : <video src={`${URL.createObjectURL(file)}`} type="video/mp4, video/quicktime, video/x-msvideo, video/*, .mkv"/>
    }
    <span onClick={onDelete} className='position-absolute rounded-4 px-1 text-danger'><AiOutlineCloseCircle/></span>
  </div>
}

const BlogPost = ({ 
  isCampaignAdminWrite, isMonthFilterApplied, deleteBlogPost, blogPostObject, user, photoModal,
  campaignsSelected, uploadEditedFiles, isUpdatingBlogPost, showEditModal, toggleEditModal
}) => {
  const tempDate = new Date(blogPostObject.dateCreated).toString().split(' ');
  const formattedDate = `${tempDate[1]} ${+tempDate[2]}`;
  const [isShowBlogPostDeleteModal, blogPostDeleteModal] = useState(false)
  const [editedBlogPost, setEditedBlogPost] = useState({
    ...blogPostObject,
    additionalUploads: []
  })

  function deleteSelectedPost(){
    blogPostDeleteModal(false)
    deleteBlogPost({ country: blogPostObject.country, id: blogPostObject._id, userId: user.id, campaignId: campaignsSelected[0]._id, isMonthFilterApplied})
  }

  const onFileSelected = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setEditedBlogPost({
        ...editedBlogPost,
        additionalUploads: [...editedBlogPost.additionalUploads, ...Array.from(e.target.files)]
      })
    } else {
      setEditedBlogPost({
        ...editedBlogPost,
        additionalUploads: []
      })
    }
    e.target.value = null
  };

  const toggleDeleteFile = (fidx) => {
    setEditedBlogPost({
      ...editedBlogPost,
      files: editedBlogPost.files.map((ef, efidx) => efidx === fidx ? { ...ef, deleted: ef.deleted ? undefined : true } : ef)
    })
  }

  const deleteNewFile = (fidx) => {
    setEditedBlogPost({
      ...editedBlogPost,
      additionalUploads: editedBlogPost.additionalUploads.filter((nf, nfidx) => nfidx !== fidx)
    })
  }

  const saveBlogPost = () => {
    uploadEditedFiles(editedBlogPost)
    setEditedBlogPost({
      ...editedBlogPost,
      files: editedBlogPost.files.filter(f => !f.deleted)
    })
  }

  const openEditModal = () => {
    setEditedBlogPost({
      ...blogPostObject,
      additionalUploads: []
    })
    toggleEditModal(blogPostObject._id)
  }

  const closeDeleteModal = () => {
    blogPostDeleteModal(false)
  }

  const openDeleteModal = () => {
    blogPostDeleteModal(true)
  }

  const openPhotoModal = (object, selectedIndex) => {
    photoModal({ isShowPhotoModal: true, object: { post: object }, isPreviewOption: true, selectedIndex, isLiveUrl: true })
  }

  return <>
    <div className='mb-3 w-100 border rounded-4 p-2 bg-white' data-aos="fade-up">
      <div className='d-flex align-items-start justify-content-between'>
        <div className='d-flex align-items-center'>
            {
              blogPostObject.profileUrl
                ? <img src={blogPostObject.profilePicUrl} className="blog-post-author-photo" alt="Profile"/>
                : <NameDisplayPicture firstName={blogPostObject.createdBy.split('')[0]} lastName={blogPostObject.createdBy.split(' ', 2)[1] || ''}/>
            }
            <div className='d-inline-flex flex-column ms-2'>
                <div className='fw-bold text-capitalize'>{blogPostObject.createdBy}</div>
                <small className='text-muted mt-n1'>{formattedDate}</small>
                { campaignsSelected.length > 1 ? <small className='text-muted mt-n1'>{blogPostObject.name}</small> : <></>}
            </div>
        </div>
        <div className='pe-2'>
          { isCampaignAdminWrite() || user.userRole.includes("insights-super-admin") ? <Dropdown>
            <Dropdown.Toggle as={'span'} className='no-caret'>
              <BsThreeDots className='cursor-pointer' size={20}/>
            </Dropdown.Toggle>
            <Dropdown.Menu align="end" className='rounded-4 shadow p-2'>
              <Dropdown.Item onClick={openEditModal} className='rounded-3 ps-2 pe-1 fs-sm py-2 mb-1 d-inline-flex align-items-center'>
                Edit
              </Dropdown.Item>
              <Dropdown.Item onClick={() => {openDeleteModal()}} className='rounded-3 ps-2 pe-1 fs-sm py-2 mb-1 d-inline-flex align-items-center'>
                Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> : <div/>}
        </div>
      </div>
      <div className='pt-2'>
        <ExpandableText>{blogPostObject.description}</ExpandableText>
      </div>
      <div className='row g-2'>
        { blogPostObject.files?.length > 0 && blogPostObject.files.map((image, imageId) => 
          (<div key={imageId} className={ `blog-post-image-preview g-2 rounded ${blogPostObject.files.length === 1 ? 'col-12': 'col-6'}`} onClick={() => openPhotoModal(blogPostObject, imageId)}>
            { imageFileTypes.some(type => image.imageUrl.includes(`.${type}`)) ? 
              <img src={image.imageUrl} className='max-w-100' alt='Attachment'/>
              :
              <div className = "video-card h-100 w-100">
                <video className = "video-frame w-100 h-100" 
                controls key={imageId} src={`${image.imageUrl}`} 
                type="video/mp4, video/quicktime, video/x-msvideo, video/*, .mkv"/>
              </div>
            }
          </div>)
        )}
      </div>
    </div>

    <Modal show={isShowBlogPostDeleteModal} onHide={closeDeleteModal} size="lg" centered>
      <Modal.Body className="px-4 dashboard-modal">
        <div className="d-flex flex-column py-4">
          <div><h5 className="fw-bold">Delete post?</h5></div>
          <div className="text-black">Are you sure you want to delete this post? You can not undo this action.</div>
        </div>
        <div className='w-100 gap-5 d-flex justify-content-end'>
          <Button variant="outline-dark" className="action-buttons" onClick={closeDeleteModal}>Cancel</Button>
          <Button variant="primary" className="action-buttons" onClick={() => {deleteSelectedPost()}}>Confirm</Button>
        </div>
      </Modal.Body>
    </Modal>

    <Modal show={showEditModal === blogPostObject._id} onHide={() => toggleEditModal(null)} size="lg" centered>
      <Modal.Body className="px-4 edit-post-modal">
        <div className="d-flex flex-column py-4">
          <div><h5 className="fw-bold text-primary">Edit Post</h5></div>
          <textarea className='form-control rounded-3 fs-sm mt-4' rows='6' value={editedBlogPost.description} onChange={e => setEditedBlogPost({ ...editedBlogPost, description: e.target.value })}></textarea>
          <div className='mt-3 d-flex gap-2'>
            { editedBlogPost.files.map((file, efidx) => <FileEditPreview key={`ef_${efidx}`} file={file} onDelete={() => toggleDeleteFile(efidx)}/>) }
            { editedBlogPost.additionalUploads.map((file, nfidx) => <NewFilePreview key={`nf_${nfidx}`} file={file} onDelete={() => deleteNewFile(nfidx)}/>) }
          </div>
          <div className="text-black mt-4 fw-bold mb-2">Media Files</div>
          <div className="blog-post-file-upload border rounded-3">
            <input type="file" accept="image/*, video/mp4, video/quicktime, video/x-msvideo, video/*, .mkv" className="w-full" multiple onChange={onFileSelected} />
            <span><FiUpload color="#888" size={20} /></span>
          </div>
        </div>
        <UploadProgressBar/>
        <div className='w-100 gap-5 d-flex justify-content-end'>
          <Button variant="outline-dark" className="action-buttons" onClick={() => toggleEditModal(null)}>Cancel</Button>
          <Button variant="primary" disabled={isUpdatingBlogPost} className="action-buttons" onClick={() => {saveBlogPost()}}>Save</Button>
        </div>
      </Modal.Body>
    </Modal>
  </>
}

BlogPost.propTypes = {
  deleteBlogPost: PropTypes.func.isRequired,
  isMonthFilterApplied: PropTypes.string.isRequired,
  campaignsSelected: PropTypes.array.isRequired
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  isMonthFilterApplied: state.blogPost.isMonthFilterApplied,
  photoModal: PropTypes.func.isRequired,
  campaignsSelected: state.campaigns.campaignsSelected,
  isUpdatingBlogPost: state.blogPost.isUpdatingBlogPost,
  showEditModal: state.blogPost.showEditModal
})

export default connect(mapStateToProps, { deleteBlogPost, photoModal, toggleEditModal, uploadEditedFiles })(BlogPost)
