import {
  GET_PARTNERS_LIST, AT_SET_ORGANIZATION_PARTNERTS, PARTNER_API_STATUS, GET_PARTNER_USER, PARTNERS_LIST_FETCHING_STATUS, DELETE_PARTNER_USER
} from "actions/util/types.action";

const initialState = {
  partnersData: [],
  DataCount: 0,
  selectedPartner: {
    country: {
      iso3: "",
      name: ""
    },
    _id: "",
    firstname: "",
    lastname: "",
    userRole: [],
    username: "",
    email: "",
    organization: [],
    associate: [],
    profile: "",
    campaign: [],
    contactNo: "",
    profilePic: ""
  },
  partnerAPIStatus: '',
  organizationPartners: [],
  profilePicture: '',
  partnersListIsFetching: false
};

const managePartnerReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case GET_PARTNERS_LIST:
      return {
        ...state,
        partnersData: payload.rows,
        DataCount: payload.count,
      }
    case AT_SET_ORGANIZATION_PARTNERTS:
      return {
        ...state,
        organizationPartners: payload
      }
    case GET_PARTNER_USER:
      return {
        ...state,
        selectedPartner: payload,
      }
    case PARTNER_API_STATUS:
      return {
        ...state,
        partnerAPIStatus: payload,
      }
    case PARTNERS_LIST_FETCHING_STATUS:
      return {
        ...state,
        partnersListIsFetching: payload
      }
    case DELETE_PARTNER_USER:
      return {
        ...state,
        partnersData: state.partnersData.filter(pd => pd._id !== payload),
        DataCount: state.DataCount - 1,
      }
    default:
      return state
  }
}

export default managePartnerReducer