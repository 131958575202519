import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import { ProgressBar } from "react-bootstrap";
import React from 'react'
const UploadProgressBar = ({ modalObject, uploadProgress }) => {
  return (
    <div className="d-flex align-items-center flex-column w-100">
    { uploadProgress > 0 ? <div className='w-100 my-3 px-3'>
      Uploading...
      <ProgressBar variant='primary' className='rounded-pill' max={100} now={uploadProgress} label={`${uploadProgress}%`}/>
    </div> : null }
  </div>
  );
};

UploadProgressBar.propTypes = {
  uploadProgress: PropTypes.number
};
const mapStateToProps = (state) => ({
  modalObject: state.photoModal,
  uploadProgress: state.fileUpload.uploadProgress
})

export default connect(mapStateToProps )(UploadProgressBar)
