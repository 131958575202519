const currentDiagnosis = {
    ALZ: 'Alzheimer\'s Disease',
    ART: 'Arthritis',
    AST: 'Asthma Disease',
    CAN: 'Cancer',
    COPD: 'Chronic Obstructive Pulmonary Disorder (COPD)',
    CIR: 'Cirrhosis of the Liver',
    CAD: 'Coronary artery disease',
    DIAB: 'Diabetes Disease',
    DIAR: 'Diarrhoeal diseases',
    HEPA: 'Hepatitis A',
    HEPB: 'Hepatitis B',
    HEPC: 'Hepatitis C',
    HIV: 'HIV or AIDS',
    HYP: 'Hypertension',
    IHD: 'Ischaemic Heart Disease',
    KID: 'Kidney Disease',
    LRI: 'Lower Respiratory Infections',
    MAL: 'Malaria',
    MEA: 'Measles',
    STR: 'Stroke',
    PNE: 'Pneumonia',
    TB: 'Tuberculosis (TB)',
    NO: 'None of the above',
    DNA: 'I don’t want to say',
}

export default currentDiagnosis