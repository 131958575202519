import React from 'react'
import dayjs from 'dayjs'
import articlePlaceholder from '../../res/img/article-placeholder.png'
import { generatePath, Link } from 'react-router-dom'

const NewsListItem = ({ colspan = 1, uri, article }) => {
  return <div className={'news-item mb-5 ' + (colspan === 2 ? 'col-12' : 'col-12 col-lg-6')}  data-aos="fade-up">
    <Link to={generatePath(`/${uri}/:articleId`, { articleId: article.id })}>
      <img src={article.cover_pic || articlePlaceholder} onError={e => e.target.src=articlePlaceholder} alt='preview' className={`preloader rounded-3 colspan-${colspan}`}/>
    </Link>
    <small className='mb-2 mt-3 d-inline-block'>{dayjs(article.date).format('DD MMMM YYYY')}</small>
    <Link className='inherited-anchor' to={generatePath('/news/:articleId', { articleId: article.id })}>
      { colspan === 2
          ? <h4 className='text-black fw-600' dangerouslySetInnerHTML={{ __html: article?.title || '' }}></h4> 
          : <h6 className='text-black fw-600' dangerouslySetInnerHTML={{ __html: article?.title || '' }}></h6> 
      }
      { colspan === 2 ? <p className='fw-500 line-clamp-3'>{article.description.replace(' […]', '...')}</p> : null }
    </Link>
  </div>
}

export default NewsListItem