import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import BlogSection from "./BlogSection";
import EngagementSection from "./EngagementSection";
import useScreenSize from "hooks/screenSize";
import { GetCampaigns } from '../../actions/campaign.action'
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom'
import supportIcon from '../../res/img/support-icon.png'
import useTitle from "hooks/useTitle";
const Dashboard = ({
  user,
  GetCampaigns,
  isFromCampaignLisitingScreen,
  isAllCampaignProgressView,
  campaignsSelected
}) => {

  const { isMobile, isTablet } = useScreenSize()

  useTitle('Dashboard')

  const isCampaignAdminWrite = () => {
    const associate = user.associate ? user.associate.find(
      (val) => val?.type === "campaign" && val?.role === "read/write"
    ): { role: '' };
    return (
      associate?.role === "read/write" &&
      user.userRole.includes("insights-campaign")
    );
  };

  useEffect(() => {
    if(!isFromCampaignLisitingScreen) {
      if (user?.campaign?.length > 0){
        const campaignIds = user.campaign.map((obj) => obj.campaignId)
        GetCampaigns({ countryCode: user.country.iso3.toUpperCase(), campaignIds, userRole: 'app-external-user' })
      }else{
        GetCampaigns({ countryCode: user.country.iso3.toUpperCase(), userRole: 'app-external-user' })
      }
    }
  }, [isFromCampaignLisitingScreen, GetCampaigns, user])

  let namecampaign = campaignsSelected.slice(0, 2).map(obj => obj.name).join(', ')
  if(campaignsSelected.length > 2) {
    namecampaign += '...'
  }

  return (
    <Container className="dashboard">
      <div className="row px-3">
        <div className="col-12 col-lg-7 ps-0">
          {
            isAllCampaignProgressView ? (
              <div className="d-flex align-items-center justfy-content-center justfy-content-md-start flex-column flex-md-row gap-2 gap-md-0 flex-grow">
                <h5 className="fw-bold mb-0 me-0 me-md-3 d-inline-block">All Campaigns</h5>

                <Link
                  to={`/campaign-details`}
                  className="text-decoration-none" >
                  <span >View Details</span>
                </Link>

              </div> ) :
            campaignsSelected.length > 0 ? (
            <div className="d-flex align-items-center justfy-content-center justfy-content-md-start flex-column flex-md-row gap-2 gap-md-0 flex-grow">
              <h5 className="fw-bold mb-0 me-0 me-md-3 d-inline-block campagin-title">
                { namecampaign } </h5>

              <Link
                to={ campaignsSelected.length > 1 ? `/campaign-details` : campaignsSelected.length === 1 ? `/campaign-details/${campaignsSelected[0]._id}` : `/campaign-details`}
                className="text-decoration-none" >
                <span className="text-nowrap">View Details</span>
              </Link>

            </div>
            ) : ''
          }
        </div>
      </div>
     
      <div className="row mx-0 mt-2">
        {isMobile || isTablet ? (
          <div className="col-12 col-lg-5 pe-0 pb-4">
            <EngagementSection
              isCampaignAdminWrite={isCampaignAdminWrite}
              user={user}
            />
          </div>
        ) : null}
        
        <BlogSection
          isCampaignAdminWrite={isCampaignAdminWrite}
          userRole = {isFromCampaignLisitingScreen ? '' : 'app-external-user'}
        />
        {isMobile || isTablet ? null : (
          <div className="col-12 col-lg-5 pe-0">
            <EngagementSection
              isCampaignAdminWrite={isCampaignAdminWrite}
              user={user}
            />
          </div>
        )}
        </div>
      { !user.userRole.includes('insights-super-admin') && <Link to={'/feedback'} className="support-badge inherited-anchor cursor-pointer position-fixed border px-3 py-2 border-primary border-2 rounded-pill bg-white shadow-sm">
        <span className="text-primary fw-600">Any feedback?</span>
        <img src={supportIcon} alt="Support Staff"/>
      </Link> }
    </Container>
  );
};

Dashboard.propTypes = {
  GetCampaigns: PropTypes.func.isRequired,
  campaignsSelected: PropTypes.array.isRequired
}

const mapStateToProps = (state, props) => ({
  user: state.auth.user,
  isFromCampaignLisitingScreen: state.campaignListing.isFromCampaignLisitingScreen,
  isAllCampaignProgressView: state.campaigns.isAllCampaignProgressView,
  campaignsSelected: state.campaigns.campaignsSelected
});

export default connect(mapStateToProps, { GetCampaigns })(Dashboard);
