import React, { useEffect } from 'react'
import { Navbar, Container, Nav, Dropdown, NavItem, NavLink } from "react-bootstrap";
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { startLogout } from '../../actions/auth.action';
import { AiOutlineLogout, AiOutlineUser } from 'react-icons/ai'
import Logo from '../../res/img/reach52-beta-logo.png';
import { setIsFromCampaignLisitingScreen } from '../../actions/util/campaignListing.action'
import PropTypes from 'prop-types'
import NameDisplayPicture from 'components/common/NameDisplayPicture';

const TopNavbar = ({
  startLogout,
  user,
  setIsFromCampaignLisitingScreen
}) => {

  const currentLocation = useLocation()

  useEffect(() => {
    if (user?.userRole.includes('app-external-user')) {
      setIsFromCampaignLisitingScreen({isFromCampaignLisitingScreen: false})
    }
  }, [user, setIsFromCampaignLisitingScreen])

  return <Navbar className='top-navbar border-bottom' expand="lg" fixed='top'>
  <Container className='d-flex align-items-center'>
    <Navbar.Brand href={
      user?.userRole.includes('app-external-user')
      ? '/dashboard'
      : (user?.userRole.includes('insights-super-admin') || user?.userRole.includes('insights-campaign') ? '/campaign' : '/')
      } className='d-flex align-items-center'>
      <img src={Logo} height="24" className="d-inline-block align-top" alt="Logo"/>
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="primary-navbar-nav" className='border-0' />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="mx-auto justify-content-center d-flex navigation-bar">
        { (user?.userRole.includes('app-external-user')) &&
        <Nav.Link className={'text-center text-md-left ' + (currentLocation.pathname === '/dashboard' ? 'active' : '')} as={Link} to="/dashboard">
          My Campaign
        </Nav.Link> }
        { (user?.userRole.includes('app-external-user')) &&
        <Nav.Link className={'text-center text-md-left ' + (currentLocation.pathname === '/gallery' ? 'active' : '')} as={Link} to="/gallery">
          Gallery
        </Nav.Link> }
        { (user?.userRole.includes('insights-super-admin') || user?.userRole.includes('insights-campaign')) && <Nav.Link className={'text-center text-md-left ' + (currentLocation.pathname === '/campaign' ? 'active' : '')} as={Link} to="/campaign">
          Campaign
        </Nav.Link> }
        { (user?.userRole.includes('insights-super-admin') || user?.userRole.includes('insights-campaign'))  && <Nav.Link className={'text-center text-md-left ' + (currentLocation.pathname === '/partners' ? 'active' : '')} as={Link} to="/partners">
          Partners
        </Nav.Link> }
        { (user?.userRole.includes('insights-super-admin') || user?.userRole.includes('insights-campaign'))  && <Nav.Link className={'text-center text-md-left ' + (currentLocation.pathname === '/manage-users' ? 'active' : '')} as={Link} to="/manage-users">
          Manage Users
        </Nav.Link> }
        <Nav.Link className={'text-center text-md-left ' + (/^\/news(\/\d+)?$/.test(currentLocation.pathname) ? 'active' : '')} as={Link} to="/news">News</Nav.Link>
        <Nav.Link className={'text-center text-md-left ' + (/^\/views(\/\d+)?$/.test(currentLocation.pathname) ? 'active' : '')} as={Link} to="/views">Views</Nav.Link>
        <Nav.Link className={'text-center text-md-left ' + (/^\/insights(\/\d+)?$/.test(currentLocation.pathname) ? 'active' : '')} as={Link} to="/insights">Insights</Nav.Link>
      </Nav>
      <Nav className="d-flex navbar-profile d-flex align-items-center">
        {
          user?.profilePic
            ? <img src={user?.profilePicUrl} className="profile-photo" alt="Profile"/>
            : <NameDisplayPicture firstName={user?.firstName} lastName={user?.lastName}/>
        }
        <Dropdown as={NavItem} className='dropdown-menu-center' align={'end'}>
          <Dropdown.Toggle as={NavLink}>
          </Dropdown.Toggle>
          <Dropdown.Menu className='rounded-4 shadow p-2'>
            <Dropdown.Item as={Link} to='/profile' className='rounded-3 ps-2 pe-1 fs-sm py-2 mb-1 d-inline-flex align-items-center'>
              <AiOutlineUser className='me-2 d-inline-block'/><small>Profile</small>
            </Dropdown.Item>
            <Dropdown.Item className='rounded-3 ps-2 pe-1 fs-sm py-2 mb-1' onClick={()=>startLogout(user)}>
              <AiOutlineLogout className='me-2 d-inline-block'/><small>Logout</small>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Nav>
    </Navbar.Collapse>
  </Container>
</Navbar>
}

TopNavbar.propTypes = {
  setIsFromCampaignLisitingScreen: PropTypes.func.isRequired
}

const mapStateToProps = (state, props) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user
})

export default connect(mapStateToProps, {
  startLogout, setIsFromCampaignLisitingScreen
})(TopNavbar)
