import { NEWS_LETTER_SUBSCRIPTION_STATUS, NEWS_LETTER_SUBSCRIPTION_GET_STATUS, NEWS_LETTER_SUBSCRIPTION_POST_STATUS } from "actions/util/types.action"

const initialState = {
  subscriptionPostStatus: '',
  subscriptionGetStatus: '',
  subscriptionStatus: {
    subscribed: false,
    subscriber: null
  },
}

const newsLetterReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
      case NEWS_LETTER_SUBSCRIPTION_POST_STATUS:
        return {
          ...state,
          subscriptionPostStatus: payload
        }
      case NEWS_LETTER_SUBSCRIPTION_GET_STATUS:
        return {
          ...state,
          subscriptionGetStatus: payload
        }
      case NEWS_LETTER_SUBSCRIPTION_STATUS:
        return {
          ...state,
          subscriptionStatus: payload
        }
      default:
        return state
    }
  }
  
  export default newsLetterReducer