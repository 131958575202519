const { useState, useEffect } = require("react")

const useScreenSize = () => {
    const [isMobile, setIsMobile] = useState(false)
    const [isTablet, setIsTablet] = useState(false)
    const [isDesktop, setIsDesktop] = useState(false)

    const setStatus = () => {
        if(window.innerWidth < 576){
            setIsMobile(true)
            setIsTablet(false)
            setIsDesktop(false)
        } else if(window.innerWidth < 991){
            setIsMobile(false)
            setIsTablet(true)
            setIsDesktop(false)
        } else {
            setIsMobile(false)
            setIsTablet(false)
            setIsDesktop(true)
        }
    }

    useEffect(() => {
        setStatus()
        window.onresize = () => {
            setStatus()
        }
    }, [])

    return { isMobile, isTablet, isDesktop }

}

export default useScreenSize