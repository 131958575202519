import api from "../lib/api.lib";
import {
  PARTNERS_CLEAR_CAMPAIGN_MANAGERS,
  PARTNERS_CLEAR_PARTNERSHIP_MANAGERS,
  PARTNERS_SET_CAMPAIGN_MANAGERS_DATA,
  PARTNERS_SET_CAMPAIGN_FETCHING_STATUS,
  PARTNERS_SET_PARTNERSHIP_FETCHING_STATUS,
  PARTNERS_SET_CAMPAIGN_MANAGER_FETCHING_STATUS,
  PARTNERS_SET_PARTNERSHIP_MANAGER_FETCHING_STATUS,
  PARTNERS_SET_CAMPAIGN_MANAGER_DATA,
  PARTNERS_SET_PARTNERSHIP_MANAGERS_DATA,
  PARTNERS_SET_PARTNERSHIP_MANAGER_DATA,
  PARTNERS_SET_CAMPAIGN_MANAGER_SAVE_STATUS,
  PARTNERS_SET_PARTNERSHIP_MANAGER_SAVE_STATUS,
  PARTNERS_APPEND_CAMPAIGN_MANAGER,
  PARTNERS_APPEND_PARTNERSHIP_MANAGER,
  PARTNERS_REMOVE_CAMPAIGN_MANAGER,
  PARTNERS_REMOVE_PARTNERSHIP_MANAGER,
  PARTNERS_UPDATE_CAMPAIGN_MANAGER,
  PARTNERS_UPDATE_PARTNERSHIP_MANAGER,
} from "./util/types.action";

export const getPartners = (partnerType, page) => async (dispatch) => {
  try {
    await dispatch({
      type: partnerType === 'campaign' 
        ? PARTNERS_SET_CAMPAIGN_FETCHING_STATUS 
        : PARTNERS_SET_PARTNERSHIP_FETCHING_STATUS,
      payload: true
    })
    const res = await api.get(`${process.env.REACT_APP_API}/partners/${partnerType}?page=${page}`)
    if (res.status === 200) {
      if(partnerType === 'campaign'){
        await dispatch({
          type: PARTNERS_SET_CAMPAIGN_MANAGERS_DATA,
          payload: res.data,
        })
      } else if (partnerType === 'partnership'){
        await dispatch({
          type: PARTNERS_SET_PARTNERSHIP_MANAGERS_DATA,
          payload: res.data,
        })
      }
    }
  } catch (error) {
    console.log(error)
  } finally {
    await dispatch({
      type: partnerType === 'campaign' 
        ? PARTNERS_SET_CAMPAIGN_FETCHING_STATUS 
        : PARTNERS_SET_PARTNERSHIP_FETCHING_STATUS,
      payload: false
    })
  }
}

export const clearPartners = (partnerType) => async (dispatch) => {
  if(partnerType === 'campaign'){
    await dispatch({
      type: PARTNERS_CLEAR_CAMPAIGN_MANAGERS
    })
  } else if(partnerType === 'partnership'){
    await dispatch({
      type: PARTNERS_CLEAR_PARTNERSHIP_MANAGERS
    })
  }
}

export const getPartner = (partnerType, managerId) => async (dispatch) => {
  try {
    await dispatch({
      type: partnerType === 'campaign'
        ? PARTNERS_SET_CAMPAIGN_MANAGER_FETCHING_STATUS
        : PARTNERS_SET_PARTNERSHIP_MANAGER_FETCHING_STATUS,
      payload: true
    })
    const res = await api.get(`${process.env.REACT_APP_API}/partners/${partnerType}/${managerId}`);
    if (res.status === 200) {
      await dispatch({
        type: partnerType === 'campaign' 
          ? PARTNERS_SET_CAMPAIGN_MANAGER_DATA
          : PARTNERS_SET_PARTNERSHIP_MANAGER_DATA,
        payload: res.data
      })
    }
  } catch (error) {
    console.log(error)
  } finally {
    await dispatch({
      type: partnerType === 'campaign'
        ? PARTNERS_SET_CAMPAIGN_MANAGER_FETCHING_STATUS
        : PARTNERS_SET_PARTNERSHIP_MANAGER_FETCHING_STATUS,
      payload: false
    })
  }
}

export const savePartner = (partnerType, partnerData) => async (dispatch) => {
  try {
    await dispatch({
      type: partnerType === 'campaign' 
        ? PARTNERS_SET_CAMPAIGN_MANAGER_SAVE_STATUS
        : PARTNERS_SET_PARTNERSHIP_MANAGER_SAVE_STATUS,
      payload: 'saving'
    })
    const res = await api.post(`${process.env.REACT_APP_API}/partners/${partnerType}`, { partnerData })
    if (res.status === 200 || res.status === 201) {
      if(res.status === 201){
        dispatch(appendPartnerToList(partnerType, res.data))
      } else {
        dispatch(updatePartnerInList(partnerType, res.data))
      }
      await dispatch({
        type: partnerType === 'campaign' 
          ? PARTNERS_SET_CAMPAIGN_MANAGER_SAVE_STATUS
          : PARTNERS_SET_PARTNERSHIP_MANAGER_SAVE_STATUS,
        payload: res.status === 200 ? 'saved' : 'created'
      })
      await dispatch({
        type: partnerType === 'campaign' 
          ? PARTNERS_SET_CAMPAIGN_MANAGER_SAVE_STATUS
          : PARTNERS_SET_PARTNERSHIP_MANAGER_SAVE_STATUS,
        payload: 'idle'
      })
    }
  } catch (error) {
    await dispatch({
      type: partnerType === 'campaign' 
        ? PARTNERS_SET_CAMPAIGN_MANAGER_SAVE_STATUS
        : PARTNERS_SET_PARTNERSHIP_MANAGER_SAVE_STATUS,
      payload: 'failed'
    })
    await dispatch({
      type: partnerType === 'campaign' 
        ? PARTNERS_SET_CAMPAIGN_MANAGER_SAVE_STATUS
        : PARTNERS_SET_PARTNERSHIP_MANAGER_SAVE_STATUS,
      payload: 'idle'
    })
  }
}

export const deletePartner = (partnerType, managerId) => async (dispatch) => {
  try {
    const res = await api.delete(`${process.env.REACT_APP_API}/partners/${partnerType}/${managerId}`);
    if (res.status === 200) {
      dispatch(removePartnerFromList(partnerType, managerId))
      await dispatch({
        type: partnerType === 'campaign' 
          ? PARTNERS_SET_CAMPAIGN_MANAGER_SAVE_STATUS
          : PARTNERS_SET_PARTNERSHIP_MANAGER_SAVE_STATUS,
        payload: 'deleted'
      })
      await dispatch({
        type: partnerType === 'campaign' 
          ? PARTNERS_SET_CAMPAIGN_MANAGER_SAVE_STATUS
          : PARTNERS_SET_PARTNERSHIP_MANAGER_SAVE_STATUS,
        payload: 'idle'
      })
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export const appendPartnerToList = (partnerType, partner) => async (dispatch) => {
  await dispatch({
    type: partnerType === 'campaign' 
      ? PARTNERS_APPEND_CAMPAIGN_MANAGER
      : PARTNERS_APPEND_PARTNERSHIP_MANAGER,
    payload: partner
  })
}

export const removePartnerFromList = (partnerType, partnerId) => async (dispatch) => {
  await dispatch({
    type: partnerType === 'campaign' 
      ? PARTNERS_REMOVE_CAMPAIGN_MANAGER
      : PARTNERS_REMOVE_PARTNERSHIP_MANAGER,
    payload: partnerId
  })
}

export const updatePartnerInList = (partnerType, partner) => async (dispatch) => {
  await dispatch({
    type: partnerType === 'campaign' 
      ? PARTNERS_UPDATE_CAMPAIGN_MANAGER
      : PARTNERS_UPDATE_PARTNERSHIP_MANAGER,
    payload: partner
  })
}